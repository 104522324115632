import { Box, useTheme, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { StarIcon } from '../../assets/icons/icons';
import DropdownInput from '../../components/DropdownInput';

const Legend = () => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <Box
      display="flex"
      alignItems="center"
      py="6px"
      px={1}
      mr={1}
      backgroundColor={theme.palette.grey[100]}
      width="fit-content"
      sx={(theme) => ({ mb: 2, [theme.breakpoints.up('sm')]: { mb: 0 } })}
    >
      <StarIcon color={theme.palette.common.green[700]} />
      <Typography
        fontFamily={theme.typography.fontFamily}
        fontSize="12px"
        color={theme.palette.grey[900]}
        pl="4px"
      >
        {formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.recommendedVehicle' })}
      </Typography>
    </Box>
  );
};

const sortConfig = {
  rangeDesc: {
    field: 'range',
    method: 'desc',
  },
  rangeAsc: {
    field: 'range',
    method: 'asc',
  },
};

export default function ContentHeader({ subset, total, sortOrder, changeSort }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const sortOptions = Object.keys(sortConfig).map((key) => ({
    value: key,
    children: formatMessage({ id: `inputs.vehicleSet.vehicleCatalog.sortOptions.${key}` }),
  }));

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      padding="10px"
      alignItems="center"
      mb={4}
      width="100%"
      sx={(theme) => ({ [theme.breakpoints.up('sm')]: { mx: 2 } })}
    >
      <Legend />
      <Box
        display="flex"
        alignItems="center"
        sx={(theme) => ({
          flexDirection: 'column-reverse',
          [theme.breakpoints.up('sm')]: { flexDirection: 'row' },
        })}
      >
        <Typography
          fontFamily={theme.typography.fontFamily}
          fontSize="12px"
          color={theme.palette.grey[900]}
          mr={1}
        >
          {formatMessage(
            { id: 'inputs.vehicleSet.vehicleCatalog.showing' },
            { subset: subset, total: total },
          )}
        </Typography>
        <DropdownInput
          id="vehicle-sort-input"
          label={formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.sortBy' })}
          options={sortOptions}
          value={sortOrder}
          onChange={(e) => changeSort(e.target.value)}
        />
      </Box>
    </Box>
  );
}
