import React from 'react';
import { Box, useTheme } from '@mui/material';
import StyledLineGraph from '../../components/charts/StyledLineGraph';
import times from 'lodash/times';
import { formatAsDollars } from '../../utils/formatters';
import { useIntl } from 'react-intl';

export default function CostsOverTimeChart({ fossilFleetCosts, evFleetCosts }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const numYears = Math.max(fossilFleetCosts.byYear.length, evFleetCosts.byYear.length);
  const labels = times(numYears, (i) => (i === 0 ? '' : `Year ${i}`));

  const chargeData = {
    labels,
    datasets: [
      {
        label: formatMessage({ id: 'panels.financials.electricVeh' }),
        fill: false,
        data: evFleetCosts.byYear,
        backgroundColor: theme.palette.primary.main,
        pointRadius: 8,
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        pointBackgroundColor: `${theme.palette.primary.main}26`,
      },
      {
        label: formatMessage({ id: 'panels.financials.fossilVeh' }),
        fill: false,
        data: fossilFleetCosts.byYear,
        backgroundColor: theme.palette.grey[900],
        pointRadius: 8,
        borderWidth: 2,
        borderColor: theme.palette.grey[900],
        pointBackgroundColor: `${theme.palette.grey[900]}26`,
      },
    ],
  };

  const xTicks = {
    min: 0,
    padding: 20,
    color: theme.palette.grey[900],
    font: {
      size: 14,
      family: 'Overpass, sans-serif',
    },
  };

  const yTicks = {
    min: 0,
    callback: (value) => `${formatAsDollars(value)}`,
    color: theme.palette.grey[900],
    padding: 20,
    font: {
      size: 14,
      family: 'Overpass, sans-serif',
    },
  };

  return (
    <Box maxWidth="890px">
      <StyledLineGraph
        data={chargeData}
        graphTitle={formatMessage({ id: 'panels.financials.overTimeCosts' }).toUpperCase()}
        showLegend
        yTicks={yTicks}
        xTicks={xTicks}
        tooltipTitleFormatter={(data) => data[0].label}
        tooltipLabelFormatter={(data) => `${data.dataset.label}: ${formatAsDollars(data.raw)}`}
      />
    </Box>
  );
}
