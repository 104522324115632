import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import IncentiveCard from './IncentiveCard';
import { useInputs } from '@bellawatt/use-inputs';
import { useDisplay } from '../../components/DisplayProvider';
import WarningMessage from '../../components/WarningMessage';

export default function Incentives() {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { mode } = useDisplay();

  const { apiEvaluatedIncentives, isMultipleSites } = useInputs();

  const eligibleIncentives = apiEvaluatedIncentives.filter(
    ({ eligibility }) => eligibility === 'eligible',
  );

  if (isMultipleSites) {
    return (
      <WarningMessage
        message={formatMessage({ id: 'panels.incentives.multipleSitesAlert' })}
        fullWidth
      />
    );
  }

  if (!apiEvaluatedIncentives.length || !eligibleIncentives.length) {
    return (
      <Typography fontFamily={theme.typography.fontFamily} fontSize="1.25rem">
        {formatMessage({ id: 'panels.incentives.noIncentives' })}
      </Typography>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12} mt={mode === 'print' ? '150px' : '0'}>
        {eligibleIncentives.length > 0 && (
          <Box mb={4}>
            <Typography fontFamily={theme.typography.fontFamily} fontWeight="500" fontSize="14px">
              {formatMessage({ id: 'panels.incentives.eligible.header' })}
            </Typography>
          </Box>
        )}
        {eligibleIncentives.map((incentive, idx) => (
          <Box key={idx} mb={2}>
            <IncentiveCard incentive={incentive} isEligible />
          </Box>
        ))}
        <Typography fontSize="0.75rem" color="grey.700" fontStyle="italic">
          {formatMessage({ id: 'panels.incentives.eligible.disclaimer' })}
        </Typography>
      </Grid>
    </Grid>
  );
}
