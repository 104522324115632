import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import ToolTip from '../../components/ToolTip';

export default function VehicleInfoCard({ header, info }) {
  const theme = useTheme();
  if (header === 'Specs') return <></>;

  return (
    <Box height="100%" p={4} borderRadius={2} border={`1px solid ${theme.palette.grey[400]}`}>
      <Typography fontSize="1.25rem" fontFamily={theme.typography.fontFamily}>
        {header}
      </Typography>
      {info.map(({ title, value, disclaimer }) => (
        <Box
          key={title}
          sx={{
            paddingTop: 3,
            '&:not(:last-child)': {
              borderBottom: `1px solid ${theme.palette.grey[400]}`,
              paddingBottom: 3,
            },
          }}
        >
          <Typography
            fontSize="0.75rem"
            color={theme.palette.grey[900]}
            mb={1}
            sx={{ textTransform: 'uppercase' }}
          >
            {title}
            <div style={{ display: 'inline-block', position: 'relative', top: '2px' }}>
              {disclaimer && <ToolTip content={disclaimer} />}
            </div>
          </Typography>
          <Typography fontFamily={theme.typography.fontFamily} color={theme.palette.grey[900]}>
            {value}
          </Typography>
          {/* {disclaimer && (
            <Typography fontStyle="italic" fontSize="14px" color={theme.palette.grey[500]}>
              {disclaimer}
            </Typography>
          )} */}
        </Box>
      ))}
    </Box>
  );
}
