import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Inputs } from '@bellawatt/use-inputs';
import getDefaultsWithApi from './use-inputs/getDefaults';
import computed from './use-inputs/computed';
import App from './App';
import ReactIntlProvider from './utils/ReactIntlProvider';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../src/config/theme.js';
import CssBaseline from '@mui/material/CssBaseline';
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

ChartJS.register(...registerables, ChartDataLabels);

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line import/no-extraneous-dependencies, global-require
  const axe = require('@axe-core/react');
  axe.default(React, ReactDOM, 1000);
}

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Inputs
        computed={computed}
        defaults={getDefaultsWithApi()}
        ignore={[
          'apiElectricVehicles',
          'apiFossilVehicles',
          'apiIncentives',
          'apiChargers',
          'apiEvaluatedIncentives',
          'assumedIncentives',
        ]}
      >
        <ReactIntlProvider>
          <App />
        </ReactIntlProvider>
      </Inputs>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
