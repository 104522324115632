import React from 'react';
import { Typography, Box } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import { useIntl } from 'react-intl';

export default function OnboardingHeader() {
  const { onboardingStep } = useInputs();
  const { formatMessage } = useIntl();

  return (
    <Box px={2}>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '34px',
          maxWidth: '500px',
          margin: '0 auto',
          marginBottom: '20px',
          fontWeight: 600,
        }}
      >
        {formatMessage({ id: `onboarding.${onboardingStep}.title` })}
      </Typography>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '16px',
          maxWidth: '650px',
          margin: '0 auto',
        }}
      >
        {formatMessage({ id: `onboarding.${onboardingStep}.leadCopy` })}
      </Typography>
    </Box>
  );
}
