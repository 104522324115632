const getDefaultRate = (electricityPrice) => ({
  name: 'defaultRate',
  title: 'Default Rate',
  rateElements: [
    {
      rateElementType: 'MonthlyEnergy',
      name: 'Power Supply Charge',
      classification: 'energy',
      billingCategory: 'supply',
      rateComponents: [
        {
          charge: electricityPrice,
          name: 'Power Supply Charge',
        },
      ],
    },
  ],
});

export default getDefaultRate;
