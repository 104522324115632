import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import walkthroughPhoto from '../../assets/images/homepage/walkthroughPhoto.png';
import { useIntl } from 'react-intl';
import Content from './Content';
import GetStartedButton from './GetStartedButton';

export default function Action() {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Box
      position="relative"
      sx={{
        display: 'flex',
        flexDirection: 'column-reverse',
        px: 3,
        mb: 25,
        [theme.breakpoints.up('sm')]: { display: 'block', px: 0, mb: 0 },
      }}
    >
      <Box
        maxHeight="600px"
        sx={{
          px: 3,
          [theme.breakpoints.up('sm')]: { position: 'absolute', right: '0', top: '20px', px: 0 },
        }}
      >
        <img
          src={walkthroughPhoto}
          height={isLargeScreen ? '400px' : '300px'}
          width="100%"
          alt="walkthrough"
        />
      </Box>
      <Content>
        <Box
          sx={{
            maxWidth: '100%',
            minHeight: '300px',
            [theme.breakpoints.up('sm')]: { maxWidth: '50%', minHeight: '400px' },
          }}
        >
          <Typography
            fontFamily={theme.typography.fontFamily}
            fontSize="68px"
            mb={2}
            sx={{
              fontSize: '34px',
              lineHeight: '120%',
              [theme.breakpoints.up('sm')]: { fontSize: '68px', lineHeight: '65px' },
            }}
          >
            {formatMessage({ id: 'homepage.makePlan.header' })}
          </Typography>
          <Typography
            fontSize="20px"
            mb={2}
            fontFamily={theme.typography.fontFamily}
            color={theme.palette.grey[700]}
          >
            {formatMessage({ id: 'homepage.makePlan.subheader' })}
          </Typography>
          <Box
            width="100%"
            display="flex"
            sx={{
              justifyContent: 'center',
              [theme.breakpoints.up('sm')]: { justifyContent: 'start' },
            }}
          >
            <GetStartedButton />
          </Box>
        </Box>
      </Content>
    </Box>
  );
}
