import { Dialog as MuiDialog, DialogContent, DialogTitle, Box } from '@mui/material';
import { CloseIcon } from '../../assets/icons/icons';
import ButtonInput from '../../components/ButtonInput';
import { useTheme } from '@mui/material';
import { useIntl } from 'react-intl';

export default function Dialog({ title, children, footer, isOpen, onClose, width }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <MuiDialog open={isOpen} maxWidth={'lg'} borderRadius="0" onBackdropClick={onClose}>
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: theme.palette.grey[900],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 16px',
          height: '60px',
          width: width && `${width}px`,
          color: theme.palette.grey[500],
          fontSize: '16px',
          fontFamily: 'Overpass, sans-serif',
        })}
      >
        <Box>{title}</Box>
        <ButtonInput title={formatMessage({ id: 'a11y.close' })} variant="link" onClick={onClose}>
          <CloseIcon color={theme.palette.grey[500]} />
        </ButtonInput>
      </DialogTitle>
      <DialogContent px="20px" sx={{ width }}>
        <Box my="40px">{children}</Box>
        <Box
          my="10px"
          sx={{ textDecoration: 'none', color: (theme) => theme.palette.primary.main }}
        >
          {footer}
        </Box>
      </DialogContent>
    </MuiDialog>
  );
}
