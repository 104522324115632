import { Button, useTheme } from '@mui/material';

export default function CheckboxButtonInput({ checked, children, onClick, ...rest }) {
  const theme = useTheme();

  const styles = {
    base: {
      backgroundColor: 'transparent',
      color: theme.palette.grey[900],
      border: `1px solid ${theme.palette.grey[700]}`,
      borderRadius: 0,
      flex: 1,
      fontFamily: 'Overpass, sans-serif',
      fontSize: '14px',
      width: '94px',
      marginRight: '50px',
    },
    active: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      border: `1px solid ${theme.palette.primary.main}`,
      '&:active, &:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  };

  return (
    <Button
      aria-checked={checked}
      aria-label={children}
      type="button"
      role="checkbox"
      onClick={onClick}
      style={checked ? { ...styles.base, ...styles.active } : { ...styles.base }}
      {...rest}
    >
      {children}
    </Button>
  );
}
