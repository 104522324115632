import React, { useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import CollapsibleInput from '../../inputs/CollapsibleInput';
import VehicleSpec from './VehicleSpec';
import ExpectedWorkload from './ExpectedWorkload';
import VehicleHeader from './VehicleHeader';
import { useDisplay } from '../../components/DisplayProvider';
import ButtonInput from '../../components/ButtonInput';
import { PencilIcon } from '../../assets/icons/icons';
import EditVehicleOverrides from '../../dialogs/editVehicleSetDialog/EditVehicleOverrides';
import { cloneDeep } from 'lodash';

import { omitComputedData } from '../../utils/computedData';

export default function Overview() {
  const { vehicleSets, setInput } = useInputs();
  const { mode } = useDisplay();

  const theme = useTheme();

  const [isEditingOverrides, setIsEditingOverrides] = useState(false);

  const handleChangeVehicleSet = (vehicleSet) => {
    return (newData) => {
      const index = vehicleSets.findIndex((set) => set.id === vehicleSet.id);
      const newSets = cloneDeep(vehicleSets);
      const overrideSet = {
        ...vehicleSet,
        ...newData,
      };
      newSets[index] = overrideSet;
      setInput({ vehicleSets: omitComputedData(newSets) });
    };
  };

  const OverrideAction = ({ vehicleSet }) => (
    <>
      <ButtonInput
        title="Edit"
        onClick={() => {
          setIsEditingOverrides(!isEditingOverrides);
        }}
        variant="icon"
      >
        <PencilIcon />
      </ButtonInput>
      <EditVehicleOverrides
        vehicleSet={vehicleSet}
        isOpen={isEditingOverrides}
        onClose={() => setIsEditingOverrides(false)}
        onChangeVehicleSet={handleChangeVehicleSet(vehicleSet)}
      />
    </>
  );

  return (
    <Grid container width="100%" rowSpacing={2}>
      {vehicleSets.map((set, idx) => {
        return (
          <Grid item xs={12} key={set.id} mb={mode === 'print' ? '300px' : '0'}>
            <CollapsibleInput title={set.name} hasAllBorder defaultOpen={idx === 0}>
              <Grid container px="46px" spacing={4}>
                <Grid item md={6} xs={mode === 'print' ? 6 : 12}>
                  <Grid container spacing={4} mb={2}>
                    <Grid item xs={12}>
                      <VehicleHeader vehicleSet={set} isElectric={false} />
                    </Grid>
                    <Grid item xs={12}>
                      <VehicleSpec
                        vehicle={
                          set.hasUserSpecifiedCustomVehicle
                            ? set.customFossilFuelVehicle
                            : set.existingVehicle
                        }
                        isElectric={false}
                        titleAction={
                          set.hasUserSpecifiedCustomVehicle ? (
                            ' '
                          ) : (
                            <OverrideAction vehicleSet={set} />
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ExpectedWorkload vehicleSet={set} />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Box mt="30px">
                      <Typography
                        fontFamily={theme.typography.fontFamily}
                        fontSize="12px"
                        color={theme.palette.grey[700]}
                        fontStyle="italic"
                        fontWeight="500px"
                        ml="15px"
                      ></Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item md={6} xs={mode === 'print' ? 6 : 12}>
                  <Grid container spacing={4} mb={2}>
                    <Grid item xs={12}>
                      <VehicleHeader vehicleSet={set} isElectric />
                    </Grid>
                    <Grid item xs={12}>
                      <VehicleSpec vehicle={set.replacementVehicle} isElectric />
                    </Grid>

                    <Grid item xs={12}>
                      <ExpectedWorkload vehicleSet={set} isElectric />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Box mt="30px">
                      <Typography
                        fontFamily={theme.typography.fontFamily}
                        fontSize="12px"
                        color={theme.palette.grey[700]}
                        fontStyle="italic"
                        fontWeight="500px"
                        ml="15px"
                      ></Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </CollapsibleInput>
          </Grid>
        );
      })}
    </Grid>
  );
}
