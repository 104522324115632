export const formatAsThousands = (val) =>
  Math.round(val)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatAsDollars = (val) => `$${formatAsThousands(val)}`;

export const formatAsCents = (val) => `$${val.toFixed(2)}`;

export const formatHours = (hours24) => {
  const hours = ((hours24 + 11) % 12) + 1;
  const amPm = hours24 > 11 ? 'pm' : 'am';
  return hours + amPm;
};

export const titleCase = (string) => {
  if (string === null || string === '') return '';
  const str = string.toString();

  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const formatVehicleName = ({ make, model, type, subtype }) => {
  const name = make ? `${make} ${model}` : `${type} (${subtype})`;
  return titleCase(name).replaceAll('_', ' ');
};

export const formatVehicleSetTitle = ({ vehicleCount, vehicle }) =>
  `${vehicleCount}x ${formatVehicleName(vehicle)}`;

export const formatChargerName = ({ make, model, model_variant }) =>
  `${make} ${model}${model_variant ? ` (${model_variant})` : ''}`;

export const formatGenericChargerName = ({ type, portKw }) => `${type} - ${portKw}kW`;

export const formatTimeRange = (start, finish) => `${formatHours(start)} - ${formatHours(finish)}`;
