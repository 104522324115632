import { createTheme } from '@mui/material/styles';

const BLUE_500 = '#007DBE';
const BLUE_700 = '#003C88';

const GREEN_100 = '#EDF7ED';
const GREEN_500 = '#4CAF50';
const GREEN_700 = '#008443';

const RED_100 = '#FEECEB';
const RED_300 = '#F44336';
const RED_500 = '#DD1D21';

const GREY_100 = '#F7F7F7';
const GREY_200 = '#E0E0E0';
const GREY_300 = '#D9D9D9';
const GREY_400 = '#D7D7D7';
const GREY_500 = '#CCCCCC';
const GREY_600 = '#A6A6A6';
const GREY_700 = '#777777';
const GREY_800 = '#404040';
const GREY_900 = '#252525';

const ORANGE_200 = '#FFEAC2';
const ORANGE_500 = '#EB8705';

const theme = createTheme({
  palette: {
    primary: {
      main: BLUE_500,
    },
    grey: {
      100: GREY_100,
      200: GREY_200,
      300: GREY_300,
      400: GREY_400,
      500: GREY_500,
      600: GREY_600,
      700: GREY_700,
      800: GREY_800,
      900: GREY_900,
    },
    common: {
      green: {
        100: GREEN_100,
        500: GREEN_500,
        700: GREEN_700,
      },
      red: {
        100: RED_100,
        300: RED_300,
        500: RED_500,
      },
      blue: {
        500: BLUE_500,
        700: BLUE_700,
      },
      orange: {
        200: ORANGE_200,
        500: ORANGE_500,
      },
      error: RED_500,
    },
  },
  typography: {
    fontFamily: 'Overpass, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Overpass', sans-serif;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          
        }
        @font-face {
          font-family: 'Overpass', sans-serif;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          
        }
        @font-face {
          font-family: 'Overpass', sans-serif;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
         
        }
        @font-face {
          font-family: 'Overpass', sans-serif;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          
        }
      `,
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: `1px solid ${GREY_700}`,
          borderRadius: '0',
          fontFamily: 'Overpass, sans-serif',
          textTransform: 'none',
          color: GREY_800,
          padding: '10px',
          height: '35px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderRadius: 0,
          },
        },
      },
    },
  },
});

export default theme;
