import { useIntl } from 'react-intl';
import ButtonInput from '../ButtonInput';

export default function GetStartedButton() {
  const { formatMessage } = useIntl();

  return (
    <a style={{ textDecoration: 'none' }} href="/fleets">
      <ButtonInput variant="filled">{formatMessage({ id: 'homepage.getStarted' })}</ButtonInput>
    </a>
  );
}
