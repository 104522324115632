import React from 'react';
import SpecCard from '../../components/SpecCard';
import { useIntl } from 'react-intl';

export default function VehicleSpec({ vehicle, isElectric, ...rest }) {
  const { formatMessage } = useIntl();
  const { rangeInMiles, milesPerGallon, fuel } = vehicle || {};

  const vehicleSpecs = isElectric
    ? [
        {
          spec: formatMessage({ id: 'panels.overview.range.electric.title' }),
          value: `${rangeInMiles ?? 0} ${formatMessage({ id: 'panels.overview.miles' })}`,
          //    disclaimer: formatMessage({ id: 'panels.overview.range.disclaimer' }),
          isLow: true,
        },
      ]
    : [
        {
          spec: formatMessage({ id: 'panels.overview.range.fossil.title' }),
          value: `${rangeInMiles} ${formatMessage({ id: 'panels.overview.miles' })}`,
          disclaimer: formatMessage({ id: 'disclaimer.vehicleData' }),
        },
        {
          spec: formatMessage({ id: 'panels.overview.fossilMPG' }),
          value: `${milesPerGallon} ${formatMessage(
            { id: 'panels.overview.mpg' },
            { type: formatMessage({ id: `panels.overview.${fuel ?? 'gas'}` }) },
          )}`,
          //    disclaimer: formatMessage({ id: 'panels.overview.estimateDisclaimer' }),
        },
      ];

  return (
    <SpecCard
      title={formatMessage({ id: 'panels.overview.specs' })}
      specList={vehicleSpecs}
      {...rest}
    />
  );
}
