import React from 'react';
import { Grid } from '@mui/material';
import VehicleSetInputs from './VehicleSetInputs';
import AssumptionInputs from './AssumptionInputs';
import LocationInputs from './LocationInputs';
import FuelInputs from './FuelInputs';
import ResetInputs from './ResetInputs';

export default function LeftInputs() {
  return (
    <Grid
      container
      sx={(theme) => ({ width: '100%', [theme.breakpoints.up('sm')]: { width: '286px' } })}
    >
      <Grid item xs={12} px={3} sx={(theme) => ({ [theme.breakpoints.up('sm')]: { px: 0 } })}>
        <VehicleSetInputs />
        <AssumptionInputs />
        <LocationInputs />
        <FuelInputs />
        <ResetInputs />
      </Grid>
    </Grid>
  );
}
