import computed from '../use-inputs/computed';
import { cloneDeep } from 'lodash';

const omit = (obj, ...props) => {
  const result = { ...obj };
  props.forEach((prop) => {
    delete result[prop];
  });
  return result;
};

const omitChargers = (chargingWindows) => {
  const newChargingWindows = cloneDeep(chargingWindows);

  for (let i = 0; i < newChargingWindows.length; i++) {
    newChargingWindows[i] = omit(newChargingWindows[i], 'charger');
  }
  return newChargingWindows;
};

//Remove the computed vehicle/charger data out of the vehicles obj before saving to state
export const omitComputedData = (vehicleSets) => {
  const newSets = cloneDeep(vehicleSets);

  for (let i = 0; i < newSets.length; i++) {
    newSets[i] = omit(newSets[i], 'existingVehicle', 'replacementVehicle');
    newSets[i].chargingWindows = omitChargers(newSets[i].chargingWindows);
  }
  return newSets;
};

// Add computed vehicle/charger data so 'working' state (not directly from use-inputs) can access the full state
export const addComputedData = (inputs) => {
  const computedParams = Object.keys(computed)
    .filter((key) => inputs.hasOwnProperty(key))
    .reduce((acc, key) => {
      return { ...acc, [key]: computed[key]({ ...inputs }) };
    }, {});

  return { ...inputs, ...computedParams };
};
