import React, { useEffect } from 'react';
import { useInputs } from '@bellawatt/use-inputs';
import { Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import Loading from './components/Loading';
import Homepage from './components/homepage/Homepage';
import ContentPane from './tabs/ContentPane';
import Header from './components/Header';
import Footer from './components/Footer';
import useApiLocationData from './utils/useApiLocationData';
import useApiIncentiveData from './utils/useApiIncentiveData';
import OnboardingLayout from './onboarding/OnboardingLayout';
import useApiEvaluatedIncentiveData from './utils/useApiEvaluatedIncentiveData';
import ContentPrint from './components/ContentPrint';
import DisplayProvider from './components/DisplayProvider';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';

function App() {
  const { loading, isOnboarded, isResetting } = useInputs();

  // dynamically fetch fuel prices, incentives and set state when certain inputs change
  useApiLocationData();
  useApiIncentiveData();
  useApiEvaluatedIncentiveData();

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);

  return (
    <div
      className="App"
      style={{
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
      }}
    >
      <ErrorBoundary>
        <DisplayProvider>
          {loading || isResetting ? (
            <Loading />
          ) : (
            <>
              <Route path="/print" component={ContentPrint} />
              <Route path="/">
                <Header />
                <main style={{ flex: '1 1' }}>
                  <Route exact path="/" component={Homepage} />
                  <Route path="/fleets" component={isOnboarded ? ContentPane : OnboardingLayout} />
                </main>
                <Footer />
              </Route>
            </>
          )}
        </DisplayProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
