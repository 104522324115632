import React from 'react';
import { Box, useTheme } from '@mui/material';
import StyledBarGraph from '../../components/charts/StyledBarGraph';
import { formatAsDollars } from '../../utils/formatters';
import { useIntl } from 'react-intl';

export default function SavingsChart({ fossilFleetCosts, evFleetCosts }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const labels = [
    formatMessage({ id: 'panels.financials.fuel' }),
    formatMessage({ id: 'panels.financials.vehicles' }),
    formatMessage({ id: 'panels.financials.savings' }),
  ];

  const {
    fuel: fossilFuel,
    incentives: fossilIncentives,
    purchase: fossilPurchase,
  } = fossilFleetCosts.byCategory;

  const { fuel: evFuel, incentives: evIncentives, purchase: evPurchase } = evFleetCosts.byCategory;

  const fuelSavings = fossilFuel - evFuel;
  const purchaseSavings = fossilPurchase - evPurchase;
  const incentivesSavings = evIncentives - fossilIncentives;
  //fossil chargers should theoretically always equal 0

  //The first 3 indexes correlate to fuel, vehicles, and incentives.
  //The values will be set depending on whether fuel or electric is cheaper.

  const evIsCheaper = [
    fuelSavings >= 0 ? [0, fuelSavings] : 0,
    purchaseSavings >= 0 ? [fuelSavings, fuelSavings + purchaseSavings] : 0,
    0,
  ];

  const fossilIsCheaper = [
    fuelSavings < 0 ? [0, fuelSavings] : 0,
    purchaseSavings < 0 ? [fuelSavings, fuelSavings + purchaseSavings] : 0,
    0,
  ];

  const savings = [0, 0, fuelSavings + purchaseSavings + incentivesSavings];

  const chargeData = {
    labels,
    datasets: [
      {
        label: formatMessage({ id: 'panels.financials.evCheaper' }),
        data: evIsCheaper,
        backgroundColor: [theme.palette.primary.main],
        barPercentage: 0.6,
      },
      {
        label: formatMessage({ id: 'panels.financials.fossilCheaper' }),
        data: fossilIsCheaper,
        backgroundColor: [theme.palette.grey[800]],
        barPercentage: 0.6,
      },
      {
        label: formatMessage({ id: 'panels.financials.savings' }),
        data: savings,
        backgroundColor: [theme.palette.common.green[500]],
        barPercentage: 0.6,
      },
    ],
  };

  const xTicks = {
    min: 0,
    padding: 20,
    color: theme.palette.grey[900],
    font: {
      size: 14,
      family: 'Overpass, sans-serif',
    },
  };

  const yTicks = {
    min: 0,
    callback: (value) => `${formatAsDollars(value)}`,
    color: theme.palette.grey[900],
    padding: 20,
    font: {
      size: 14,
      family: 'Overpass, sans-serif',
    },
  };

  return (
    <Box height="400px" maxWidth="890px">
      <StyledBarGraph
        data={chargeData}
        graphTitle={formatMessage({ id: 'panels.financials.savingsChart' }).toUpperCase()}
        yTicks={yTicks}
        xTicks={xTicks}
        showLegend
        stacked
        tooltipLabelFormatter={(data) => {
          const amount = Array.isArray(data.raw) ? data.raw[1] - data.raw[0] : data.raw;
          return `${data.dataset.label}: ${formatAsDollars(amount)}`;
        }}
        tooltipTitleFormatter={(data) => data[0].label}
      />
    </Box>
  );
}
