import { Box, Grid, Typography, useTheme } from '@mui/material';
import feature from '../../assets/images/homepage/feature.png';
import Content from './Content';
import { useIntl } from 'react-intl';

export default function Fleets() {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: `url(${feature}) center no-repeat`,
        backgroundSize: 'cover',
      }}
      mb={2}
    >
      <Content pb={10}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography fontSize="42px" lineHeight="52px" fontWeight={600} color="#FFF" pb={4}>
              {formatMessage({ id: 'homepage.fleetsImpact.header' })}
            </Typography>
            <Typography fontSize="20px" color="#FFF" fontFamily={theme.typography.fontFamily}>
              {formatMessage({ id: 'homepage.fleetsImpact.subheader' })}
            </Typography>
          </Grid>
        </Grid>
      </Content>
    </Box>
  );
}
