import { Box, useTheme, Typography } from '@mui/material';
import StyledTable from '../../components/charts/StyledTable';
import { formatAsDollars, formatAsThousands, formatAsCents } from '../../utils/formatters';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import VehicleSet from '../../calculations/VehicleSet';
import sum from 'lodash/sum';
import ToolTip from '../../components/ToolTip';

export default function ElectricFleetTable({ electricCosts }) {
  const { vehicleSets, electricityPrice, lifespanYears } = useInputs();
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const labels = [
    formatMessage({ id: 'panels.fuel.set' }),
    formatMessage({ id: 'panels.fuel.count' }),
    formatMessage({ id: 'panels.fuel.miles' }),
    [
      formatMessage({ id: 'panels.fuel.fuelCost' }),
      <Typography fontSize="12px" key={2}>
        {formatMessage({ id: 'panels.fuel.table.electricDollars' })}
      </Typography>,
    ],
    formatMessage({ id: 'panels.fuel.cost' }),
  ];

  const vehicleSetData = vehicleSets.map((set) => {
    return [
      set.replacementVehicle.name,
      set.vehicleCount,
      <ToolTip
        content={formatMessage(
          { id: 'panels.fuel.table.toolTip' },
          { total: formatAsThousands(VehicleSet.annualMiles(set)) },
        )}
      >
        {formatAsThousands(VehicleSet.annualMiles(set))}
      </ToolTip>,
      formatAsCents(electricityPrice),
      formatAsDollars(electricCosts[set.id]),
    ];
  });

  const rows = [
    ...vehicleSetData,
    [
      <Typography fontSize="14px" fontFamily={theme.typography.fontFamily}>
        {formatMessage({ id: 'panels.fuel.annualTotal' })}
      </Typography>,
      null,
      null,
      null,
      formatAsDollars(sum(Object.values(electricCosts))),
    ],
    [
      <Typography fontSize="14px" fontFamily={theme.typography.fontFamily}>
        {formatMessage({ id: 'panels.fuel.lifetime' }, { time: lifespanYears })}
      </Typography>,
      null,
      null,
      null,
      formatAsDollars(sum(Object.values(electricCosts)) * lifespanYears),
    ],
  ];

  return (
    <Box>
      <Typography mb="12px" fontSize="14px" fontFamily={theme.typography.fontFamily}>
        {formatMessage({ id: 'panels.fuel.electricFleet' }).toUpperCase()}
      </Typography>
      <StyledTable
        headings={labels}
        rows={rows}
        headerColor={theme.palette.primary.main}
        firstColumnColor={`${theme.palette.primary.main}33`}
        firstCellColor={theme.palette.grey[900]}
        borderColor={theme.palette.grey[900]}
      />
    </Box>
  );
}
