import React from 'react';
import { Button, useTheme } from '@mui/material';
import ReactGA from 'react-ga';

export default function ButtonInput({
  variant = 'outlined',
  onClick,
  alert,
  children,
  textTransform,
  fullWidth,
  isDisabled,
  title,
}) {
  const theme = useTheme();

  const styles = {
    base: {
      borderRadius: '0',
      fontFamily: 'Overpass, sans-serif',
      fontSize: '14px',
      width: fullWidth ? '100%' : 'auto',
      cursor: 'pointer',
      textTransform: 'none',
    },
    outlined: {
      minHeight: '40px',
      lineHeight: 1.1,
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.grey[500]}`,
      backgroundColor: 'transparent',
      textTransform: textTransform ?? 'uppercase',
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.grey[100],
      },
    },
    outlinedNoTransform: {
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.grey[500]}`,
      backgroundColor: 'transparent',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    filled: {
      color: '#FFF',
      backgroundColor: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    warning: {
      color: 'white',
      backgroundColor: theme.palette.common.red[500],
      border: '2px solid transparent',
    },
    contained: {
      textTransform: textTransform ?? 'uppercase',
      border: `2px solid ${theme.palette.primary.main}`,
    },
    link: {
      color: alert ? theme.palette.common.red[500] : theme.palette.primary.main,
      fill: alert ? theme.palette.common.red[500] : theme.palette.primary.main,
      fontSize: '14px',
      textDecoration: 'none',
      padding: '12px',
    },
    icon: {
      color: alert ? theme.palette.common.red[500] : theme.palette.primary.main,
      fill: alert ? theme.palette.common.red[500] : theme.palette.primary.main,
      padding: '8px',
      minWidth: 'unset',
    },
  };

  return (
    <Button
      title={title}
      disabled={isDisabled}
      variant={variant}
      onClick={(event) => {
        ReactGA.event({
          category: 'Button',
          action: 'Click',
          label: children,
        });
        onClick(event);
      }}
      sx={{
        ...styles.base,
        ...styles[variant],
      }}
    >
      {children}
    </Button>
  );
}
