import times from 'lodash/times';
import moment from 'moment-timezone';

const translations = {
  en: {
    translation: {
      a11y: {
        edit: 'Edit',
        close: 'Close',
      },
      disclaimer: {
        vehicleData: 'Current vehicle data provided by user.',
      },
      header: 'GM FLEETS',
      tabs: {
        overview: 'Overview',
        financials: 'Financials',
        fuel: 'Fuel Costs',
        incentives: 'Incentives',
        comparison: 'Vehicle Comparison',
        charging: 'Charging',
        emissions: 'Emissions',
        print: 'Print',
      },
      inputs: {
        vehicleSet: {
          title: 'Vehicle Sets',
          addNewButton: '+ Add New Vehicle Set',
          editCard: {
            specs: {
              type: 'Type',
              milesPerWorkday: 'Miles per workday',
              daysOperating: 'Days operating',
              chargingWindows: 'Charging windows',
              multipleWindows: 'Multiple time slots',
            },
          },
          vehicleCatalog: {
            title: 'Select an EV that meets your needs',
            subtitle: 'Select an available electric vehicle that meets your needs for {setName}',
            sortBy: 'Sort By',
            showing: 'Showing {subset} of {total} vehicles',
            electricVehicle: 'Electric Vehicle',
            recommendedVehicle: 'Recommended Vehicle',
            selected: 'Selected Vehicle',
            selectReplacement: 'Select As Replacement',
            type: 'Type',
            subtype: 'Subtype',
            milesPerDay: 'Miles per day',
            daysOperating: 'Days operating',
            replacementYear: 'Replacement Year',
            range: 'Range',
            capacity: {
              battery: 'Battery Capacity',
              cargo: 'Cargo Capactiy',
              passenger: 'Passenger Capacity',
            },
            headroom: 'Headroom',
            legroom: 'Legroom',
            availability: 'Availability',
            milesDay: 'Miles / Day',
            msrp: 'MSRP',
            cancel: 'Cancel',
            confirm: 'Confirm Selection',
            sortOptions: {
              rangeDesc: 'Range (decreasing)',
              rangeAsc: 'Range (increasing)',
            },
            make: 'Make',
            vehicleRange: 'Vehicle Range',
            filters: 'Filters',
            over: 'Over 500 mi',
            lessThan: 'Less than 250 mi',
            between: '251 mi - 500 mi',
          },
          overrides: {
            title: 'Edit Vehicle Specs',
            mpg: 'Custom Vehicle MPG',
            range: 'Custom Vehicle Range',
            msrp: 'Custom Vehicle MSRP',
            gvwr: 'Custom Vehicle GVWR',
            make: 'Custom Vehicle Make',
            year: 'Custom Vehicle Year',
            type: 'Custom Vehicle Type',
            subtype: 'Custom Vehicle Subtype',
            model: 'Custom Vehicle Model',
            name: 'Custom Vehicle Name',
            includedWeight:
              'When properly equipped; includes weight of vehicle, passengers, cargo and equipment',
          },
          editSetDialog: {
            title: 'Edit Vehicle Set',
            selection: 'Vehicle Selection',
            usage: 'Vehicle Usage',
            behavior: 'Charging Behavior',
            cancel: 'Cancel',
            save: 'Save',
            delete: 'Delete Vehicle Set',
            warningBold: 'Delete this vehicle set? ',
            warning: 'You will be unable to recover any data from it.',
            yes: 'Yes, Delete it',
            name: 'Name your vehicle set',
            alertExceed:
              'If the daily miles are near to or exceed the estimated range of the vehicle then they will need to be charged up more than once per day (e.g. in the middle of the day). Alternatively you may want to explore other EV models with higher estimated ranges, or reduce the number of vehicles of this type on the previous screen.',
            vehicleCount: '# Of Vehicles',
            currentPlanned: 'Your Current/Planned Vehicle',
            avgMiles: 'Average Business Miles Per Vehicle (Per Day)',
            avgPersonal: 'Average Personal Miles Per Vehicle (Per Day)',
            includePersonal: 'Include Personal Miles',
            personalPaidFor:
              'My company pays for the fuel for employees personal miles - potential savings for personal miles will be calculated.',
            combined: 'Business and Personal Miles Combined',
            daysOperation: 'Days in Operation',
            monthsOperation: 'Months in Operation',
            weekdays: 'Weekdays Only',
            allMonths: 'All Months',
            customMonths: 'Have custom months of operation?',
            customDays: 'Have custom days of operation?',
            specifyMonths: 'Specify Months',
            specifyDays: 'Specify Days',
            vehicleChangeWarning: 'This change will affect your recommended electric vehicle',
            warningText: 'Warning',
            alertText: 'Alert',
            empty: 'Empty',
            professionalInstallation: 'Chargers require professional installation',
            lowBatteryWarning:
              '{warning}: With this configuration, the estimated minimum state of charge across the week will be {percent}. Are you sure the charging times, charging level(s), and daily miles are a conservative estimate?',
            lowBatteryWarning2:
              'You may want to increase charging times or select chargers with higher outputs',
            depletedBatteryWarning:
              '{warning}: With this configuration, the batteries may be depleted. Please add more charging times, increase the charging level(s), and/or decrease the daily miles.',
            chargerSelectedMessage:
              'A specific charger was selected. To edit this selection, please go to the "Charging" tab.',
            OverlapWarning:
              '{warning}: You cannot have overlapping charging times for this vehicle set',
            override: {
              range: 'Custom Range',
              mpg: 'Custom MPG',
              gvwr: 'Custom GVWR',
            },
            customVehicle: 'Input Custom Vehicle',
            chooseGMVehicle: 'Choose GM Vehicle',
            customFossilFuelVehicle: {
              mpg: 'Fossil Fuel Vehicle MPG',
              range: 'Fossil Fuel Vehicle Range',
              msrp: 'Fossil Fuel Vehicle MSRP',
              gvwr: 'Fossil Fuel Vehicle GVWR',
              make: 'Fossil Fuel Vehicle Make',
              year: 'Fossil Fuel Vehicle Year',
              type: 'Fossil Fuel Vehicle Type',
              subtype: 'Fossil Fuel Vehicle Subtype',
              fuelType: 'Fossil Fuel Vehicle Fuel Type',
              model: 'Fossil Fuel Vehicle Model',
              name: 'Fossil Fuel Vehicle Name',
            },
          },
        },
        assumptions: {
          title: 'Assumptions',
          vehicleLifetime: {
            label: 'Years in Operation',
            tooltip:
              'The vehicle lifetime is the expected length of time you plan to drive the vehicle. This value is used to calculate potential savings and potential operations costs.',
          },
          organizationType: {
            label: 'Organization Type',
            tooltip: 'The organization type to identify potential third party incentives.',
          },
          publicEntity: {
            label: 'Public Entity',
            tooltip:
              'Used to indicate if your business is a public entity, the entity type is used to identify potential third party incentives.',
          },
          taxExempt: {
            label: 'Tax Exempt',
            tooltip:
              'Used to indicate if your business is a tax exempt entity, the tax exempt field is used to identify potential third party incentives.',
          },
          multipleSitesPurchase: {
            header: 'Purchasing for multiple sites',
            subheader:
              '(National average fuel prices will be applied and local incentives will not be analyzed)',
          },
        },
        location: {
          title: 'Site Location',
          zipCode: {
            label: 'Zipcode',
            tooltip:
              'Your location drives electricity and fuel costs. Electricity costs are sourced from {linkUSIA}. Fuel costs are sourced from {linkAAA}.',
            formatError: 'Zipcode should be 5 digits',
          },
          inDac: {
            label: 'In a DAC',
            tooltip:
              'Disadvantaged Community (DAC) locations may be eligible to receive additional third party incentives.',
          },
          btmConstructionCosts: {
            label: 'BTM Construction Costs',
            tooltip: 'Behind the meter (BTM) construction costs',
          },
        },
        fuel: {
          title: 'Fuel Costs',
          gasolinePrice: {
            label: 'Gasoline Price ($/Gal)',
            tooltip:
              'Based on current average fuel prices in your state. Gasoline price is updated every 2 business days to the most recent available from AAA. Price is updated in the fleets tool live with the AAA update. SRC: {link}',
          },
          dieselPrice: {
            label: 'Diesel Price ($/Gal)',
            tooltip:
              'Based on current average fuel costs for your state. Diesel price is updated every 2 business days to the most recent available from AAA. Price is updated in the fleets tool live with the AAA update. SRC: {link}',
          },
          electricityPrice: {
            label: 'Electricity Cost ($/kWh)',
            link: 'US Energy Information Administration',
            tooltip:
              'Electricity cost is updated annually to the most recent available from US EIA. Cost is updated in the fleets tool live with the US EIA update. SRC: {link}',
          },
        },
        booleanOptions: {
          yes: 'Yes',
          no: 'No',
        },
        chargerCatalog: {
          title: 'Select a charger that meets your needs',
          subtitle:
            'To charge a {vehicle} driving {miles} miles per day, chargers must provide a minimum of {kWhPerWorkday} per day',
          showMore: 'Show More',
          requirements: 'Charger Requirements',
          vehicle: 'Vehicle',
          vehicleCount: 'Number of Vehicles',
          capacity: 'Battery Capacity',
          milesPerDay: 'Miles Per Day',
          efficiency: 'Efficiency',
          window: 'Charging Window',
          duration: 'Charging Duration',
          output: 'Required Output',
          showing: 'Showing {subset} of {total} chargers',
          selected: 'Selected Charger',
          selectReplacement: 'Select As Replacement',
          generic: 'Generic Chargers Only',
          allBranded: 'All Branded Chargers',
          sortOptions: {
            msrpDesc: 'MSRP (decreasing)',
            msrpAsc: 'MSRP (increasing)',
          },
          brand: 'Charger Brand',
          minOutput: 'Minimum Output',
          price: 'Maximum Price',
          ports: 'Ports',
          cable: 'Cable Length',
          type: 'Type',
          chargerType: 'Charger Type',
          miles: 'miles',
          mpge: 'MPGe',
          milesAb: 'mi',
          hours: 'hrs',
          upTo: 'Up to',
          all: 'All',
        },
        inputsButton: 'Inputs & Assumptions',
        updateButton: 'Update',
        reset: 'Reset Inputs',
        confirmResetInputs: 'Are you sure you want to reset all of your inputs?',
      },
      fleetSummary: {
        title: 'Fleet Summary',
        lifetime: 'Potential {time} year Savings',
        size: 'Size',
        fuelSavings: 'Potential Annual Fuel Savings',
        perMile: 'Per Mile',
        incentives: 'Federal, State, & Local potential Incentives',
        emissions: 'Total CO₂ Emissions Saved',
        vehicles: '{value} vehicles',
        tons: '{value} tons',
        tooltips: {
          a: 'a',
          an: 'an',
          lifetime:
            'Potential Life Savings is based on the customer’s data input of vehicle fleet selection and usage habits, potential fuel savings, vehicle purchase prices, and charging infrastructure costs over the course of {gramar} {time}-year{plural} vehicle lifespan. Actual savings will vary based on real-life usage of vehicle fleet and factors outside of the scope of this estimation. This Potential Life Savings estimation are provided for example purposes only, GM does not warrant or guarantee the realization of any potential savings. Potential federal, state and local incentives and any tax amounts are not considered in the savings calculation',
          annualTou:
            'Based on customer data inputs of daily miles driven, days and months of operation over a { years }-year period, using the user specified zipcode based on AAA state average  price of { dieselFuelCost } per gallon for diesel and { gasFuelCost } per gallon of gasoline and and applicable commercial rates based on time of use based on the commercial rates of {utility}. Potential federal, state and local incentives are not considered in the savings calculation.',
          annualKwh:
            'Based on customer data inputs of daily miles driven, days and months of operation over a { years }-year period, using the user specified zipcode based on AAA state average  price of { dieselFuelCost } per gallon for diesel and { gasFuelPrice } per gallon of gasoline and local electricity costs at { electricPrice } kWh based on annually updated electricity prices obtained from the EIA.',
          incentives:
            'Potential federal, state and local incentives are estimates provided by ZappyRide on a monthly basis and provided for example purposes only. These incentive projections are based on certain assumptions and not all consumers will qualify for the maximum incentive or at all. Potential federal, state and local incentives may be limited to the first year of ownership and are not over the life of the vehicle. Please review the language of each individual incentive to determine eligibility and availability and consult with your legal counsel. Potential federal, state and local incentives are subject to change at any time. GM does not warrant or guarantee the availability of any potential incentives. Please check with your legal counsel for the most up to date information on availability and eligibility of potential incentives. Potential federal, state and local incentive amounts are not included in the Potential { years }-year savings amounts.',
          emissions:
            'Emissions saved are calculated by subtracting the emissions of the electric vehicles based on their efficiency, distance driven and the grams of CO2 emitted per kWh consumed for the specified site location. Complete details of the emissions saving calculation can be seen on the "Emissions" tab.',
        },
      },
      homepage: {
        getStarted: 'Get Started',
        hero: {
          headerPrimary: 'Electrify your fleet with General Motors.',
          subheader:
            'Tell us about your fleet, and we’ll recommend the best electric vehicles to handle the job.',
        },
        aboutYourFleet: {
          header: 'Tell us about your fleet. We’ll run the numbers.',
          subheader:
            'Run the numbers to calculate what you need to know to decide if electrifying your fleet with GM is the right move for you.',
        },
        matchVehicle: {
          header: 'Match with the right vehicle.',
          subheader: 'Can electric vehicles keep up with my workload?',
        },
        forcastCosts: {
          header: 'Forecast Potential Costs and Savings.',
          subheader: 'How soon will this investment pay for itself?',
        },
        findIncentive: {
          header: 'Find EV incentives.',
          subheader: 'How much is available and how do I apply?',
        },
        compareGasoline: {
          header: 'Compare to gasoline vehicles.',
          subheader: 'What are my combustion engine alternatives?',
        },
        fleetsImpact: {
          header: 'Changing your fleet can make a big impact.',
          subheader: 'See how you can reduce your emissions.',
        },
        makePlan: {
          header: 'Let’s make a plan, together.',
          subheader: 'Tell us about your fleet. We’ll do the rest.',
        },
      },
      onboarding: {
        stepXof: 'step {stepNumber} of 5',
        back: 'Back',
        gettingStarted: {
          title: 'Going electric starts with understanding your needs',
          leadCopy:
            'We need to understand your requirements so we can recommend the appropriate GM electric vehicle and identify incentives.',
          tellUsOrganization: 'Tell us about your organization',
          tellUsVehicle: 'Tell us about your vehicles',
          seeSavings: 'See how much you could potentially save',
          createFirstVehicleSet: 'Create Your First Vehicle Set',
          doLater: "I'll Do This Later",
        },
        generalInformation: {
          title: 'General Information',
          leadCopy: 'We use this information to calculate fuel costs and applicable incentives.',
          organizationType: {
            label: 'Organization Type',
            tooltip:
              'We ask for the organization type so that we can identify the applicable federal, state, utility and / or local incentives.',
          },
          zipCode: {
            label: 'Zip Code',
            tooltip: 'We ask for the zipcode so we can identify applicable incentives',
          },
          seeDefinition: 'Select an option to see definition',
          organizationDescriptions: {
            car_sharing_companies:
              'Companies that allow people to rent cars for short periods of time, such as by the hour. Examples: ZipCar, Getaround...',
            federal_government_entities:
              'The government of the United States or any bureau, department or other agency of the federal government. Examples: Department of Transportation, FBI...',
            local_government_entities:
              'A county, incorporated city, emergency services district, other special district, joint board, or other entity defined as a political subdivision that maintains the capability to provide mutual aid.',
            logistics_company: 'Companies that move goods. Examples: UPS, DHL, Ryder...',
            medium_or_large_businesses:
              'Depending on the industry, medium and large businesses have at least 250 to 1,500 employees. Visit the Small Business Administration website for an industry specific definition. Few if any incentives distinguish between small, medium or large businesses. ',
            native_tribes: 'The recognized governing body of any Native American tribe.',
            nonprofits:
              'Legal entities that operate for a collective, public or social benefit as opposed to generating profits. Typically these companies are tax-exempt and hold 501(c)(3) or 501(c)(7) status.',
            private_school_districts: 'Privately funded schools and school districts.',
            public_school_districts: 'Government funded schools and school districts.',
            rental_companies:
              'Companies that rent vehicles for short periods of time ranging from a few hours to a few weeks. Examples: Hertz, Avis...',
            small_businesses:
              'Depending on the industry, small businesses have a minimum of 1 and a maximum of 250 to 1,500 employees. Visit the Small Business Administration website for an industry specific definition. Few if any incentives distinguish between small, medium or large businesses. ',
            state_government_entities:
              'State or any department, agency, commission, or authority of the state. E.g. California Highway Patrol, New York State Department of Health',
            transit_agencies: 'Public entity responsible for managing transit services.',
            universities:
              'An institute of higher education and research which grants academic degrees.',
            utility:
              'A provider of a service used by the public such as an electricity or gas supply.',
            other: 'Entitities not covered by other definitions.',
          },
          vehiclesLocated: 'Where vehicles will be located',
          purchasingMultipleSites: {
            header: 'I am purchasing for multiple sites',
            subHeader:
              '(National average fuel prices will be applied and local incentives will not be analyzed)',
          },
          next: 'Next: Select Vehicle',
          zipcodeError: 'Enter a 5 digit zipcode, or check "I am purchasing for multiple sites"',
        },
        vehicleUsage: {
          title: 'Vehicle Usage',
          leadCopy:
            'This information drives range and charging requirements determining EV and charger recommendations and it is used to calculate fuel costs. Please fill out based on the conventional gas / diesel vehicle you are replacing or planning to replace your vehicle with.',
          next: 'Results',
        },
        selectVehicle: {
          title: 'Select Vehicle',
          switchTitle:
            'Select your fossil fuel vehicle by type and subtype, enter vehicle specs, then select an EV replacement model.',
          switchButton1: 'Fossil Fuel Make/Model',
          switchButton2: 'EV Replacement Model',

          leadCopy: 'Tell us about your plans for purchasing new or replacement vehicles',
          next: 'Next: Vehicle Usage',
          numberVehicles: '# Of Vehicles',
          vehicleMake: 'Your Current/Planned Vehicle Make',
          vehicleModel: 'Your Current/Planned Vehicle Model',
          name: 'Name your vehicle set',
          range: 'Range',
          miles: 'miles',
          capacity: 'Capacity',
          milesKw: 'Efficiency',
          disclaimer: {
            ice: 'EPA estimated range. Actual range will vary based on several factors, including, temperature, terrain, loading, use and maintenance.',
            ev: 'On a full charge. EPA estimated. Actual range will vary based on several factors, including ambient temperature, terrain, battery age and condition, loading, and how you use and maintain your vehicle.',
          },
          mpgDiesel: 'Mpg diesel for an equivalent fossil vehicle',
          weightClass: 'Weight Class',
          fuel: 'Fuel',
          current: 'Current/Planned Vehicle',
          equivalent: 'Equivalent Electric Vehicle',
          mpge: '{value} MPGe',
          specs: 'Standard Vehicle Specs',
          epaEstimatedRange: ' EPA Estimated Range',
          customFossilFuelVehicle: {
            mpg: 'Fossil Fuel Vehicle MPG',
            range: 'Fossil Fuel Vehicle Range',
            msrp: 'Fossil Fuel Vehicle MSRP',
            gvwr: 'Fossil Fuel Vehicle GVWR',
            make: 'Fossil Fuel Vehicle Make',
            year: 'Fossil Fuel Vehicle Year',
            type: 'Fossil Fuel Vehicle Type',
            subtype: 'Fossil Fuel Vehicle Subtype',
            model: 'Fossil Fuel Vehicle Model',
            name: 'Fossil Fuel Vehicle Name',
            fuelType: 'Fossil Fuel Vehicle Fuel Type',
            replacement: 'Your Current/Planned GM Model',
            includedWeight:
              'When properly equipped; includes weight of vehicle, passengers, cargo and equipment',
          },
        },
        chargingBehavior: {
          title: 'Charging Behavior',
          leadCopy:
            'Think ahead to when you expect to charge your new electrified fleet. Enter each daily window of time you expect to charge your vehicles and where, and ZappyRide will recommend ideal charger type(s). You can pick specific charger makes and models later.',
          next: 'Done',
          selectChargersFor: 'Selecting Charger(s) for each:',
          vehicleDetails: 'driving an average of {miles} miles per day',
        },
      },
      panels: {
        specs: 'Standard Vehicle Specs',
        warning: 'Warning!',
        insufficientChargeWarning: 'The following vehicles deplete their batteries:',
        insufficientChargeCTA:
          'Please Edit Inputs to add more charging times, increase the charging levels, and/or decrease the daily miles.',
        overview: {
          fossilAlt: 'Fossil Fuel Alternative',
          electric: 'Electric Fleet',
          specs: 'Vehicle Specs',
          workload: 'Expected Workload',
          range: {
            electric: {
              title:
                'On a full charge. EPA estimated. Actual range will vary based on several factors, including ambient temperature, terrain, battery age and condition, loading, and how you use and maintain your vehicle.',
            },
            fossil: {
              title: 'Range',
            },
            disclaimer:
              'EPA estimated range on a full charge. Actual range and MPGe will vary based on several factors, including temperature, terrain, battery age, loading, use and maintenance.',
          },
          capacity: 'Battery Capacity',
          fossilMPG: 'Fossil Fuel MPG',
          electricMPG: 'Fossil Fuel MPG Equivalent',
          estimateDisclaimer:
            'EPA estimated range on a full charge. Actual range and MPGe will vary based on several factors, including temperature, terrain, battery age, loading, use and maintenance.',
          mpgEquivalent: 'miles per gallon equivalent',
          workdays: 'Workdays',
          weekdays: 'Weekdays',
          typicalWorkday: 'Typical Workday',
          window: 'Charging Window',
          windows: 'Charging Windows',
          approach: 'Approach',
          miles: 'miles',
          mpg: 'miles per gallon ({type})',
          gas: 'gasoline',
          gasoline: 'gasoline',
          diesel: 'diesel',
          disclaimer:
            'EPA estimated range on a full charge. Actual range and MPGe will vary based on several factors, including temperature, terrain, battery age, loading, use and maintenance.',
        },
        financials: {
          payback: {
            years: 'After {value} years',
            year: 'After {value} year',
            summary:
              'the cumulative cost of electric vehicles becomes cheaper than the cost of an equivalent fossil fuel fleet.',
            disclaimer: 'Breakeven does not consider potential incentives.',
            noBreakeven: 'No Breakeven',
            noBreakevenText:
              'The cumulative cost of electric vehicles potentially becomes cheaper than the cost of an equivalent fossil fuel fleet.',
          },
          lifetime: {
            look: "You're looking at ",
            savings: '{value} in potential savings ',
            overTime: 'potential {years} year savings.',
            disclaimer: 'Savings do not consider potential incentives.',
          },
          overTimeCosts: 'Vehicle purchase and fuel costs over time',
          barChart: 'Bar Chart',
          waterfall: 'Waterfall',
          fuel: 'Fuel',
          vehicles: 'Purchase Cost',
          incentives: 'Incentives',
          chargers: 'Chargers',
          vehiclePurchase: 'Vehicle Purchase',
          chargingInfrastructure: 'Charging Infrastructure',
          total: 'Total',
          categories: 'Categories',
          electric: 'Electric Vehicle(s)',
          fossil: 'Fossil Vehicle(s)',
          savings: 'Potential EV Savings',
          costs: 'costs',
          savingsChart: 'Savings from EV vs Fossil Fuel',
          evCheaper: 'EV is Cheaper',
          fossilCheaper: 'Fossil is Cheaper',
          evSavings: 'Potential Ev Savings',
          fossilVeh: 'Fossil Fuel Vehicles',
          electricVeh: 'Electric Vehicles',
          tooltips: {
            evcost:
              'Electricity cost is updated annually to the most recent available from US EIA. Cost is updated in the fleets tool live with the US EIA update. SRC:{link}',
            ffcost:
              'Based on current average fuel costs for your state. Fuel prices are updated every 2 business days to the most recent available from AAA. Prices are updated in the fleets tool live with the AAA update. SRC:{link}',

            incentives: 'A detailed breakdown of incentives can be found in the incentives tab.',
            breakeven:
              'Breakeven is based on cumulative costs  for fossil fuel and electric vehicles comprised of vehicle MSRP, estimated charger cost and charger installation costs after potential incentives are applied; fuel costs are based on user entered daily mileage, days and months of operation and respective gasoline, diesel and electric fuel costs using the state average gas price for the zipcode of {dieselFuelCost} per gallon for diesel and {gasFuelCost} per gallon of gasoline and applicable commercial rates based on time of use.',
            savings:
              'Savings are based on cumulative costs over the {years} year vehicle use for fossil fuel and electric vehicles comprised of vehicle MSRP, estimated charger cost and charger installation costs after potential incentives are applied; fuel costs are based on user entered daily mileage, days and months of operation and respective gasoline, diesel and electric fuel costs using the state average gas price for the zipcode of {dieselFuelCost} per gallon for diesel and {gasFuelCost} per gallon of gasoline and applicable commercial rates based on time of use.',
          },
        },
        fuel: {
          yearly: 'Potential Yearly Fuel Costs',
          eFleet: 'Electric Vehicle Fleet',
          gFleet: 'Gasoline Fleet',
          yourSelect: 'Your selection',
          similar: 'Similar gas vehicle',
          chartSummary:
            'Based on your selections, your electric fleet will cost {value} less per year than its gasoline equivalent.',
          electricity: 'Electricity',
          gas: 'Gasoline',
          set: 'Vehicle Set',
          count: 'Count',
          efficiency: 'Efficiency',
          miles: 'Miles',
          fuelCost: 'Fuel Cost',
          cost: 'Cost',
          annualTotal: 'Potential Annual Total',
          lifetime: 'Potential Vehicle {time} Years Total',
          fossilFleet: 'Fossil Fuel Vehicle Fleet',
          electricFleet: 'Electric Vehicle Fleet',
          annualCosts: 'Potential Annual Fuel Costs',
          gasPriceSource:
            'Based on current average fuel costs for your state. Fuel prices are updated every 2 business days to the most recent available from AAA. Prices are updated in the fleets tool live with the AAA update. Source: ',
          electricPriceSource:
            'Electricity cost is updated annually to the most recent available from US EIA. Cost is updated in the fleets tool live with the US EIA update. ',
          usEnergy: 'US Energy Information Administration',
          aaa: 'AAA',
          diesel: 'Diesel',
          reg: 'Regular',
          commercial: 'Commercial Rate',
          year: 'Year',
          fossilFleetCost: 'Fossil Fuel Fleet Fuel Cost',
          electricFleetCost: 'Electric Fleet Fuel Cost',
          total: 'Total',
          savings: 'Savings',
          totalCosts: 'Total Costs',
          costPerMile: 'Fuel Cost per Mile',
          chartFooterA: 'Electricity Price Source:',
          chartFooterB: 'Gasoline Price Source:',
          table: {
            electricMiles: '(kWh/mile)',
            electricDollars: '($/kWh)',
            gasUnits: '(MPG)',
            gasDollars: '($/Gal)',
            toolTip:
              'Potential annual miles are calculated based on the user input number of fleet vehicles, number of business and personal miles, whether the company pays for them, the number of days operating in a week, and the number of months operating in a year in the fleet setup screens. This amount is calculated using the following equation: ' +
              'annual miles = (business miles + personal miles) x (number of days / 7) x (number of months / 12) x 365.25 x number of vehicles',
          },
        },
        comparison: {
          header: 'Vehicle Comparison',
          subheader:
            'Select vehicle set to see a side by side comparison of fossil fuel and electric equivalent vehicles.',
          iceDisclaimer:
            'EPA estimated mpg and range. Actual range will vary based on several factors, including temperature, terrain, loading, use and maintenance.',
          evDisclaimer:
            'On a full charge. EPA estimated. Actual range will vary based on several factors, including ambient temperature, terrain, battery age and condition, loading, and how you use and maintain your vehicle.',
          dropdownLabel: 'Vehicle Set',
          gasoline: 'Gasoline',
          batteryElectric: 'Battery Electric',
          efficiency: 'Efficiency',
          disclaimer:
            'On a full charge. EPA estimated. Actual range will vary based on several factors, including ambient temperature, terrain, battery age and condition, loading, and how you use and maintain your vehicle.',
          fuel: {
            header: 'Fuel Cost',
            annualFossilCost: 'Potential Annual Fossil Fuel Cost',
            annualElectricCost: 'Potential Annual Electric Cost',
            fossilPerMileCost: 'Potential Fossil Fuel Cost Per Mile',
            electricPerMileCost: 'Potential Electricity Cost Per Mile',
            milesPerYear: 'Total Potential Miles Per Year',
            fossilAssumptions: 'Fossil Fuel Cost Assumptions',
            electricAssumptions: 'Electricity Cost Assumptions',
            avgFossilFuelCost:
              'Assumes average cost of {fuel_type} for {state_zip}. Based on current average fuel costs for your state. Fuel prices are updated every 2 business days to the most recent available from AAA. Prices are updated in the fleets tool live with the AAA update. SRC:{link}',
            avgElectricityCost:
              'Assumes average cost of electricity for {state_zip}. Electricity cost is updated annually to the most recent available from US EIA. Cost is updated in the fleets tool live with the US EIA update. SRC:{link}',
            avgFossilFuelCostNational: 'Federal average cost of gasoline',
            avgElectricityCostNational: 'Federal average cost of electricity',
            mpg: '{value} MPG',
            mgpe: '{value} MPGe',
            milesPerYearDisclaimer:
              'Potential annual miles are calculated based on the user input number of fleet vehicles, number of business and personal miles, whether the company pays for them, the number of days operating in a week, and the number of months operating in a year in the fleet setup screens. This amount is calculated using the following equation: {BREAK} annual miles = (business miles + personal miles) x (number of days / 7) x (number of months / 12) x 365.25 x number of vehicles',
            milesPerYearDisclaimerElectric:
              'Potential annual miles are calculated based on the user input number of fleet vehicles, number of business and personal miles, whether the company pays for them, the number of days operating in a week, and the number of months operating in a year in the fleet setup screens. This amount is calculated using the following equation: {BREAK} annual miles = (business miles + personal miles) x (number of days / 7) x (number of months / 12) x 365.25 x number of vehicles',
          },
          incentives: {
            header: 'Incentives',
            capitalIncentives: 'Capital Incentives',
            ongoingPerMile: 'Ongoing Incentives per mile',
          },
          specs: {
            header: 'Specs',
            cargo: 'Cargo Capacity',
            seating: 'Seating',
            headroom: 'Headroom',
            legroom: 'Legroom',
          },
        },
        incentives: {
          noIncentives: "Sorry, we couldn't find any incentives for your location.",
          multipleSitesAlert:
            'Incentives are not analyzed when purchasing for multiple sites. To see incentives, uncheck "purchasing for multiple sites" in the Assumptions Inputs and enter a zipcode.',
          status: 'Status / {status}',
          eligible: {
            header: 'EV Incentives',
            subHeader: 'This project may potentially be eligible for the following incentives.',
            disclaimer:
              'The above potential federal, state and local incentives are estimates provided by ZappyRide on a monthly basis and provided for example purposes only. These incentive projections are based on certain assumptions and not all consumers will qualify for the maximum incentive or at all. Potential federal, state and local incentives may be limited to the first year of ownership and are not over the life of the vehicle. Please review the language of each individual incentive to determine eligibility and availability and consult with your legal counsel. Potential federal, state and local incentives are subject to change at any time. GM does not warrant or guarantee the availability of any potential incentives. Please check with your legal counsel for the most up to date information on availability and eligibility of potential incentives.',
          },
          ineligible: {
            header: 'Ineligible Incentives',
            subheader: 'Your project is ineligible for the incentives below.',
          },
          details: 'Details',
          eligibilityAnalysis: 'Project Eligibility Analysis',
          fundingBreakdown: 'Funding Breakdown',
          closingDate: 'Closing Date',
          typicalAmount: 'Typical Amount',
          organizations: 'Organizations',
          totalAvailable: 'Total Available',
          fundingToDate: 'Funding To Date',
          applicability: 'Applicability',
          summarizedBy: 'summarized by',
          applyNow: 'Apply Now',
          learnMore: 'Learn More',
          na: 'N/A',
          all: 'All',
        },
        charging: {
          needs: 'Charging and Facility needs',
          total: 'Total charger cost is ',
          vehicles: '{value} Vehicles',
          infoCard: {
            warning: "Doesn't meet kW output required",
            success: 'Meets KW output required',
            connectivity: 'Connectivity',
            estimated: 'Estimated Cost',
            type: 'Plug Type(s)',
            cable: 'Cable Length',
            cableFt: '{value} ft',
            ports: 'Ports',
            form: 'Form Factor',
            outlet: 'Outlet(s)',
            ocpp: 'OCPP',
            oscp: 'OSCP',
            vehicleGrid: 'Vehicle to Grid',
            warranty: 'Warranty',
            warrantyYrs: '{value} years',
            certs: 'Certifications',
            generic: 'Generic',
            dualPort: 'Dual Port',
            change: 'Change',
          },
          chargerType: 'Charger Type',
          vehicle: 'Vehicle',
          time: 'Time Range',
          singlePort: 'Single Port',
          dualPort: 'Dual Port',
          minimumReq: 'Minimum req: {value}',
          overTime: 'Charging over time',
          peak: 'Peak Mileage',
          typicalWorkday: 'Typical Working Day',
          average: 'Average Mileage',
          dailyCharge: 'Daily Battery Charge',
          selectSet: 'Select Vehicle Set',
          ev: 'EV: {vehicleName}',
          replaces: 'Replaces: {vehicleName}',
          chargeState: 'Battery Charge State',
          note: 'Note: The graph assumes daily mileage is averaged across all hours of the day when the vehicle is not available to charge. Actual mileage and battery state will differ.',
          changeWindow: 'Consider changing your charging window.',
          chargeWarning:
            'Over the course of a day your vehicle will hit a minimum charge of {charge}.',
        },
        emissions: {
          summary: {
            co2SavedPerYear: 'of CO₂ potentially saved per year',
            co2SavedLifetime: 'of CO₂ potentially saved over vehicle lifetime',
            treesPlantedPerYear: 'trees planted per year',
            treesPlantedLifetime: 'trees planted over vehicle lifetime',
          },
          table: {
            item: 'Item',
            diesel: 'Diesel',
            gasoline: 'Gasoline',
            lpg: 'LPG',
            annualMiles: 'Annual Miles',
            fossilFuelVolume: 'Fossil Fuel Volume',
            fossilFuelEmissions: 'Fossil Fuel CO₂ Emissions',
            co2EmissionsByFuel: 'CO₂ Emissions by Fossil Fuel',
            totalCo2Emissions: 'Total CO₂ Emissions from Fossil Fuels',
            electricityEquivalent: 'Electricity Equivalent',
            electricityEmissions: 'Electricity Emissions',
            co2EmissionsFromCharging: 'Total CO₂ Emissions from EV charging',
            co2EmissionsReduced: 'CO₂ Emissions Reduced',
            treesPlanted: 'Trees Planted',
            units: {
              miles: '{value} miles',
              gallons: '{value} gal',
              co2PerGallon: '{value} lbs CO₂ / gal',
              lbs: '{value} lbs',
              tons: '{value} tons',
              lbsCo2: '{value} lbs CO₂',
              mwh: '{value} MWh',
              lbsPerMwh: '{value} lbs CO₂/MWh',
              trees: '{value} trees',
            },
            notes: {
              title: 'Notes',
              annualMiles: 'The total number of miles driven annually by your fleet.',
              fossilFuelVolume:
                "For each vehicle set, we applied each vehicle's mpg to the annual miles driven",
              fossilFuelEmissions: 'Source: {link}',
              fossilFuelEmissionsLink: 'U.S. Energy Information Administration',
              co2EmissionsByFuel: '"Fossil Fuel Volume" * "CO₂ Emissions In Lbs/Gal"',
              totalCo2Emissions: '"Diesel CO₂ Emissions" + "Gasoline CO₂ Emissions"',
              electricityEquivalent:
                'The amount of electricity required to replace the selected fleet of fossil fuel vehicles one-to-one with electric vehicles.',
              electricityEmissions:
                'The amount of CO₂ emitted per MWh of electricity generated, based on your zip code.',
              co2EmissionsFromCharging: '"Electricity Equivalent" * "Electricity Emissions"',
              co2EmissionsReduced:
                '"Total CO₂ Emissions from Fossil Fuels" - "Total CO₂ Emissions from EV Charging"',
              treesPlanted: 'A tree absorbs 48 pounds of CO₂ per year',
            },
          },
        },
      },
      organizationTypes: {
        car_sharing_companies: 'Car Sharing Companies',
        federal_government_entities: 'Federal Government Entities',
        local_government_entities: 'Local Government Entities',
        logistics_company: 'Logistics Company',
        medium_or_large_businesses: 'Medium Or Large Businesses',
        native_tribes: 'Native Tribes',
        nonprofits: 'Nonprofits',
        private_school_districts: 'Private School Districts',
        public_school_districts: 'Public School Districts',
        rental_companies: 'Rental Companies',
        small_businesses: 'Small Businesses',
        state_government_entities: 'State Government Entities',
        transit_agencies: 'Transit Agencies',
        universities: 'Universities',
        utility: 'Utility',
        other: 'Other',
      },
      chargerTypes: {
        level_2: 'Level 2',
        level_3: 'Level 3',
        dc_fast_charging: 'DCFC',
        unknown: 'Unknown',
      },
      timePeriods: {
        days: {
          weekdays: {
            long: 'Weekdays',
            abbreviated: 'Weekdays',
          },
          weekends: {
            long: 'Weekends',
            abbreviated: 'Weekends',
          },
          0: {
            long: 'Sunday',
            abbreviated: 'Sun',
          },
          1: {
            long: 'Monday',
            abbreviated: 'Mon',
          },
          2: {
            long: 'Tuesday',
            abbreviated: 'Tue',
          },
          3: {
            long: 'Wednesday',
            abbreviated: 'Wed',
          },
          4: {
            long: 'Thursday',
            abbreviated: 'Thu',
          },
          5: {
            long: 'Friday',
            abbreviated: 'Fri',
          },
          6: {
            long: 'Saturday',
            abbreviated: 'Sat',
          },
        },
        months: {
          0: {
            long: 'January',
            abbreviated: 'Jan',
          },
          1: {
            long: 'February',
            abbreviated: 'Feb',
          },
          2: {
            long: 'March',
            abbreviated: 'Mar',
          },
          3: {
            long: 'April',
            abbreviated: 'Apr',
          },
          4: {
            long: 'May',
            abbreviated: 'May',
          },
          5: {
            long: 'June',
            abbreviated: 'Jun',
          },
          6: {
            long: 'July',
            abbreviated: 'Jul',
          },
          7: {
            long: 'August',
            abbreviated: 'Aug',
          },
          8: {
            long: 'September',
            abbreviated: 'Sep',
          },
          9: {
            long: 'October',
            abbreviated: 'Oct',
          },
          10: {
            long: 'November',
            abbreviated: 'Nov',
          },
          11: {
            long: 'December',
            abbreviated: 'Dec',
          },
        },
        years: '{years} years',
        workdays: {
          weekdays: 'Weekdays',
          weekends: 'Week',
        },
        labels: {
          month: 'Month',
          day: 'Day',
          hour: 'Hour',
        },
      },
      chargingWindows: {
        chargingTimes: {
          ...times(24, (i) => moment(i, 'H').format('h a')),
        },
        times: {
          start: 'Start Time',
          end: 'End Time',
          type: 'Charger Type',
          add: 'Add Charging Window',
          output: 'Type/Output',
        },
        milesPerDay: '{value} miles per day',
        recommendation:
          'A charger has been recommended assuming a {timeRange} charging window for your {vehicleName} vehicles driving {milesPerDay} that require charging {chargeAmount} in {numHours}. With this configuration, the minimum state of charge will be {minStateOfCharge}.',
      },
      findYourRep: 'Find Your GM Fleet Rep',
      externalSiteDialog: {
        title: 'Leaving GM',
        message:
          'Are you sure you want to navigate away from GMs website? GM holds no liability for content outside its website.',
        cancel: 'Cancel',
        continue: 'Continue',
      },
      incentive: {
        button: 'Incentive Disclaimer',
        dialogTitle: 'ZappyRide Disclaimer',
        disclaimer:
          'Potential government incentives are estimates provided by ZappyRide on a monthly basis and provided for example purposes only. These incentive projections are based on certain assumptions and not all consumers will qualify for the maximum incentive or at all. Government incentives may be limited to the first year of ownership and are not over the life of the vehicle. Please review the language of each individual incentive to determine eligibility and availability and consult with your tax professional. Governmental incentives are subject to change at any time. GM does not warrant or guarantee the availability of any potential incentives. Please check with your tax professional for the most up to date information on availability and eligibility of potential incentives.',
      },
      errorBoundary: {
        header: 'Please reset your inputs',
        body: 'You have either modified a valid url, or we have updated our vehicle data as part of our effort to stay up-to-date with a fast changing industry. Please reset your inputs using the button below to resolve this issue.',
      },
    },
  },
};

export default translations;
