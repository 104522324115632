import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material';

export default function StyledTable({
  headings,
  rows,
  headerColor,
  firstColumnColor,
  firstCellColor,
  borderColor,
  bottomRowColor,
}) {
  const theme = useTheme();

  const cellBackground = (col, row) => {
    if (col === 0 && firstColumnColor) {
      return firstColumnColor;
    }
    if (row === rows.length - 1 && bottomRowColor) {
      return bottomRowColor;
    }
    return 'transparent';
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ height: '42px' }}>
            {headings.map((heading) => (
              <TableCell
                key={heading}
                component={heading ? 'th' : 'tr'}
                sx={{
                  padding: '10px',
                  border: `1px solid ${borderColor ?? theme.palette.grey[500]}`,
                  fontFamily: 'Overpass, sans-serif',
                  color: headerColor ? 'white' : theme.palette.grey[900],
                  backgroundColor: headerColor ?? 'transparent',
                }}
              >
                {heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, iRow) => (
            <TableRow key={iRow} sx={{ height: '42px' }}>
              {row.map((col, iCol) => (
                <TableCell
                  key={`${iRow}-${iCol}`}
                  align={iCol === 0 ? 'left' : 'right'}
                  sx={{
                    padding: '10px',
                    border: `1px solid ${borderColor ?? theme.palette.grey[500]}`,
                    backgroundColor: cellBackground(iCol, iRow),
                    color:
                      iRow !== rows.length - 1 && iCol === 0
                        ? firstCellColor
                        : theme.palette.grey[900],
                    fontFamily:
                      iRow === rows.length - 1 ? 'Overpass, sans-serif' : 'Overpass, sans-serif',
                  }}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
