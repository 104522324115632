import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Box, Typography, useTheme } from '@mui/material';
import { orderBy } from 'lodash';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import DropdownInput from '../../../components/DropdownInput';
import TextFieldInput from '../../../components/TextFieldInput';
import {
  VEHICLE_TYPES,
  VEHICLE_SUBTYPES,
  FUEL_TYPES,
  VEHICLE_MODELS,
} from '../../../data/VEHICLES';
import StatsCard from './StatsCard';

export default function SelectVehicleStep({ vehicleSet, onChangeVehicleSet }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { apiElectricVehicles } = useInputs();
  const {
    name,
    vehicleCount,
    replacementVehicle,
    existingVehicle,
    customFossilFuelVehicle,
    overrideSpecs,
  } = vehicleSet;
  const overrides = overrideSpecs || {};

  const [isShowingCustomOptions, setIsShowingCustomOptions] = useState(true);
  const [error, setError] = useState(false);
  const [specs] = useState(overrides);
  const handleError = (value) => {
    let mpgCheck =
      value?.milesPerGallon && value?.milesPerGallon <= 100 && value?.milesPerGallon >= 1;

    let msrpCheck = value?.msrp && value?.msrp <= 500000 && value?.msrp >= 10000;

    if (value.hasOwnProperty('milesPerGallon') && !mpgCheck) {
      return setError({
        type: 'mpg',
        msg: 'An MPG figure that is an integer between 1 and 100 must be specified',
      });
    } else {
      setError(false);
    }

    if (value.hasOwnProperty('msrp') && !msrpCheck) {
      return setError({
        type: 'msrp',
        msg: 'An MSRP figure that is an integer between $10,000 and $500,000 must be specified',
      });
    } else {
      setError(false);
    }
  };

  // const handleCustomVehicleOption = (value) => {
  //   handleError(value);
  //   console.log(value);
  //   onChangeVehicleSet({ customFossilFuelVehicle: { ...customFossilFuelVehicle, ...value } });
  //   console.log({...customFossilFuelVehicle, ...value});
  // };
  /* eslint-disable react-hooks/exhaustive-deps */
  const handleCustomVehicleOption = useCallback(
    (value) => {
      handleError(value);
      // console.log(customFossilFuelVehicle);
      onChangeVehicleSet({ customFossilFuelVehicle: { ...customFossilFuelVehicle, ...value } });
      // console.log({...customFossilFuelVehicle, ...value});
    },
    [customFossilFuelVehicle],
  );

  const handleVehicleChange = (existingId) => {
    let target;
    apiElectricVehicles.forEach((ev) => {
      if (ev.id === existingId) {
        target = ev;
      }
    });
    onChangeVehicleSet({ replacementVehicle: target });
  };

  const handleCustomVehicleToggle = (value) => {
    setIsShowingCustomOptions(value);
    onChangeVehicleSet({ hasUserSpecifiedCustomVehicle: value });
  };

  const generateYears = () => {
    let year = new Date();
    year = year.getFullYear();

    let years = [];

    for (let i = 0; i < 10; i++) {
      years.push({ value: year - i, children: year - i });
    }

    return years;
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  const handleSaveSpecs = useCallback(() => {
    onChangeVehicleSet({ overrideSpecs: { ...specs } });
  }, [specs]);
  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    handleSaveSpecs();
  }, [handleSaveSpecs]);

  const vehicleOptions = apiElectricVehicles.map(({ id, name }) => ({ value: id, children: name }));

  const filteredFuelTypes = FUEL_TYPES.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.value === value.value && t.children === value.children),
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let hasSubtype = false;

    VEHICLE_SUBTYPES[customFossilFuelVehicle.type].forEach(({ value }) => {
      if (value === customFossilFuelVehicle.subtype) hasSubtype = true;
    });

    if (!hasSubtype) {
      return handleCustomVehicleOption({
        subtype: VEHICLE_SUBTYPES[customFossilFuelVehicle.type][0].value,
      });
    }

    const selectedVehicleModel =
      VEHICLE_MODELS[customFossilFuelVehicle.type][customFossilFuelVehicle.subtype];

    // console.log("test",customFossilFuelVehicle);
    const changeParams = {
      fossilFuelType: selectedVehicleModel.fossilFuelType,
      gvwr: selectedVehicleModel.gvwr.toFixed(0),
      // year: selectedVehicleModel.year,
      imageUrl: selectedVehicleModel.imageUrl,
      name:
        selectedVehicleModel.name +
        ' ' +
        selectedVehicleModel.type +
        ' ' +
        selectedVehicleModel.subtype,
    };

    return handleCustomVehicleOption(changeParams);
  }, [customFossilFuelVehicle.subtype, customFossilFuelVehicle.type, customFossilFuelVehicle.year]);
  /* eslint-disable react-hooks/exhaustive-deps */

  const switchTitle = formatMessage({ id: 'onboarding.selectVehicle.switchTitle' });
  const switchButton1 = formatMessage({ id: 'onboarding.selectVehicle.switchButton1' });
  const switchButton2 = formatMessage({ id: 'onboarding.selectVehicle.switchButton2' });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleCustomVehicleToggle(true);
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  const filteredVehicleTypes = VEHICLE_TYPES.filter(
    (item) => item.value === 'Passenger' || item.value === 'Pick-up Truck',
  );

  return (
    <Grid container px={2} spacing={6} flexDirection="column">
      <Grid item container xs={12} spacing={2} flexDirection="row">
        <Grid item md={6} xs={12}>
          <TextFieldInput
            value={name}
            label={formatMessage({ id: 'onboarding.selectVehicle.name' })}
            onBlur={(e) => onChangeVehicleSet({ name: e.target.value })}
            mb={0}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextFieldInput
            value={vehicleCount}
            label={formatMessage({ id: 'onboarding.selectVehicle.numberVehicles' })}
            onBlur={(e) => onChangeVehicleSet({ vehicleCount: parseInt(e.target.value) })}
            type="number"
            sx={(theme) => ({ mb: 0, [theme.breakpoints.up('sm')]: { mb: 0 } })}
          />
        </Grid>
      </Grid>

      <Grid item md={12} xs={12}>
        <StatsCard
          vehicleSet={vehicleSet}
          onChangeVehicleSet={onChangeVehicleSet}
          replacementVehicle={replacementVehicle}
          selectedVehicle={existingVehicle}
        />
      </Grid>

      <Grid item xs={12} spacing={2} flexDirection="row">
        <Typography fontSize="14px" color={theme.palette.black}>
          {switchTitle}
        </Typography>
        <Grid item xs={12} flexDirection="row">
          <Box
            p="8px"
            width="100%"
            border={`1px solid ${theme.palette.grey[300]}`}
            flexDirection="row"
            display="flex"
            gap={2}
          >
            <Grid item sm={6} xs={12}>
              <Box
                p="4px"
                width="100%"
                height="63px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color={isShowingCustomOptions ? 'white' : '#007DBE'}
                backgroundColor={isShowingCustomOptions ? '#007DBE' : 'transparent'}
                onClick={() => setIsShowingCustomOptions(true)}
                style={{ cursor: 'pointer' }}
              >
                {switchButton1}
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box
                p="4px"
                width="100%"
                height="63px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color={isShowingCustomOptions ? '#007DBE' : 'white'}
                backgroundColor={isShowingCustomOptions ? 'transparent' : '#007DBE'}
                onClick={() => setIsShowingCustomOptions(false)}
                style={{ cursor: 'pointer' }}
              >
                {switchButton2}
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {!isShowingCustomOptions && (
        <Grid item xs={12} spacing={2} flexDirection="row">
          <Grid item md={6} xs={12}>
            <DropdownInput
              id="vehicle-subtype-input"
              label={formatMessage({
                id: 'onboarding.selectVehicle.customFossilFuelVehicle.replacement',
              })}
              options={orderBy(vehicleOptions, [(types) => types.value], ['asc'])}
              value={replacementVehicle.id}
              onChange={(e) => handleVehicleChange(e.target.value)}
            />
          </Grid>
        </Grid>
      )}

      {isShowingCustomOptions && (
        <Grid item xs={12} spacing={2} flexDirection="row">
          <Grid container item xs={12} spacing={2} flexDirection="row">
            <Grid item md={6} xs={12}>
              <DropdownInput
                id="vehicle-type-input"
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.type',
                })}
                options={orderBy(filteredVehicleTypes, [(types) => types.value], ['asc'])}
                value={customFossilFuelVehicle.type}
                onChange={(e) => handleCustomVehicleOption({ type: e.target.value })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DropdownInput
                id="vehicle-subtype-input"
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.subtype',
                })}
                options={orderBy(
                  VEHICLE_SUBTYPES[customFossilFuelVehicle.type],
                  [(types) => types.value],
                  ['asc'],
                )}
                value={
                  customFossilFuelVehicle.subtype ||
                  VEHICLE_SUBTYPES[customFossilFuelVehicle.type][0].value
                }
                onChange={(e) => handleCustomVehicleOption({ subtype: e.target.value })}
              />
            </Grid>
          </Grid>

          <Box my="24px" width="100%" borderTop={`1px solid ${theme.palette.grey[300]}`} />

          <Grid container item xs={12} spacing={2} flexDirection="row">
            <Grid item md={6} xs={12}>
              <DropdownInput
                id="vehicle-year-input"
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.year',
                })}
                options={generateYears()}
                value={existingVehicle.year || customFossilFuelVehicle.year || '2023'}
                onLoad={(e) => {
                  handleCustomVehicleOption({ year: e });
                }}
                onChange={(e) => handleCustomVehicleOption({ year: e.target.value })}
                mb={4}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextFieldInput
                value={existingVehicle.make || customFossilFuelVehicle.make || ' '}
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.make',
                })}
                onBlur={(e) => handleCustomVehicleOption({ make: e.target.value })}
                type="text"
                mb={4}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2} flexDirection="row">
            <Grid item md={6} xs={12}>
              <TextFieldInput
                value={existingVehicle.model || customFossilFuelVehicle.model || ' '}
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.model',
                })}
                onBlur={(e) => handleCustomVehicleOption({ model: e.target.value })}
                type="text"
                mb={4}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DropdownInput
                id="vehicle-subtype-input"
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.fuelType',
                })}
                options={filteredFuelTypes}
                value={customFossilFuelVehicle.fossilFuelType}
                onChange={(e) => handleCustomVehicleOption({ fossilFuelType: e.target.value })}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2} flexDirection="row">
            <Grid item md={6} xs={12}>
              <TextFieldInput
                value={
                  existingVehicle.milesPerGallon || customFossilFuelVehicle.milesPerGallon || ' '
                }
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.mpg',
                })}
                onBlur={(e) => handleCustomVehicleOption({ milesPerGallon: e.target.value })}
                type="number"
                mb={4}
                error={error.type === 'mpg' ? error.msg : false}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextFieldInput
                value={existingVehicle.rangeInMiles || customFossilFuelVehicle.rangeInMiles || ' '}
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.range',
                })}
                onBlur={(e) => handleCustomVehicleOption({ rangeInMiles: e.target.value })}
                type="number"
                mb={4}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2} flexDirection="row">
            <Grid item md={6} xs={12}>
              <TextFieldInput
                value={existingVehicle.msrp || customFossilFuelVehicle.msrp || ' '}
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.msrp',
                })}
                onBlur={(e) => handleCustomVehicleOption({ msrp: e.target.value })}
                type="number"
                mb={4}
                isThousand
                error={error.type === 'msrp' ? error.msg : false}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
