import React from 'react';
import EditVehicleSetDialog from './EditVehicleSetDialog';
import { useInputs } from '@bellawatt/use-inputs';
import { cloneDeep } from 'lodash';
import { omitComputedData } from '../../utils/computedData';

export default function EditVehicleSet({ isOpen, onClose, vehicleSet, isEditing }) {
  const { vehicleSets, setInput } = useInputs();

  const handleDelete = (setId) => {
    const index = vehicleSets.findIndex((set) => set.id === setId);
    const newSets = cloneDeep(vehicleSets);

    newSets.splice(index, 1);
    setInput({ vehicleSets: omitComputedData(newSets) });
    onClose();
  };

  const handleSaveAndClose = (vehicleData) => {
    const index = vehicleSets.findIndex((set) => set.id === vehicleData.id);
    const newSets = cloneDeep(vehicleSets);
    newSets[index] = vehicleData;
    //console.log('newSets', newSets);
    setInput({ vehicleSets: omitComputedData(newSets) });
    onClose();
  };

  return (
    <EditVehicleSetDialog
      isOpen={isOpen}
      onClose={() => onClose()}
      onSave={handleSaveAndClose}
      onDelete={handleDelete}
      canRemove={vehicleSets.length > 1}
      vehicleSet={vehicleSet}
      isEditing={isEditing}
    />
  );
}
