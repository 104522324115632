import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import ToolTip from './ToolTip';

/* eslint-disable react-hooks/exhaustive-deps */
export default function DropdownInput({
  id,
  label,
  tooltip,
  value,
  onChange,
  onLoad,
  options,
  ...rest
}) {
  useEffect(() => {
    if (onLoad) onLoad(value);
  }, []);
  return (
    <Box {...rest}>
      <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box flexGrow={1}>
          <InputLabel
            id={id}
            sx={{ backgroundColor: '#FFF', paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
          >
            {label}
          </InputLabel>
          <Select labelId={id} value={value} onChange={onChange} fullWidth>
            {options.map(({ value: val, children }) => (
              <MenuItem key={val} value={val}>
                {children}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {tooltip && <ToolTip content={tooltip} />}
      </FormControl>
    </Box>
  );
}
