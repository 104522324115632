import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import Header from '../Header';
import Footer from '../Footer';
import ResetInputs from '../../inputs/ResetInputs';

function ErrorFallback({ error }) {
  const { formatMessage } = useIntl();

  // TODO: Send error to reporting tool
  // reportError(error)

  return (
    <Box width="100%" minHeight="100vh" display="flex" flexDirection="column">
      <Header />
      <Box flex="1 1" textAlign="center" maxWidth="800px" mx="auto" p="40px">
        <div role="alert">
          <h1>{formatMessage({ id: 'errorBoundary.header' })}</h1>
          <p>{formatMessage({ id: 'errorBoundary.body' })}</p>
          <Box mt="40px">
            <ResetInputs />
          </Box>
        </div>
      </Box>
      <Footer hideRebates />
    </Box>
  );
}

export default function ErrorBoundary({ fallback = ErrorFallback, children }) {
  return <ReactErrorBoundary FallbackComponent={fallback}>{children}</ReactErrorBoundary>;
}
