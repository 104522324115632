import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';
import { StarIcon } from '../../assets/icons/icons';
import ButtonInput from '../../components/ButtonInput';
import vehicleMappings from '../../data/vehicleMappings';
import { formatAsDollars } from '../../utils/formatters';

const formatOperatingDays = ([first, ...rest]) => {
  const daysOfWeek = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'];
  // workdays are stored with 0 === Sunday, but we want to display Monday first
  const reorderedOperatingDays = first === 0 ? [...rest, first] : [first, ...rest];
  return reorderedOperatingDays.map((day) => daysOfWeek[day]).join(',');
};

const Spec = ({ spec }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <Box display="flex" justifyContent="space-between" py="3px">
      <Typography
        fontFamily={theme.typography.fontFamily}
        fontSize="14px"
        color={theme.palette.grey[600]}
      >
        {formatMessage({ id: spec.name })}
      </Typography>
      <Typography
        fontFamily={theme.typography.fontFamily}
        fontSize="14px"
        color={theme.palette.grey[900]}
      >
        {spec.value}
      </Typography>
    </Box>
  );
};

const Title = ({ count, name }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p="10px"
      m="6px"
      backgroundColor={theme.palette.grey[100]}
    >
      <Typography
        fontFamily={theme.typography.fontFamily}
        fontSize="14px"
        color={theme.palette.grey[900]}
      >
        {count}x
      </Typography>
      <Typography
        fontFamily={theme.typography.fontFamily}
        fontSize="14px"
        color={theme.palette.grey[900]}
      >
        {name}
      </Typography>
    </Box>
  );
};

export function SimpleVehicleCard({ vehicleSet }) {
  const theme = useTheme();
  const { replacementVehicle, vehicleCount, milesPerWorkday, workdays } = vehicleSet;
  const { type, subtype, name } = replacementVehicle;

  const specList = [
    {
      name: 'inputs.vehicleSet.vehicleCatalog.type',
      value: type,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.subtype',
      value: subtype,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.milesPerDay',
      value: milesPerWorkday,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.daysOperating',
      value: formatOperatingDays(workdays),
    },
  ];

  return (
    <Box border={`1px solid ${theme.palette.grey[300]}`}>
      <Title count={vehicleCount} name={name} />
      <Box px="10px">
        {specList.map((spec) => (
          <Spec spec={spec} key={spec.name} />
        ))}
      </Box>
    </Box>
  );
}

export function ComplexVehicleCard({ vehicleSet, replacementOption, selectedVehicle, onSelect }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { replacementVehicle, vehicleCount, milesPerWorkday, existingVehicleId } = vehicleSet;
  const { name } = replacementVehicle;
  const {
    make,
    model,
    type,
    subtype,
    imageUrl,
    name: replacementName,
    range,
    msrp,
    headroom,
    legroom,
    seats,
    cargo,
  } = replacementOption;

  const isRecommended = vehicleMappings[existingVehicleId].ev_id === replacementOption.id;

  const specList = [
    {
      name: 'inputs.vehicleSet.vehicleCatalog.type',
      value: type,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.subtype',
      value: subtype,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.range',
      value: range,
    },
    //     {
    //       name: 'inputs.vehicleSet.vehicleCatalog.capacity.battery',
    //       value: batteryCapacityInKwh,
    //     },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.capacity.cargo',
      value: !isNaN(cargo) && cargo !== 0 ? `${cargo} ft³` : '--',
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.capacity.passenger',
      value: !isNaN(seats) && seats !== 0 ? seats : '--',
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.headroom',
      value: headroom !== 0 ? `${headroom} in` : '--',
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.legroom',
      value: legroom !== 0 ? `${legroom} in` : '--',
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.availability',
      value: 0,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.milesDay',
      value: milesPerWorkday,
    },
  ];

  const cardBorder =
    selectedVehicle.id === replacementOption.id
      ? `2px solid ${theme.palette.primary.main}`
      : `1px solid ${theme.palette.grey[300]}`;

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Box
      width="255px"
      border={cardBorder}
      borderRadius="4px"
      boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
      sx={(theme) => ({ height: 'fit-content', [theme.breakpoints.up('sm')]: { height: '610px' } })}
    >
      <Title count={vehicleCount} name={name} />
      <Box px={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography fontFamily={theme.typography.fontFamily} fontSize="16px">
            {make}
          </Typography>
          {isRecommended && <StarIcon color={theme.palette.common.green[700]} />}
        </Box>
        <Typography>{model}</Typography>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src={imageUrl} alt={replacementName} width="auto" height="124px" />
        </Box>
        {isLargeScreen && (
          <Box borderBottom={`1px solid ${theme.palette.grey[200]}`} pb="10px">
            {specList.map((spec) => (
              <Spec key={spec.name} spec={spec} />
            ))}
          </Box>
        )}

        <Box display="flex" justifyContent="space-between" py="10px" alignItems="center">
          <Typography
            fontFamily={theme.typography.fontFamily}
            fontSize="16px"
            color={theme.palette.grey[600]}
          >
            {formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.msrp' })}
          </Typography>
          <Typography
            fontFamily={theme.typography.fontFamily}
            fontSize="20px"
            color={theme.palette.grey[900]}
          >
            {formatAsDollars(msrp)}
          </Typography>
        </Box>
        <Box textAlign="center" p="6px">
          <ButtonInput
            variant={selectedVehicle.id === replacementOption.id ? 'contained' : 'outlined'}
            onClick={onSelect}
            textTransform="none"
          >
            {formatMessage({
              id: `inputs.vehicleSet.vehicleCatalog.${
                selectedVehicle.id === replacementOption.id ? 'selected' : 'selectReplacement'
              }`,
            })}
          </ButtonInput>
        </Box>
      </Box>
    </Box>
  );
}
