import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import without from 'lodash/without';
import CheckboxButtonInput from './CheckboxButtonInput';

export default function CheckboxButtonRow({ onChange, selectionArray = [], data, error }) {
  const { formatMessage } = useIntl();

  const toggleButton = (itemIndex) => {
    if (selectionArray.includes(itemIndex)) {
      onChange(without(selectionArray, itemIndex));
    } else {
      onChange([...selectionArray, itemIndex]);
    }
  };

  return (
    <Box>
      <Grid container rowSpacing={2}>
        {data
          .sort((a, b) => a.position - b.position)
          .map(({ itemIndex, translationKey }) => (
            <Grid key={itemIndex} item>
              <CheckboxButtonInput
                checked={selectionArray.includes(itemIndex)}
                onClick={() => toggleButton(itemIndex)}
              >
                {formatMessage({ id: translationKey })}
              </CheckboxButtonInput>
            </Grid>
          ))}
      </Grid>
      {error && (
        <Box
          sx={(theme) => ({
            color: theme.palette.common.error,
            fontSize: '14px',
            marginTop: '10px',
            paddingLeft: '12px',
          })}
        >
          {error}
        </Box>
      )}
    </Box>
  );
}
