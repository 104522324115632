import React from 'react';
import { Box, useTheme } from '@mui/material';
import StyledBarGraph from '../../components/charts/StyledBarGraph';
import { formatAsDollars } from '../../utils/formatters';
import { useIntl } from 'react-intl';

export default function CostsBarChart({ fossilFleetCosts, evFleetCosts }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const labels = [
    formatMessage({ id: 'panels.financials.fuel' }),
    formatMessage({ id: 'panels.financials.vehicles' }),
  ];

  const evData = [evFleetCosts.byCategory.fuel, evFleetCosts.byCategory.purchase];

  const fossilData = [fossilFleetCosts.byCategory.fuel, fossilFleetCosts.byCategory.purchase];

  const chargeData = {
    labels,
    datasets: [
      {
        label: formatMessage({ id: 'panels.financials.electricVeh' }),
        fill: false,
        data: evData,
        backgroundColor: theme.palette.primary.main,
      },
      {
        label: formatMessage({ id: 'panels.financials.fossilVeh' }),
        fill: false,
        data: fossilData,
        backgroundColor: theme.palette.grey[900],
      },
    ],
  };

  const xTicks = {
    min: 0,
    padding: 20,
    color: theme.palette.grey[900],
    font: {
      size: 14,
      family: 'Overpass, sans-serif',
    },
  };

  const yTicks = {
    min: 0,
    callback: (value) => `${formatAsDollars(value)}`,
    color: theme.palette.grey[900],
    padding: 20,
    font: {
      size: 14,
      family: 'Overpass, sans-serif',
    },
  };

  return (
    <Box height="400px" maxWidth="890px">
      <StyledBarGraph
        data={chargeData}
        graphTitle={formatMessage({ id: 'panels.financials.costs' }).toUpperCase()}
        showLegend
        yTicks={yTicks}
        xTicks={xTicks}
        tooltipTitleFormatter={(data) => data[0].label}
        tooltipLabelFormatter={(data) => `${data.dataset.label}: ${formatAsDollars(data.raw)}`}
      />
    </Box>
  );
}
