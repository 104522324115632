import React, { useState } from 'react';
import { Grid, Box, useTheme, useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';
import Overview from './overview/Overview';
import Financials from './financials/Financials';
import Fuel from './fuel/Fuel';
import Incentives from './incentives/Incentives';
import Comparison from './comparison/Comparison';
import TabContent from './TabContent';
import FleetSummary from '../components/FleetSummary';
import LeftInputs from '../inputs/LeftInputs';
import FindYourRep from '../components/FindYourRep';
import { useDisplay } from '../components/DisplayProvider';
import ButtonInput from '../components/ButtonInput';
import logo from '../assets/images/icons/zappyride.png';

export default function ContentPane() {
  const { formatMessage } = useIntl();
  const { mode } = useDisplay();
  const theme = useTheme();
  const [showInputs, setShowInputs] = useState(false);

  const tabs = [
    {
      label: formatMessage({ id: 'tabs.overview' }),
      content: <Overview />,
    },
    {
      label: formatMessage({ id: 'tabs.financials' }),
      content: <Financials />,
    },
    {
      label: formatMessage({ id: 'tabs.fuel' }),
      content: <Fuel />,
    },
    {
      label: formatMessage({ id: 'tabs.incentives' }),
      content: <Incentives />,
    },
    {
      label: formatMessage({ id: 'tabs.comparison' }),
      content: <Comparison />,
    },
  ];

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const handleShowInputs = () => {
    setShowInputs((value) => !value);
    document.documentElement.scrollTop = 0;
  };

  return (
    <Grid container justifyContent="center" mb={6}>
      <Grid
        item
        xs={12}
        sx={{ display: 'none', [theme.breakpoints.up('sm')]: { display: 'block' } }}
      >
        <FleetSummary />
      </Grid>
      <Grid
        container
        width="100%"
        sx={{ [theme.breakpoints.up('sm')]: { maxWidth: '1400px', mt: '80px', px: '12px' } }}
        justifyContent="start"
      >
        {(showInputs || isLargeScreen) && (
          <Grid item xs={12} md={3} mb="200px">
            <LeftInputs />
          </Grid>
        )}
        {(isLargeScreen || !showInputs) && (
          <Grid item xs={12} md={8} mb="200px">
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ color: 'black', fontSize: '12px' }}
              >
                <span>powered by </span>
                <img height="20px" src={logo} alt="zappy ride logo" />
              </Box>
            </Grid>
            <TabContent tabList={tabs} />
          </Grid>
        )}
        {mode !== 'print' && <FindYourRep />}
        <Box
          textAlign="center"
          position="fixed"
          bottom="135px"
          p={2}
          backgroundColor="white"
          width="100%"
          zIndex={2}
          boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
          sx={{ display: 'block', [theme.breakpoints.up('sm')]: { display: 'none' } }}
        >
          <ButtonInput variant="contained" textTransform="none" onClick={handleShowInputs}>
            {formatMessage({ id: `inputs.${showInputs ? 'updateButton' : 'inputsButton'}` })}
          </ButtonInput>
        </Box>
      </Grid>
    </Grid>
  );
}
