const organizationTypes = [
  {
    id: 'car_sharing_companies',
    translationKey: 'organizationTypes.car_sharing_companies',
  },
  {
    id: 'federal_government_entities',
    translationKey: 'organizationTypes.federal_government_entities',
  },
  {
    id: 'local_government_entities',
    translationKey: 'organizationTypes.local_government_entities',
  },
  {
    id: 'logistics_company',
    translationKey: 'organizationTypes.logistics_company',
  },
  {
    id: 'medium_or_large_businesses',
    translationKey: 'organizationTypes.medium_or_large_businesses',
  },
  {
    id: 'native_tribes',
    translationKey: 'organizationTypes.native_tribes',
  },
  { id: 'nonprofits', translationKey: 'organizationTypes.nonprofits' },
  {
    id: 'private_school_districts',
    translationKey: 'organizationTypes.private_school_districts',
  },
  {
    id: 'public_school_districts',
    translationKey: 'organizationTypes.public_school_districts',
  },
  {
    id: 'rental_companies',
    translationKey: 'organizationTypes.rental_companies',
  },
  {
    id: 'small_businesses',
    translationKey: 'organizationTypes.small_businesses',
  },
  {
    id: 'state_government_entities',
    translationKey: 'organizationTypes.state_government_entities',
  },
  {
    id: 'transit_agencies',
    translationKey: 'organizationTypes.transit_agencies',
  },
  { id: 'universities', translationKey: 'organizationTypes.universities' },
  { id: 'utility', translationKey: 'organizationTypes.utility' },
  { id: 'other', translationKey: 'organizationTypes.other' },
];

export default organizationTypes;
