import React from 'react';
import { Grid, useTheme, Typography } from '@mui/material';

export default function SpecCard({ title, specList, titleAction }) {
  const theme = useTheme();

  return (
    <Grid
      container
      alignContent="start"
      border={`2px solid ${theme.palette.grey[400]}`}
      borderRadius="4px"
      padding={4}
      height="100%"
    >
      <Grid container xs={12} flexWrap="nowrap" alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography fontFamily={theme.typography.fontFamily} height={16} pb={1}>
            {title}
          </Typography>
        </Grid>
        <Grid item>{titleAction}</Grid>
      </Grid>
      {specList.map((spec, index) =>
        spec ? (
          <Grid
            item
            xs={12}
            key={spec.spec}
            py={3}
            sx={{
              '&:not(:last-child)': {
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
              },
            }}
          >
            <Typography fontSize="14px" fontFamily={theme.typography.fontFamily} pb="12px">
              {spec.value}
            </Typography>
            <Typography fontSize="12px" fontFamily={theme.typography.fontFamily}>
              {spec.isLow ? spec.spec : spec.spec.toUpperCase()}
            </Typography>
            {spec.disclaimer && (
              <Typography
                fontSize="12px"
                fontFamily={theme.typography.fontFamily}
                pb="12px"
                fontStyle="italic"
              >
                {spec.disclaimer}
              </Typography>
            )}
          </Grid>
        ) : null,
      )}
    </Grid>
  );
}
