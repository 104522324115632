import { useInputs } from '@bellawatt/use-inputs';
import { useEffect } from 'react';
import { get } from './api';

export default function useApiIncentiveData() {
  const { loading: inputsLoading, apisLoading, zipCode, setInput } = useInputs();

  useEffect(() => {
    if (inputsLoading) return;

    setInput({
      apisLoading: {
        ...apisLoading,
        incentives: true,
      },
    });

    get(`/commercial/incentives?postcode=${zipCode}`)
      .then((response) => {
        setInput({
          apisLoading: {
            ...apisLoading,
            incentives: false,
          },
          apiIncentives: response.data.incentives,
        });
      })
      .catch((err) => {
        console.error(err);
        setInput({
          apisLoading: {
            ...apisLoading,
            incentives: false,
          },
        });
      });
    // adding setInput to deps causes the effect to run every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputsLoading, zipCode]);
}
