import { Box, useTheme, Typography } from '@mui/material';
import StyledTable from '../../components/charts/StyledTable';
import { formatAsDollars, formatAsThousands, formatAsCents } from '../../utils/formatters';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import sum from 'lodash/sum';
import VehicleSet from '../../calculations/VehicleSet';
import ToolTip from '../../components/ToolTip';

export default function FossilFleetTable({ fossilFuelCosts }) {
  const { vehicleSets, dieselPrice, gasolinePrice, lifespanYears } = useInputs();
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const labels = [
    formatMessage({ id: 'panels.fuel.set' }),
    formatMessage({ id: 'panels.fuel.count' }),
    [
      formatMessage({ id: 'panels.fuel.efficiency' }),
      <Typography fontSize="12px" key={1}>
        {formatMessage({ id: 'panels.fuel.table.gasUnits' })}
      </Typography>,
    ],
    formatMessage({ id: 'panels.fuel.miles' }),
    [
      formatMessage({ id: 'panels.fuel.fuelCost' }),
      <Typography fontSize="12px" key={2}>
        {formatMessage({ id: 'panels.fuel.table.gasDollars' })}
      </Typography>,
    ],
    formatMessage({ id: 'panels.fuel.cost' }),
  ];

  const vehicleSetData = vehicleSets.map((set) => {
    const fuelCost = set.existingVehicle.fuel === 'gasoline' ? gasolinePrice : dieselPrice;
    const efficiency = `${set.existingVehicle.milesPerGallon} - ${
      set.existingVehicle.fuel === 'gasoline' ? 'Gasoline' : 'Diesel'
    }`;

    // console.log(set.existingVehicle);
    return [
      set.existingVehicle.year + ' ' + set.existingVehicle.make + ' ' + set.existingVehicle.model,
      set.vehicleCount,
      efficiency,
      <ToolTip
        content={formatMessage(
          { id: 'panels.fuel.table.toolTip' },
          { total: formatAsThousands(VehicleSet.annualMiles(set, set.personalMilesPaidFor)) },
        )}
      >
        {formatAsThousands(VehicleSet.annualMiles(set, set.personalMilesPaidFor))}
      </ToolTip>,
      formatAsCents(fuelCost),
      formatAsDollars(fossilFuelCosts[set.id]),
    ];
  });

  const rows = [
    ...vehicleSetData,
    [
      <Typography fontSize="14px" fontFamily={theme.typography.fontFamily}>
        {formatMessage({ id: 'panels.fuel.annualTotal' })}
      </Typography>,
      null,
      null,
      null,
      null,
      formatAsDollars(sum(Object.values(fossilFuelCosts))),
    ],
    [
      <Typography fontSize="14px" fontFamily={theme.typography.fontFamily}>
        {formatMessage({ id: 'panels.fuel.lifetime' }, { time: lifespanYears })}
      </Typography>,
      null,
      null,
      null,
      null,
      formatAsDollars(sum(Object.values(fossilFuelCosts)) * lifespanYears),
    ],
  ];

  return (
    <Box>
      <Typography mb="12px" fontSize="14px" fontFamily={theme.typography.fontFamily}>
        {formatMessage({ id: 'panels.fuel.fossilFleet' }).toUpperCase()}
      </Typography>
      <StyledTable
        headings={labels}
        rows={rows}
        headerColor={theme.palette.primary.main}
        firstColumnColor={`${theme.palette.primary.main}33`}
        firstCellColor={theme.palette.grey[900]}
        borderColor={theme.palette.grey[900]}
      />
    </Box>
  );
}
