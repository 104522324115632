import React from 'react';
import VehicleUsage from '../../dialogs/editVehicleSetDialog/VehicleUsage';

export default function VehicleUsageStep({
  vehicleSet,
  onChangeVehicleSet,
  setDisabled,
  isEditing,
}) {
  return (
    <VehicleUsage
      vehicleSet={vehicleSet}
      onChangeVehicleSet={onChangeVehicleSet}
      setDisabled={setDisabled}
      isEditing={isEditing}
    />
  );
}
