import React from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { formatAsDollars, formatAsCents } from '../../utils/formatters';
import times from 'lodash/times';
import { useInputs } from '@bellawatt/use-inputs';
import ToolTip from '../../components/ToolTip';

export default function SummaryBoxes({ fossilFleetCosts, evFleetCosts }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const { dieselPrice, gasolinePrice, lifespanYears } = useInputs();

  const { byCategory: fossilCategories } = fossilFleetCosts;
  const { byCategory: evCategories } = evFleetCosts;

  const fuelSavings = fossilCategories.fuel - evCategories.fuel;
  const purchaseSavings = fossilCategories.purchase - evCategories.purchase;
  const totalSavings = fuelSavings + purchaseSavings;

  // first year where electrified fleet costs less than retiring fleet (or undefined if it doesn't happen within 10 years)
  const paybackYear = times(fossilFleetCosts.byYear.length).find(
    (year) => evFleetCosts.byYear[year] <= fossilFleetCosts.byYear[year],
  );

  const gasData = [...fossilFleetCosts.byYear];
  gasData.shift();
  const electricData = [...evFleetCosts.byYear];
  electricData.shift();

  const savings = gasData[gasData.length - 1] - electricData[electricData.length - 1];
  const noBreakEven = savings <= 0;

  return (
    <Box>
      <Box
        justifyContent="space-between"
        sx={{ display: 'block', [theme.breakpoints.up('sm')]: { display: 'flex' } }}
      >
        {noBreakEven ? (
          <Box
            p={2}
            backgroundColor={theme.palette.grey[100]}
            border={`1px solid ${theme.palette.grey[300]}`}
            sx={{
              maxWidth: '100%',
              [theme.breakpoints.up('sm')]: { width: '400px', marginRight: '30px' },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
              <Typography
                fontFamily={theme.typography.fontFamily}
                fontSize={'24px'}
                color={theme.palette.primary.main}
              >
                {formatMessage({ id: 'panels.financials.payback.noBreakeven' })}
              </Typography>
            </Box>
            <Typography
              fontFamily={theme.typography.fontFamily}
              fontSize={'16px'}
              color={theme.palette.grey[900]}
            >
              {formatMessage({ id: 'panels.financials.payback.noBreakevenText' })}
            </Typography>
          </Box>
        ) : (
          <Box
            p={2}
            backgroundColor={theme.palette.grey[100]}
            border={`1px solid ${theme.palette.grey[300]}`}
            sx={{
              maxWidth: '100%',
              [theme.breakpoints.up('sm')]: { width: '400px', marginRight: '30px' },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
              <Typography
                fontFamily={theme.typography.fontFamily}
                fontSize={'24px'}
                color={theme.palette.primary.main}
              >
                {formatMessage(
                  { id: `panels.financials.payback.${paybackYear === 1 ? 'year' : 'years'}` },
                  { value: paybackYear },
                )}
              </Typography>
              <ToolTip
                content={formatMessage(
                  { id: 'panels.financials.tooltips.breakeven' },
                  {
                    years: lifespanYears,
                    dieselFuelCost: formatAsCents(dieselPrice),
                    gasFuelCost: formatAsCents(gasolinePrice),
                  },
                )}
              />
            </Box>
            <Typography
              fontFamily={theme.typography.fontFamily}
              fontSize={'16px'}
              color={theme.palette.grey[900]}
            >
              {formatMessage({ id: 'panels.financials.payback.summary' })}
            </Typography>
            <Typography
              fontFamily={theme.typography.fontFamily}
              fontSize={'12px'}
              color={theme.palette.grey[700]}
              fontStyle="italic"
            >
              {formatMessage({ id: 'panels.financials.payback.disclaimer' })}
            </Typography>
          </Box>
        )}

        <Box
          p={2}
          backgroundColor={theme.palette.grey[100]}
          border={`1px solid ${theme.palette.grey[300]}`}
          mt={2}
          sx={{ maxWidth: '100%', [theme.breakpoints.up('sm')]: { width: '400px', mt: 0 } }}
        >
          <Typography
            fontFamily={theme.typography.fontFamily}
            fontSize={'16px'}
            color={theme.palette.grey[900]}
          >
            {formatMessage({ id: 'panels.financials.lifetime.look' })}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Typography
              fontFamily={theme.typography.fontFamily}
              fontSize={'24px'}
              color={theme.palette.primary.main}
            >
              {formatMessage(
                { id: 'panels.financials.lifetime.savings' },
                { value: formatAsDollars(totalSavings) },
              )}
            </Typography>
            <ToolTip
              content={formatMessage(
                { id: 'panels.financials.tooltips.savings' },
                {
                  years: lifespanYears,
                  dieselFuelCost: formatAsCents(dieselPrice),
                  gasFuelCost: formatAsCents(gasolinePrice),
                },
              )}
            />
          </Box>
          <Typography
            fontFamily={theme.typography.fontFamily}
            fontSize={'16px'}
            color={theme.palette.grey[900]}
          >
            {formatMessage(
              { id: 'panels.financials.lifetime.overTime' },
              {
                years: lifespanYears
              },
              )}
          </Typography>
          <Typography
            fontFamily={theme.typography.fontFamily}
            fontSize={'12px'}
            color={theme.palette.grey[700]}
            sx={{ marginTop: '24px' }}
            fontStyle="italic"
          >
            {formatMessage({ id: 'panels.financials.lifetime.disclaimer' })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
