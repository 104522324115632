import { React, useState } from 'react';
import { Box } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import { cloneDeep } from 'lodash';
import GettingStartedStep from './steps/GettingStartedStep';
import GeneralInformationStep from './steps/GeneralInformationStep';
import VehicleUsageStep from './steps/VehicleUsageStep';
import SelectVehicleStep from './steps/selectVehicleStep/SelectVehicleStep';
import OnboardingButtonRow from './OnboardingButtonRow';
import { addComputedData, omitComputedData } from '../utils/computedData';

export default function OnboardingContent() {
  const {
    onboardingStep,
    vehicleSets,
    apiFossilVehicles,
    apiElectricVehicles,
    apiChargers,
    setInput,
  } = useInputs();

  const [vehicleSet, setVehicleSet] = useState(vehicleSets[0]);
  const [disabled, setDisabled] = useState(false);

  const handleSave = () => {
    const newSets = cloneDeep(vehicleSets);
    newSets[0] = vehicleSet;

    setInput({ vehicleSets: omitComputedData(newSets), isOnboarded: true });
  };

  const handleChangeVehicleSet = (newData) => {
    let replacementVehicleId;
    let shouldSetReplacementVehicleId = true;

    if (newData.replacementVehicle === undefined) {
      shouldSetReplacementVehicleId = false;
    } else {
      if (newData.replacementVehicle) {
        replacementVehicleId = newData.replacementVehicle.id;
      } else if (vehicleSet.replacementVehicle.id) {
        replacementVehicleId = vehicleSet.replacementVehicle.id;
      } else {
        replacementVehicleId = vehicleSet.replacementVehicleId;
      }
    }

    setVehicleSet((vs) => {
      const baseData = {
        vehicleSets: [
          {
            ...vs,
            ...newData,
          },
        ],
        apiFossilVehicles,
        apiElectricVehicles,
        apiChargers,
      };

      if (shouldSetReplacementVehicleId) {
        baseData.vehicleSets[0].replacementVehicleId = replacementVehicleId;
      }

      const newVehicleSet = addComputedData(baseData).vehicleSets[0];
      return newVehicleSet;
    });
  };

  const steps = [
    {
      name: 'gettingStarted',
      content: <GettingStartedStep />,
    },
    {
      name: 'generalInformation',
      content: <GeneralInformationStep />,
    },
    {
      name: 'selectVehicle',
      content: (
        <SelectVehicleStep vehicleSet={vehicleSet} onChangeVehicleSet={handleChangeVehicleSet} />
      ),
    },
    {
      name: 'vehicleUsage',
      content: (
        <VehicleUsageStep
          vehicleSet={vehicleSet}
          onChangeVehicleSet={handleChangeVehicleSet}
          setDisabled={setDisabled}
        />
      ),
    },
  ];

  const stepIndex = steps.findIndex((step) => step.name === onboardingStep);
  return (
    <Box>
      <Box
        sx={(theme) => ({
          mb: '300px',
          [theme.breakpoints.up('sm')]: {
            mb: '120px',
          },
        })}
      >
        {steps[stepIndex].content}
      </Box>
      <OnboardingButtonRow onSave={handleSave} vehicleSet={vehicleSet} disabled={disabled} />
    </Box>
  );
}
