import React from 'react';
import { Box, Checkbox, Typography, useTheme } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import times from 'lodash/times';
import { useIntl } from 'react-intl';
import CollapsibleInput from './CollapsibleInput';
import DropdownInput from '../components/DropdownInput';
import booleanOptions from '../data/booleanOptions';
import organizationTypes from '../data/organizationTypes';

export default function AssumptionInputs() {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const {
    lifespanYears,
    organizationType,
    isPublicEntity,
    isTaxExempt,
    isMultipleSites,
    setInput,
  } = useInputs();

  const lifespanOptions = times(5, (i) => ({
    value: i + 1,
    children: formatMessage({ id: 'timePeriods.years' }, { years: i + 1 }),
  }));

  return (
    <CollapsibleInput title={formatMessage({ id: 'inputs.assumptions.title' })}>
      <DropdownInput
        id="vehicle-lifespan-input"
        label={formatMessage({ id: 'inputs.assumptions.vehicleLifetime.label' })}
        options={lifespanOptions}
        value={lifespanYears}
        onChange={(e) => setInput({ lifespanYears: parseInt(e.target.value) })}
        tooltip={formatMessage({ id: 'inputs.assumptions.vehicleLifetime.tooltip' })}
        mb={4}
      />

      <DropdownInput
        id="organization-type-input"
        label={formatMessage({ id: 'inputs.assumptions.organizationType.label' })}
        options={organizationTypes.map(({ id, translationKey }) => ({
          value: id,
          children: formatMessage({ id: translationKey }),
        }))}
        value={organizationType}
        onChange={(e) => setInput({ organizationType: e.target.value })}
        tooltip={formatMessage({ id: 'inputs.assumptions.organizationType.tooltip' })}
        mb={4}
      />

      <DropdownInput
        id="public-entity-input"
        label={formatMessage({ id: 'inputs.assumptions.publicEntity.label' })}
        options={booleanOptions.map(({ id, translationKey }) => ({
          value: id,
          children: formatMessage({ id: translationKey }),
        }))}
        value={isPublicEntity ? 'yes' : 'no'}
        onChange={(e) => setInput({ isPublicEntity: e.target.value === 'yes' })}
        tooltip={formatMessage({ id: 'inputs.assumptions.publicEntity.tooltip' })}
        mb={4}
      />

      <DropdownInput
        id="tax-exempt-input"
        label={formatMessage({ id: 'inputs.assumptions.taxExempt.label' })}
        options={booleanOptions.map(({ id, translationKey }) => ({
          value: id,
          children: formatMessage({ id: translationKey }),
        }))}
        value={isTaxExempt ? 'yes' : 'no'}
        onChange={(e) => setInput({ isTaxExempt: e.target.value === 'yes' })}
        tooltip={formatMessage({ id: 'inputs.assumptions.taxExempt.tooltip' })}
        mb={4}
      />

      <Box display="flex">
        <Checkbox
          checked={isMultipleSites}
          onChange={(e) => setInput({ isMultipleSites: e.target.checked })}
          inputProps={{ 'aria-label': 'Purchasing for multiple sites' }}
          disableRipple
          sx={{ marginRight: '1rem', alignSelf: 'flex-start' }}
        />
        <Box>
          <Typography fontFamily={theme.typography.fontFamily} fontSize="0.875rem" mb={1}>
            {formatMessage({ id: 'inputs.assumptions.multipleSitesPurchase.header' })}
          </Typography>
          <Typography fontSize="0.75rem">
            {formatMessage({ id: 'inputs.assumptions.multipleSitesPurchase.subheader' })}
          </Typography>
        </Box>
      </Box>
    </CollapsibleInput>
  );
}
