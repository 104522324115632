import { Box, useTheme, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import DropdownInput from '../../components/DropdownInput';
import { useInputs } from '@bellawatt/use-inputs';
import uniq from 'lodash.uniq';

export default function Filters({ vehicleSet, onChange }) {
  const { formatMessage } = useIntl();
  const { apiElectricVehicles } = useInputs();
  const theme = useTheme();
  const [vehicleType, setVehicleType] = useState(vehicleSet.existingVehicle.type || 'All');
  const [vehicleSubtype, setVehicleSubtype] = useState('All');
  const [vehicleMake, setVehicleMake] = useState('All');
  const [selectedRange, setSelectedRange] = useState(0);

  const vehicleTypes = uniq(apiElectricVehicles.map(({ type }) => type))
    .map((type) => ({
      value: type,
      children: type,
    }))
    .sort((a, b) => a.value.localeCompare(b.value));

  const vehicleSubtypes = uniq(
    apiElectricVehicles
      .filter((v) => (vehicleType === 'All' || v.type === vehicleType) && v.subtype !== undefined)
      .map(({ subtype }) => subtype),
  )
    .map((subtype) => ({ value: subtype, children: subtype }))
    .sort((a, b) => a.value.localeCompare(b.value));

  const vehicleMakes = uniq(
    apiElectricVehicles
      .filter(
        (v) =>
          (vehicleType === 'All' || v.type === vehicleType) &&
          (vehicleSubtype === 'All' || v.subtype === vehicleSubtype),
      )
      .map(({ make }) => make),
  )
    .map((make) => ({ value: make, children: make }))
    .sort((a, b) => a.value.localeCompare(b.value));

  vehicleTypes.unshift({
    value: 'All',
    children: formatMessage({ id: 'inputs.chargerCatalog.all' }),
  });
  vehicleSubtypes.unshift({
    value: 'All',
    children: formatMessage({ id: 'inputs.chargerCatalog.all' }),
  });
  vehicleMakes.unshift({
    value: 'All',
    children: formatMessage({ id: 'inputs.chargerCatalog.all' }),
  });

  const rangeOptions = [
    {
      value: 0,
      children: formatMessage({ id: 'inputs.chargerCatalog.all' }),
    },
    {
      value: 250,
      children: formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.lessThan' }),
    },
    {
      value: 500,
      children: formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.between' }),
    },
    {
      value: 501,
      children: formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.over' }),
    },
  ];

  return (
    <Box mb={4}>
      <Typography fontFamily={theme.typography.fontFamily} fontSize="16px" py="20px">
        {formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.filters' })}
      </Typography>
      <DropdownInput
        id="vehicle-types-filter-input"
        label={formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.type' })}
        options={vehicleTypes}
        value={vehicleType}
        onChange={(e) => {
          setVehicleType(e.target.value);
          onChange('type', e.target.value);
        }}
        mb={3}
      />
      <DropdownInput
        id="vehicle-subtype-filter-input"
        label={formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.subtype' })}
        options={vehicleSubtypes}
        value={vehicleSubtype}
        onChange={(e) => {
          setVehicleSubtype(e.target.value);
          onChange('subtype', e.target.value);
        }}
        mb={3}
      />
      <Box borderBottom={`1px solid ${theme.palette.grey[300]}`} mb={3}>
        <DropdownInput
          id="vehicle-make-filter-input"
          label={formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.make' })}
          options={vehicleMakes}
          value={vehicleMake}
          onChange={(e) => {
            setVehicleMake(e.target.value);
            onChange('make', e.target.value);
          }}
          mb={3}
        />
      </Box>
      <DropdownInput
        id="vehicle-range-filter-input"
        label={formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.vehicleRange' })}
        options={rangeOptions}
        value={selectedRange}
        onChange={(e) => {
          setSelectedRange(e.target.value);
          onChange('range', e.target.value);
        }}
      />
    </Box>
  );
}
