import React, { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { ChevronIcon } from '../assets/icons/icons';
import { useDisplay } from '../components/DisplayProvider';

export default function CollapsibleInput({
  title,
  defaultOpen = false,
  hasBottomBorder,
  hasAllBorder,
  children,
}) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const { mode } = useDisplay();

  return (
    <Box border={hasAllBorder ? `1px solid ${theme.palette.grey[300]}` : ''} width="100%">
      <Box
        onClick={() => setIsOpen((prev) => !prev)}
        sx={{ cursor: 'pointer' }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        px={hasAllBorder && 2}
        borderTop={hasAllBorder ?? `1px solid ${theme.palette.grey[300]}`}
        borderBottom={
          !hasAllBorder && (isOpen || hasBottomBorder) && `1px solid ${theme.palette.grey[300]}`
        }
        fontFamily={theme.typography.fontFamily}
        color={theme.palette.grey[900]}
      >
        <Box fontFamily={theme.typography.fontFamily} width="100%">
          {title}
        </Box>
        <ChevronIcon rotate={!isOpen} transition />
      </Box>
      {(isOpen || mode === 'print') && (
        <Box mt={2} mb={6}>
          {children}
        </Box>
      )}
    </Box>
  );
}
