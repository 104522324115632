import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  useMediaQuery,
  DialogActions,
  useTheme,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import CollapsibleInput from '../../inputs/CollapsibleInput';
import ModalButtonRow from './ModalButtonRow';
import VehicleSelection from './VehicleSelection';
import { cloneDeep } from 'lodash';
import VehicleUsage from './VehicleUsage';
import { addComputedData } from '../../utils/computedData';
import vehicleMappings from '../../data/vehicleMappings';

export default function EditVehicleSetDialog({
  isOpen,
  onClose,
  onDelete,
  canRemove,
  onSave,
  vehicleSet,
  isEditing,
}) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { apiFossilVehicles, apiElectricVehicles, apiChargers } = useInputs();
  const [workingVehicleSet, setWorkingVehicleSet] = useState(cloneDeep(vehicleSet));
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setWorkingVehicleSet(cloneDeep(vehicleSet));
  }, [vehicleSet]);

  const handleChangeVehicleSet = (newData) => {
    const replacementVehicleId =
      newData['existingVehicleId'] !== undefined
        ? vehicleMappings[newData.existingVehicleId].ev_id
        : newData.replacementVehicle
        ? newData.replacementVehicle.id
        : vehicleSet.replacementVehicleId;

    // when working vehicle set state is changed, pass it through the use-inputs computed flow
    // to make sure all pieces of state are available
    const newVehicleSet = addComputedData({
      vehicleSets: [
        {
          ...vehicleSet,
          ...newData,
          replacementVehicleId,
        },
      ],
      apiFossilVehicles,
      apiElectricVehicles,
      apiChargers,
    }).vehicleSets[0];

    setWorkingVehicleSet(newVehicleSet);
  };

  const handleDelete = () => {
    const id = workingVehicleSet.id;
    onDelete(id);
  };

  const handleSave = () => {
    const newSet = {
      ...workingVehicleSet,
      isNewSet: false,
    };
    onSave(newSet);
  };

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Dialog open={isOpen} onBackdropClick={onClose} maxWidth={isLargeScreen ? 'lg' : 'sm'}>
      <DialogTitle>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            minWidth: '380px',
            [theme.breakpoints.up('sm')]: { minWidth: '1000px' },
          })}
        >
          <Typography fontFamily={theme.typography.fontFamily} fontSize="24px">
            {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent px="48px">
        <Box mb="4px">
          <CollapsibleInput
            title={formatMessage({ id: 'inputs.vehicleSet.editSetDialog.selection' })}
            hasAllBorder
            defaultOpen
          >
            <Box maxWidth="900px">
              <VehicleSelection
                vehicleSet={workingVehicleSet}
                onChangeVehicleSet={handleChangeVehicleSet}
                setDisabled={setDisabled}
                isEditing={isEditing}
              />
            </Box>
          </CollapsibleInput>
        </Box>
        <Box mb="4px">
          <CollapsibleInput
            title={formatMessage({ id: 'inputs.vehicleSet.editSetDialog.usage' })}
            hasAllBorder
          >
            <Box maxWidth="900px">
              <VehicleUsage
                vehicleSet={workingVehicleSet}
                onChangeVehicleSet={handleChangeVehicleSet}
                setDisabled={setDisabled}
                isEditing={isEditing}
              />
            </Box>
          </CollapsibleInput>
        </Box>
      </DialogContent>
      <DialogActions>
        <ModalButtonRow
          onClose={onClose}
          onDelete={handleDelete}
          showDelete={canRemove}
          onSaveAndClose={handleSave}
          disabled={disabled}
          isNew={vehicleSet?.isNewSet}
        />
      </DialogActions>
    </Dialog>
  );
}
