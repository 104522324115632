import React, { useState } from 'react';
import { Box } from '@mui/material';
import { GMIcon, PoweredByIcon } from '../assets/icons/icons';
import { useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import Dialog from '../dialogs/dialog/Dialog';

export default function Footer() {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const [openDisclaimer, setOpenDisclaimer] = useState(false);

  return (
    <Box
      role="contentinfo"
      container
      zIndex={2}
      backgroundColor={theme.palette.grey[900]}
      height="135px"
      width="100%"
      pl={3}
      pt={2}
      position="fixed"
      bottom="0"
      sx={{
        [theme.breakpoints.up('sm')]: {
          position: 'relative',
          px: '166px',
          pt: '23px',
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        sx={(theme) => ({
          alignItems: 'start',
          flexDirection: 'column',
          rowGap: '16px',
          [theme.breakpoints.up('md')]: {
            alignItems: 'center',
            flexDirection: 'row',
          },
        })}
      >
        <GMIcon />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
            rowGap: '8px',
            [theme.breakpoints.up('sm')]: {
              alignItems: 'center',
              flexDirection: 'row',
            },
          }}
        >
          <Box pl={{ xs: '0', sm: '24px' }} mb={{ xs: '0', sm: '-5px' }}>
            <PoweredByIcon />
          </Box>
        </Box>
      </Box>
      <Dialog
        isOpen={openDisclaimer}
        title={formatMessage({ id: 'incentive.dialogTitle' })}
        onClose={() => setOpenDisclaimer(false)}
      >
        {formatMessage({ id: 'incentive.disclaimer' })}
      </Dialog>
    </Box>
  );
}
