import React from 'react';
import { useTheme, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useIntl } from 'react-intl';

export default function StyledToggleButton({ title1, title2, selected, toggleSelected }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <ToggleButtonGroup
      sx={{
        '& .Mui-selected': {
          color: 'white !important',
          backgroundColor: `${theme.palette.primary.main} !important`,
        },
      }}
      value={selected}
      exclusive
      onChange={(ev, value) => toggleSelected(value)}
    >
      <ToggleButton value={title1}>{formatMessage({ id: title1 })}</ToggleButton>
      <ToggleButton value={title2}>{formatMessage({ id: title2 })}</ToggleButton>
    </ToggleButtonGroup>
  );
}
