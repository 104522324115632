import { useInputs } from '@bellawatt/use-inputs';
import { useEffect } from 'react';
import { get } from './api';

const transformLocationData = (data, isMultipleSites) => ({
  dieselPrice: isMultipleSites
    ? data.national_fuel_cost[0].diesel
    : data.regional_fuel_cost[0].diesel,
  gasolinePrice: isMultipleSites
    ? data.national_fuel_cost[0].gasoline
    : data.regional_fuel_cost[0].gasoline,
  electricityPrice: isMultipleSites
    ? data.national_fuel_cost[0].commercial_electricity / 100
    : data.regional_fuel_cost[0].commercial_electricity / 100,
  electricityEmissions: isMultipleSites
    ? data.national_electricity[0].emissions.emissions_co2
    : data.regional_electricity[0].emissions.emissions_co2,
  powerSupplier: data.power_supplier[0].name,
  state: data.region,
});

export default function useApiLocationData() {
  const { loading: inputsLoading, apisLoading, zipCode, isMultipleSites, setInput } = useInputs();

  useEffect(() => {
    if (inputsLoading) return;

    setInput({
      apisLoading: {
        ...apisLoading,
        location: true,
      },
    });

    get(`/location?postcode=${zipCode}`)
      .then((response) => {
        const data = transformLocationData(response.data.location, isMultipleSites);
        setInput({
          apisLoading: {
            ...apisLoading,
            location: false,
          },
          ...data,
        });
      })
      .catch((err) => {
        console.error(err);
        setInput({
          apisLoading: {
            ...apisLoading,
            location: false,
          },
        });
      });
    // adding setInput to deps causes the effect to run every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputsLoading, zipCode, isMultipleSites]);
}
