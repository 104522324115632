import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography, useTheme } from '@mui/material';
import { PencilIcon } from '../assets/icons/icons';
import { formatAsThousands, formatHours } from '../utils/formatters';
import ButtonInput from '../components/ButtonInput';

const formatOperatingDays = ([first, ...rest]) => {
  const daysOfWeek = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'];
  // workdays are stored with 0 === Sunday, but we want to display Monday first
  const reorderedOperatingDays = first === 0 ? [...rest, first] : [first, ...rest];
  return reorderedOperatingDays.map((day) => daysOfWeek[day]).join(',');
};

const formatChargingWindows = (chargingWindows, defaultMsg) => {
  return chargingWindows.length > 1
    ? defaultMsg
    : `${formatHours(chargingWindows[0].start)} - ${formatHours(chargingWindows[0].finish)}`;
};

const VehicleSpec = ({ label, value }) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize="0.75rem" color={theme.palette.grey[700]}>
        {label}
      </Typography>
      <Typography
        fontSize="0.75rem"
        fontFamily={theme.typography.fontFamily}
        color={theme.palette.grey[800]}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default function EditVehicleSetCard({ vehicleSet, onClick }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const {
    name,
    vehicleCount,
    workdays,
    milesPerWorkday,
    chargingWindows,
    existingVehicle: { make, model, subtype, year },
  } = vehicleSet;

  return (
    <Box border={`1px solid ${theme.palette.grey[300]}`} mb={3} py={1} px={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom={`1px solid ${theme.palette.grey[300]}`}
      >
        <Box mr={0.5}>
          <Typography
            fontFamily={theme.typography.fontFamily}
            color={theme.palette.grey[800]}
            mb={0.5}
          >
            {name}
          </Typography>
          <Box display="flex">
            <Typography fontFamily={theme.typography.fontFamily} fontSize="0.75rem" mr={1.5}>
              {vehicleCount}x
            </Typography>
            <Typography fontSize="0.75rem">
              {year} {make} {model}
            </Typography>
          </Box>
        </Box>
        <Box flexShrink={0}>
          <ButtonInput title={formatMessage({ id: 'a11y.edit' })} onClick={onClick} variant="icon">
            <PencilIcon />
          </ButtonInput>
        </Box>
      </Box>
      <Box px={1} pt={1}>
        <VehicleSpec
          label={formatMessage({ id: 'inputs.vehicleSet.editCard.specs.type' })}
          value={subtype}
        />
        <VehicleSpec
          label={formatMessage({ id: 'inputs.vehicleSet.editCard.specs.milesPerWorkday' })}
          value={formatAsThousands(milesPerWorkday)}
        />
        <VehicleSpec
          label={formatMessage({ id: 'inputs.vehicleSet.editCard.specs.daysOperating' })}
          value={formatOperatingDays(workdays)}
        />
        <VehicleSpec
          label={formatMessage({ id: 'inputs.vehicleSet.editCard.specs.chargingWindows' })}
          value={formatChargingWindows(
            chargingWindows,
            formatMessage({ id: 'inputs.vehicleSet.editCard.specs.multipleWindows' }),
          )}
        />
      </Box>
    </Box>
  );
}
