import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import StyledBarGraph from '../../components/charts/StyledBarGraph';
import { formatAsDollars } from '../../utils/formatters';
import { useIntl } from 'react-intl';
import Link from '../../components/Link';

export function YearlyFuelCostChart({ electricCosts, fossilFuelCosts, savings }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const labels = [
    [
      formatMessage({ id: 'panels.fuel.eFleet' }),
      `(${formatMessage({ id: 'panels.fuel.yourSelect' })})`,
    ],
    [
      formatMessage({ id: 'panels.fuel.gFleet' }),
      `(${formatMessage({ id: 'panels.fuel.similar' })})`,
    ],
  ];

  const chargeData = {
    labels,
    datasets: [
      {
        label: formatMessage({ id: 'panels.fuel.electricity' }),
        data: [electricCosts],
        backgroundColor: theme.palette.primary.main,
        barPercentage: 0.5,
      },
      {
        label: formatMessage({ id: 'panels.fuel.gas' }),
        data: [0, fossilFuelCosts],
        backgroundColor: theme.palette.grey[800],
        barPercentage: 0.5,
      },
    ],
  };

  const yTicks = {
    min: 0,
    padding: 20,
    color: theme.palette.grey[900],
    font: {
      size: 14,
      family: 'Overpass, sans-serif',
    },
  };

  const xTicks = {
    min: 0,
    callback: (value) => '',
    stepSize: 25000,
  };
  const dataLabels = {
    display: true,
    anchor: 'end',
    align: 'end',
    color: theme.palette.grey[900],
    font: {
      family: 'Overpass, sans-serif',
    },
    formatter: (value, context) => {
      return value === 0 ? null : formatAsDollars(value);
    },
  };
  return (
    <Box maxWidth="890px">
      <Typography fontFamily={theme.typography.fontFamily} color={theme.palette.grey[900]}>
        {formatMessage({ id: 'panels.fuel.yearly' }).toUpperCase()}
      </Typography>
      <Typography fontFamily={theme.typography.fontFamily} color={theme.palette.grey[900]}>
        {formatMessage(
          { id: 'panels.fuel.chartSummary' },
          { value: <b>{formatAsDollars(savings)}</b> },
        )}
      </Typography>
      <Box height="300px">
        <StyledBarGraph
          data={chargeData}
          yTicks={yTicks}
          xTicks={xTicks}
          indexAxis={'y'}
          showLegend
          showVerticalGrid
          legendPosition="bottom"
          legendAlign="center"
          stacked={true}
          dataLabels={dataLabels}
          tooltipEnabled={false}
          suggestedXMax={Math.max(fossilFuelCosts, electricCosts) * 1.25}
          gridColor={theme.palette.grey[200]}
        />
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Typography fontFamily={theme.typography.fontFamily} color={theme.palette.grey[900]}>
          {formatMessage({ id: 'panels.fuel.chartFooterA' })}
        </Typography>
        <Link
          to="https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=table_5_06_a"
          external
        >
          {formatMessage({ id: 'panels.fuel.usEnergy' })}
        </Link>
        <Typography fontFamily={theme.typography.fontFamily} color={theme.palette.grey[900]}>
          {formatMessage({ id: 'panels.fuel.chartFooterB' })}
        </Typography>
        <Link to="https://gasprices.aaa.com/" external>
          {formatMessage({ id: 'panels.fuel.aaa' })}
        </Link>
      </Box>
    </Box>
  );
}
