import React from 'react';
import { Grid } from '@mui/material';
import { GMIcon } from '../assets/icons/icons';
import { useInputs } from '@bellawatt/use-inputs';

export default function Header() {
  const { isOnboarded } = useInputs();

  return (
    <Grid
      role="banner"
      container
      height="64px"
      alignItems="center"
      pl="48px"
      position="fixed"
      backgroundColor="white"
      zIndex={2}
      sx={(theme) => ({
        [theme.breakpoints.up('sm')]: { position: 'relative' },
        boxShadow: isOnboarded ? 'none' : '0px 0px 20px rgba(0, 0, 0, 0.1)',
      })}
    >
      <Grid item xs={12}>
        <GMIcon />
      </Grid>
    </Grid>
  );
}
