import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Bar } from 'react-chartjs-2';

export default function StyledBarGraph(props) {
  const theme = useTheme();
  const {
    yTitle,
    data,
    graphTitle,
    showLegend,
    indexAxis,
    stacked,
    gridOptions,
    xTicks,
    yTicks,
    showVerticalGrid = false,
    tooltipLabelFormatter,
    tooltipTitleFormatter,
    tooltipEnabled = true,
    legendPosition,
    legendAlign = 'end',
    dataLabels = { display: false },
    suggestedXMax,
    gridColor,
  } = props;

  const options = {
    responsive: true,
    indexAxis,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: graphTitle,
        color: theme.palette.grey[900],
        align: 'start',
        font: {
          size: 14,
          family: 'Overpass, sans-serif',
        },
      },
      legend: {
        position: legendPosition ?? 'top',
        onClick: null,
        display: showLegend,
        align: legendAlign,
        labels: {
          color: theme.palette.grey[900],
          font: {
            size: 12,
            family: 'Overpass, sans-serif',
          },
          boxWidth: 24,
          boxHeight: 24,
        },
      },
      datalabels: dataLabels,
      tooltip: {
        enabled: tooltipEnabled,
        callbacks: {
          label:
            tooltipLabelFormatter ||
            function (data) {
              return `${data.dataset.label}: ${data.formattedValue}`;
            },
          title:
            tooltipTitleFormatter ||
            function (data) {
              return null;
            },
        },
      },
    },
    scales: {
      x: {
        suggestedMax: suggestedXMax,
        grid: {
          display: showVerticalGrid,
          drawBorder: true,
          drawTicks: true,
          color: gridColor ?? theme.palette.grey[900],
        },
        ticks: xTicks
          ? xTicks
          : {
              padding: 20,
              color: theme.palette.grey[900],
              font: {
                size: 16,
              },
            },
        stacked,
      },
      y: {
        grid: {
          color: gridColor ?? theme.palette.grey[900],
          lineWidth: gridOptions ? gridOptions.lineWidth : 1,
          display: true,
          drawBorder: false,
          drawTicks: true,
          max: 3000000,
        },
        title: {
          display: true,
          text: yTitle,
          color: theme.palette.grey[900],
          font: {
            size: 16,
          },
        },
        ticks: yTicks
          ? yTicks
          : {
              padding: 20,
              color: theme.palette.grey[900],
              font: {
                size: 16,
              },
            },
        stacked,
      },
    },
  };

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Bar aria-label={graphTitle} options={options} data={data} />
    </Box>
  );
}
