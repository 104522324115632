import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { steps } from '../data/onboardingSteps';
import logo from '../assets/images/icons/zappyride.png';

export default function ProgressBar({ stepIndex }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <>
      <Typography
        component="h1"
        sx={{ textAlign: 'center', textTransform: 'uppercase', fontSize: '12px', fontWeight: 600 }}
      >
        {formatMessage({ id: 'onboarding.stepXof' }, { stepNumber: stepIndex + 1 })}
      </Typography>
      <Grid container direction="row" justifyContent="center">
        <Grid container columnSpacing={2} sx={{ margin: '30px 0 0 0' }} justifyContent="center">
          {steps.map((step, i) => (
            <Grid item xs={2} key={step}>
              <Box
                height="4px"
                sx={{
                  borderRadius: '5px',
                  backgroundColor:
                    stepIndex >= i ? theme.palette.primary.main : theme.palette.grey[500],
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item md={5} xs={0} />
        <Grid item md={3} xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent={{ xs: 'center', md: 'flex-end' }}
            sx={{ margin: '12px 0 0 0', color: 'grey.900', fontSize: '12px' }}
          >
            <span style={{ marginRight: '5px' }}>powered by </span>
            <img height="18px" src={logo} alt="zappy ride logo" />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
