import { useInputs } from '@bellawatt/use-inputs';
import { useEffect } from 'react';
import { post } from './api';
import VehicleSets from '../calculations/VehicleSets';

const getPayload = ({
  vehicleSets,
  organizationType,
  zipCode,
  powerSupplier,
  state,
  isPublicEntity,
  isTaxExempt,
  lifespanYears,
}) => {
  const chargers = VehicleSets.allChargers(vehicleSets);

  const isTransitParkingFacility = vehicleSets.some(
    ({ replacementVehicle }) => replacementVehicle.formFactor === 'transit_bus',
  );

  return {
    project: {
      project_type: 'fleets',
      locationEnum: isTransitParkingFacility ? 'transit_parking_facility' : 'workplace_facility',
      years_of_operation: lifespanYears,

      organization: {
        organization_type: organizationType,
        is_public: isPublicEntity,
        is_tax_exempt: isTaxExempt,
      },

      site: {
        address: {
          postcode: zipCode,
          region: state,
          power_supplier: {
            name: powerSupplier,
          },
        },
        in_a_dac: false,
      },

      chargers: chargers.map(({ charger, chargerId, vehicleCount }) => ({
        count: vehicleCount,
        identifier: chargerId,
        is_open_to_public: false,
        is_networked: true,
        details: {
          type: 'charger',
          is_energy_star_certified: true,
          purchase_price: charger?.price,
          charger_power_level: charger?.portKw,
          number_of_connections: charger?.ports,
          charger_type: charger?.chargerType,
        },
      })),

      vehicles: vehicleSets.map(({ replacementVehicle, vehicleCount }) => ({
        count: vehicleCount,
        type: 'vehicle',
        details: {
          type: 'vehicle',
          subtype: replacementVehicle.subtype,
          make: replacementVehicle.make || 'Custom',
          model: replacementVehicle.model,
          trim: replacementVehicle.trim,
          fuel: replacementVehicle.fuel || 'bev',
          weight_class: replacementVehicle.weightClass,
          model_year: replacementVehicle.year,
          vehicle_type: replacementVehicle.type,
          form_factor:
            replacementVehicle.formFactor === 'Crossover' ? 'SUV' : replacementVehicle.formFactor,
          msrp: replacementVehicle.msrp,
          purchase_price: replacementVehicle.msrp,
          lift_capacity: replacementVehicle.liftCapacity,
          battery_capacity: replacementVehicle.batteryCapacityInKwh,
          replaces_ice_vehicle: true,
          identifier: replacementVehicle.id,
          lease_length: null,
          eligible_for_ca_rebate: false,
          gvwr: replacementVehicle.gvwr,
          years_of_operation: lifespanYears,
        },
      })),
    },
  };
};

export default function useApiEvaluatedIncentiveData() {
  const inputs = useInputs();
  const {
    apisLoading,
    loading: inputsLoading,
    apiIncentives: keyedIncentives,
    isMultipleSites,
    setInput,
    ...restOfInputs
  } = inputs;

  const loading = inputsLoading || apisLoading?.incentives;

  const payload = loading ? {} : getPayload(restOfInputs);

  useEffect(() => {
    if (loading) return;

    if (isMultipleSites) {
      setInput({
        apisLoading: {
          ...apisLoading,
          evaluatedIncentives: false,
        },
        apiEvaluatedIncentives: [],
      });
    }

    setInput({
      apisLoading: {
        ...apisLoading,
        evaluatedIncentives: true,
      },
    });

    post('/project', payload)
      .then((response) => {
        const evaluatedIncentives = response.data.evaluations.incentives;
        const combinedIncentives = evaluatedIncentives
          .filter(({ handle }) =>
            keyedIncentives.find(({ incentive_handle: keyedHandle }) => handle === keyedHandle),
          )
          .map((incentive) => ({
            ...incentive,
            ...keyedIncentives.find(
              ({ incentive_handle: keyedHandle }) => incentive.handle === keyedHandle,
            ),
          }));

        setInput({
          apisLoading: { ...apisLoading, evaluatedIncentives: false },
          apiEvaluatedIncentives: combinedIncentives,
        });
      })
      .catch((err) => {
        console.error(err);
        setInput({
          apisLoading: { ...apisLoading, evaluatedIncentives: false },
        });
      });
    // adding setInput to deps causes the effect to run every render
    // re-run effect when project data changes, or new /incentives data comes in

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(payload), JSON.stringify(keyedIncentives)]);
}
