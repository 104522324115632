import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDisplay } from './DisplayProvider';
import Overview from '../tabs/overview/Overview';
import Financials from '../tabs/financials/Financials';
import Fuel from '../tabs/fuel/Fuel';
import Incentives from '../tabs/incentives/Incentives';
import Comparison from '../tabs/comparison/Comparison';
// import Emissions from '../tabs/emissions/Emissions';

const sections = [
  <Overview />,
  <Financials />,
  <Fuel />,
  <Incentives />,
  <Comparison />,
  //   <Emissions />,
];

export default function ContentPrint() {
  const { mode, setMode } = useDisplay();

  useEffect(() => {
    setMode('print');

    return () => {
      setMode('screen');
    };
    // We only want mount and unmount here otherwise infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timeout;

    if (mode === 'print') {
      //   Delay gives time for charts to render
      timeout = setTimeout(() => window.print(), 5000);
    }

    return () => clearTimeout(timeout);
  }, [mode]);

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          minHeight: '64px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></Box>
      {sections.map((section, index) => (
        <Box sx={{ breakAfter: 'page' }} key={index}>
          {section}
        </Box>
      ))}
    </Box>
  );
}
