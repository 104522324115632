// Source: https://docs.google.com/spreadsheets/d/1o8ydfXcQ6B6Mtk_EagQECyZ2LXQICQFbT2WAtkXboXI/edit#gid=1078164887

const CHARGERS = [
  {
    id: 'DCFC_1.4kW',
    ports: 1,
    price: 1000,
    estimatedRebate: 0,
    portKw: 1.4,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Level%201%20charger-01.png',
  },
  {
    id: 'level_2-7.7',
    price: 1000,
    estimatedRebate: 0,
    ports: 1,
    portKw: 7.7,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 7.7KW.png',
  },
  {
    id: 'level_2-9.6',
    price: 1200,
    estimatedRebate: 0,
    ports: 1,
    portKw: 9.6,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 9.6KW-01.png',
  },
  {
    id: 'level_2-11.5',
    price: 1500,
    estimatedRebate: 0,
    ports: 1,
    portKw: 11.5,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 11.5KW-01.png',
  },
  {
    id: 'level_2-19.2',
    price: 2600,
    estimatedRebate: 0,
    ports: 1,
    portKw: 19.2,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 19.2KW-01.png',
  },
  {
    id: 'level_2-24',
    price: 12000,
    estimatedRebate: 0,
    ports: 1,
    portKw: 24,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 19.2KW-01.png',
  },

  {
    id: 'dc_fast_charging-50',
    price: 27800,
    estimatedRebate: 0,
    ports: 1,
    portKw: 50,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 50 KW Single port charger-01.png',
  },
  {
    id: 'dc_fast_charging-100',
    price: 59000,
    estimatedRebate: 0,
    ports: 1,
    portKw: 100,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 100 KW dual port charger-01.png',
  },
  {
    id: 'dc_fast_charging-120',
    price: 71000,
    estimatedRebate: 0,
    ports: 1,
    portKw: 120,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 120 KW dual port charger-01.png',
  },
  {
    id: 'dc_fast_charging-160',
    price: 95000,
    estimatedRebate: 0,
    ports: 1,
    portKw: 160,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 160 KW dual port charger-01.png',
  },
  {
    id: 'dc_fast_charging-175',
    price: 104000,
    estimatedRebate: 0,
    ports: 1,
    portKw: 175,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DC 175kW Singal Port--01.png',
  },
  {
    id: 'dc_fast_charging-500',
    price: 305000,
    estimatedRebate: 0,
    ports: 1,
    portKw: 500,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DCFC 6 port 500kW-01.png',
  },
];

export default CHARGERS;
