import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { YearlyFuelCostChart } from './YearlyFuelCostChart';
import { useIntl } from 'react-intl';

import FossilFleetTable from './FossilFleetTable';
import ElectricFleetTable from './ElectricFleetTable';
import { useInputs } from '@bellawatt/use-inputs';
import VehicleSet from '../../calculations/VehicleSet';
import getDefaultRate from '../../calculations/rates/defaultRate';
import sum from 'lodash/sum';
import Link from '../../components/Link';

export default function Fuel() {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const { vehicleSets, electricityPrice, dieselPrice, gasolinePrice } = useInputs();

  const rate = getDefaultRate(electricityPrice);

  const electricCosts = vehicleSets.reduce(
    (acc, vehicleSet) => ({
      ...acc,
      [vehicleSet.id]: VehicleSet.annualElectricityCosts(vehicleSet, rate),
    }),
    {},
  );

  const fossilFuelCosts = vehicleSets.reduce(
    (acc, vehicleSet) => ({
      ...acc,
      [vehicleSet.id]: VehicleSet.annualFossilFuelCosts(vehicleSet, {
        dieselPrice: dieselPrice,
        gasolinePrice: gasolinePrice,
      }),
    }),
    {},
  );

  const totalElectricCosts = sum(Object.values(electricCosts));
  const totalFossilFuelCosts = sum(Object.values(fossilFuelCosts));
  const savings = Math.max(totalFossilFuelCosts - totalElectricCosts, 0);

  return (
    <Grid container>
      <Grid item xs={12}>
        <YearlyFuelCostChart
          electricCosts={totalElectricCosts}
          fossilFuelCosts={totalFossilFuelCosts}
          savings={savings}
        />
      </Grid>
      <Grid item xs={12} mt="100px">
        <FossilFleetTable fossilFuelCosts={fossilFuelCosts} />
        <Typography fontSize="12px" fontFamily={theme.typography.fontFamily} mt={'6px'}>
          {formatMessage({ id: 'panels.fuel.gasPriceSource' })}
          <Link to="https://gasprices.aaa.com/" external>
            AAA
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} mt="53px">
        <ElectricFleetTable electricCosts={electricCosts} />
        <Typography fontSize="12px" fontFamily={theme.typography.fontFamily} mt={'6px'}>
          {formatMessage({ id: 'panels.fuel.electricPriceSource' })}
          <Link
            to="https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=table_5_06_a"
            external
          >
            {formatMessage({ id: 'panels.fuel.usEnergy' })}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}
