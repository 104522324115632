import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Line } from 'react-chartjs-2';

export default function StyledLineGraph(props) {
  const theme = useTheme();
  const {
    yTitle,
    data,
    graphTitle,
    showLegend,
    xTicks,
    yTicks,
    suggestedYMax,
    tooltipEnabled,
    tooltipLabelFormatter,
    tooltipTitleFormatter,
  } = props;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        text: graphTitle,
        color: theme.palette.grey[900],
        align: 'start',
        font: {
          size: 14,
          family: 'Overpass, sans-serif',
        },
      },
      legend: {
        onClick: null,
        display: showLegend,
        align: 'end',
        labels: {
          color: theme.palette.grey[900],
          font: {
            size: 12,
            family: 'Overpass, sans-serif',
          },
          boxWidth: 24,
          boxHeight: 24,
        },
      },
      tooltip: {
        enabled: tooltipEnabled,
        callbacks: {
          label:
            tooltipLabelFormatter ||
            function (data) {
              return `${data.dataset.label}: ${data.formattedValue}`;
            },
          title:
            tooltipTitleFormatter ||
            function (data) {
              return null;
            },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: true,
        },
        ticks: xTicks
          ? xTicks
          : {
              padding: 20,
              color: theme.palette.grey[900],
              font: {
                size: 16,
              },
            },
      },
      y: {
        suggestedMax: suggestedYMax,
        beginAtZero: true,
        grid: {
          display: true,
          drawBorder: false,
          drawTicks: true,
          color: theme.palette.grey[900],
        },
        title: {
          display: false,
          align: 'end',
          text: yTitle,
          color: theme.palette.grey[900],
          font: {
            size: 16,
          },
        },
        ticks: yTicks
          ? yTicks
          : {
              padding: 20,
              color: theme.palette.grey[900],
              font: {
                size: 16,
              },
            },
      },
    },
  };

  return (
    <Box textAlign="center" sx={{ height: '400px' }}>
      <Line aria-label={graphTitle} options={options} data={data} />
    </Box>
  );
}
