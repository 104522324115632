import React, { useState } from 'react';
import { useTheme, Box, Typography } from '@mui/material';
import { ElectricFleetIcon, GasCanIcon } from '../../assets/icons/icons';
import { useIntl } from 'react-intl';
import VehicleCatalog from '../../dialogs/vehicleCatalog/VehicleCatalog';
import EditVehicleSet from '../../dialogs/editVehicleSetDialog/EditVehicleSet';

export default function VehicleHeader({ vehicleSet, isElectric }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const [openCatalog, setOpenCatalog] = useState(false);
  const [openEditVehicle, setOpenEditVehicle] = useState(false);

  const { vehicleCount } = vehicleSet;
  const vehicle = isElectric ? vehicleSet.replacementVehicle : vehicleSet.existingVehicle;
  const { name, make, model, year, imageUrl } = vehicle || {};

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        fontSize="16px"
        fontFamily={theme.typography.fontFamily}
        mb={1}
      >
        <Box height="20px" width="19px">
          {isElectric ? <ElectricFleetIcon /> : <GasCanIcon color={theme.palette.grey[900]} />}
        </Box>

        <Box pl="16px">
          {formatMessage({
            id: isElectric ? 'panels.overview.electric' : 'panels.overview.fossilAlt',
          })}
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Box width="100px" mr={2} flexShrink={0}>
          <img src={imageUrl} alt={name} style={{ width: '100%', height: 'auto' }} />
        </Box>
        <Typography fontFamily={theme.typography.fontFamily}>
          {isElectric ? `${vehicleCount}X ${name}` : `${vehicleCount}X ${year} ${make} ${model}`}
        </Typography>
        <VehicleCatalog
          isOpen={openCatalog}
          onClose={() => setOpenCatalog(false)}
          vehicleSet={vehicleSet}
        />
        <EditVehicleSet
          isOpen={openEditVehicle}
          onClose={() => setOpenEditVehicle(false)}
          vehicleSet={vehicleSet}
        />
      </Box>
    </Box>
  );
}
