import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { CirclesIcon, VehicleIcon, GraphIcon } from '../../assets/icons/icons';

const IconWithText = ({ icon, text }) => {
  return (
    <Grid container justifyContent="center" alignItems="center" direction="column">
      <Grid item sx={{ height: '75px' }}>
        {icon}
      </Grid>
      <Grid item>
        <Typography sx={{ fontWeight: '600' }}>{text}</Typography>
      </Grid>
    </Grid>
  );
};

export default function GettingStartedStep() {
  const { formatMessage } = useIntl();

  return (
    <Grid container>
      <Grid item md={4} xs={12}>
        <IconWithText
          icon={<CirclesIcon />}
          text={formatMessage({ id: 'onboarding.gettingStarted.tellUsOrganization' })}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <IconWithText
          icon={<VehicleIcon />}
          text={formatMessage({ id: 'onboarding.gettingStarted.tellUsVehicle' })}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <IconWithText
          icon={<GraphIcon />}
          text={formatMessage({ id: 'onboarding.gettingStarted.seeSavings' })}
        />
      </Grid>
    </Grid>
  );
}
