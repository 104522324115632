import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import ReactGA from 'react-ga';
import { steps } from '../data/onboardingSteps';
import ProgressBar from './ProgressBar';
import OnboardingHeader from './OnboardingHeader';
import OnboardingContent from './OnboardingContent';

export default function OnboardingLayout() {
  const { onboardingStep } = useInputs();
  const stepIndex = steps.findIndex((step) => step === onboardingStep);

  useEffect(() => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Onboarding progress',
      value: onboardingStep,
    });
  }, [onboardingStep]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      pt={3}
      sx={{
        maxWidth: '1068px',
        margin: '0 auto',
      }}
    >
      <Grid item xs={8} mt="50px">
        <ProgressBar stepIndex={stepIndex} />
      </Grid>
      <Grid item mt="20px" xs={12}>
        <OnboardingHeader />
      </Grid>
      <Grid item mt="75px" xs={12}>
        <OnboardingContent />
      </Grid>
    </Grid>
  );
}
