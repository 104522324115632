import { LoadProfile, RateCalculator } from '@bellawatt/electric-rate-engine';
import BatterySchedule from './BatterySchedule';
import { YEAR } from '../utils/assumptions';
import CHARGERS from '../data/CHARGERS';

const MPGeConversionRate = 33.705;

const VehicleSet = {
  annualMiles(vehicleSet, includePersonal = true) {
    const { workdays, workmonths, milesPerWorkday, personalMilesPerWorkday, vehicleCount } =
      vehicleSet;

    const annualMilesPerVehicle =
      (milesPerWorkday + (includePersonal ? personalMilesPerWorkday : 0)) *
      365.25 *
      (workdays.length / 7) *
      (workmonths.length / 12);

    return vehicleCount * annualMilesPerVehicle;
  },

  annualFossilFuelCosts(vehicleSet, { dieselPrice, gasolinePrice }) {
    const { existingVehicle, personalMilesPaidFor } = vehicleSet;

    const gallons =
      this.annualMiles(vehicleSet, personalMilesPaidFor) / existingVehicle.milesPerGallon;

    const priceDictionary = {
      diesel: dieselPrice,
      gas: gasolinePrice,
    };
    const price =
      priceDictionary[existingVehicle.fuel === 'gasoline' ? 'gas' : existingVehicle.fuel];

    return price * gallons;
  },

  annualBatterySchedule: BatterySchedule.annualBatterySchedule,

  averageWorkdayBatterySchedule: BatterySchedule.averageWorkdayBatterySchedule,

  annualLoadProfile(vehicleSet, includePersonal = true) {
    const rawData = this.annualBatterySchedule(vehicleSet, includePersonal).map(
      ({ chargedKwh }) => chargedKwh,
    );

    return new LoadProfile(rawData, { year: YEAR });
  },

  annualElectricityCosts(vehicleSet, rate) {
    const loadProfile = this.annualLoadProfile(vehicleSet, vehicleSet.personalMilesPaidFor);
    const rateCalculator = new RateCalculator({
      ...rate,
      loadProfile,
    });

    return rateCalculator.annualCost();
  },

  minimumStateOfCharge(vehicleSet) {
    const batterySchedule = this.annualBatterySchedule(vehicleSet);

    const minCharge = Math.min(...batterySchedule.map((x) => x.startKwh));
    const maxCharge = Math.max(...batterySchedule.map((x) => x.startKwh));
    return Math.ceil((minCharge / maxCharge) * 100);
  },

  recommendedCharger(vehicleSet, { start, finish }) {
    const {
      replacementVehicle: { efficiencyInMilesPerKwh },
      milesPerWorkday,
      personalMilesPerWorkday,
    } = vehicleSet;
    const efficiencyInKwhPerMile = 1 / efficiencyInMilesPerKwh;

    // kWh required to fully charge on a workday
    const fullChargeKwh = (milesPerWorkday + personalMilesPerWorkday) * efficiencyInKwhPerMile;
    const chargingHours = start < finish ? finish - start : 23 - start + finish;
    const minKw = fullChargeKwh / chargingHours;

    // make sure an acceptable charger exists
    const chargerKws = CHARGERS.map(({ portKw }) => portKw);
    const maxChargerKw = Math.max(...chargerKws);
    if (minKw > maxChargerKw) return null;

    // find charger with lowest kW above the minimum kW, if it exists
    const recommendedKw = Math.min(...chargerKws.filter((portKw) => portKw >= minKw));
    return CHARGERS.find(({ portKw }) => portKw >= minKw && portKw === recommendedKw);
  },

  mpge(vehicleSet) {
    return Math.round(vehicleSet.efficiencyInMilesPerKwh * MPGeConversionRate);
  },
};

export default VehicleSet;
