import { Box, Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import Content from './Content';
import GetStartedButton from './GetStartedButton';
import { useIntl } from 'react-intl';
import screen from '../../assets/images/homepage/screen.png';
import mobileScreen from '../../assets/images/homepage/mobileScreen.png';

const BoldQuestion = ({ header, copy }) => {
  const theme = useTheme();
  return (
    <Box mt={2} sx={(theme) => ({ mb: 4, [theme.breakpoints.up('sm')]: { mb: 6, mx: 2 } })}>
      <Typography fontFamily={theme.typography.fontFamily} fontSize="20px" lineHeight="26px">
        {header}
      </Typography>
      <Typography
        fontSize="16px"
        lineHeight="23px"
        color={(theme) => theme.palette.grey[700]}
        sx={(theme) => ({ mt: 1, [theme.breakpoints.up('sm')]: { mt: 3 } })}
      >
        {copy}
      </Typography>
    </Box>
  );
};

export default function Summary() {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <>
      <Content>
        <Box textAlign="center">
          <Typography
            fontFamily={theme.typography.fontFamily}
            sx={{
              fontSize: '24px',
              [theme.breakpoints.up('sm')]: { fontSize: '42px', letterSpacing: '-0.4px' },
            }}
          >
            {formatMessage({ id: 'homepage.aboutYourFleet.header' })}
          </Typography>
          <Typography
            m="10px auto"
            mb={4}
            maxWidth="600px"
            color={theme.palette.grey[700]}
            sx={{ fontSize: '18px', [theme.breakpoints.up('sm')]: { fontSize: '20px' } }}
          >
            {formatMessage({ id: 'homepage.aboutYourFleet.subheader' })}
          </Typography>
          <GetStartedButton />
        </Box>
      </Content>
      <Content>
        <Grid container>
          {!isLargeScreen && (
            <Grid item xs={12} mx={2} mb={2}>
              <img src={mobileScreen} width="100%" height="100%" alt="preview" />
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <BoldQuestion
              header={formatMessage({ id: 'homepage.matchVehicle.header' })}
              copy={formatMessage({ id: 'homepage.matchVehicle.subheader' })}
            />
            <BoldQuestion
              header={formatMessage({ id: 'homepage.findIncentive.header' })}
              copy={formatMessage({ id: 'homepage.findIncentive.subheader' })}
            />
          </Grid>
          {isLargeScreen && (
            <Grid item md={6}>
              <img src={screen} width="100%" height="100%" alt="preview" />
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <BoldQuestion
              header={formatMessage({ id: 'homepage.forcastCosts.header' })}
              copy={formatMessage({ id: 'homepage.forcastCosts.subheader' })}
            />
            <BoldQuestion
              header={formatMessage({ id: 'homepage.compareGasoline.header' })}
              copy={formatMessage({ id: 'homepage.compareGasoline.subheader' })}
            />
          </Grid>
        </Grid>
      </Content>
    </>
  );
}
