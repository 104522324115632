import { Box } from '@mui/material';

export default function Content({ children, width = '1068px', margin = '0 auto', ...rest }) {
  return (
    <Box
      pt={8}
      sx={(theme) => ({
        maxWidth: width,
        margin: margin,
        px: 3,
        [theme.breakpoints.up('sm')]: { px: 0 },
      })}
      {...rest}
    >
      {children}
    </Box>
  );
}
