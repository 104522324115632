import React, { useState } from 'react';
import { Grid, Typography, Box, Checkbox } from '@mui/material';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import { useTheme } from '@mui/material';
import organizationTypes from '../../data/organizationTypes';
import DropdownInput from '../../components/DropdownInput';
import TextFieldInput from '../../components/TextFieldInput';

export default function GeneralInformationStep() {
  const { formatMessage } = useIntl();
  const { organizationType, zipCode, isMultipleSites, setInput } = useInputs();
  const theme = useTheme();
  const [zipError, setZipError] = useState(null);

  const setErrorState = () => {
    if (!/[0-9]{5}/.test(zipCode) && !isMultipleSites) {
      setZipError(formatMessage({ id: 'onboarding.generalInformation.zipcodeError' }));
    } else {
      setZipError();
    }
  };

  Object.entries({}).reduce((acc, [key, value]) => {
    if (typeof value !== 'function') {
      return {
        ...acc,
        [key]: value,
      };
    }
    return acc;
  }, {});

  return (
    <Grid container>
      <Grid item xs={1} />
      <Grid item md={5} xs={12} sx={{ padding: '15px' }}>
        <DropdownInput
          id="organization-type-input"
          label={formatMessage({ id: 'onboarding.generalInformation.organizationType.label' })}
          options={organizationTypes.map(({ id, translationKey }) => ({
            value: id,
            children: formatMessage({ id: translationKey }),
          }))}
          value={organizationType}
          onChange={(e) => setInput({ organizationType: e.target.value })}
          tooltip={formatMessage({ id: 'onboarding.generalInformation.organizationType.tooltip' })}
          mb={4}
        />
        <Typography
          sx={{
            color: theme.palette.grey[700],
            marginTop: '-30px',
            marginLeft: '10px',
            fontSize: '12px',
          }}
        >
          {formatMessage({ id: 'onboarding.generalInformation.seeDefinition' })}
        </Typography>
        {organizationType && (
          <Box mt="24px">
            <Typography fontSize="14px" fontWeight="700">
              {formatMessage({
                id: organizationTypes.find((type) => type.id === organizationType)?.translationKey,
              })}
            </Typography>
            <Typography fontSize="14px">
              {formatMessage({
                id: `onboarding.generalInformation.organizationDescriptions.${organizationType}`,
              })}
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid item md={5} xs={12} sx={{ padding: '15px' }}>
        {!isMultipleSites && (
          <TextFieldInput
            value={zipCode}
            error={zipError}
            label={formatMessage({ id: 'onboarding.generalInformation.zipCode.label' })}
            tooltip={formatMessage({ id: 'onboarding.generalInformation.zipCode.tooltip' })}
            onChange={(e) => setInput({ zipCode: e.target.value })}
            onBlur={() => setErrorState()}
            inputProps={{ maxLength: 5 }}
            maxLength={5}
            type="number"
            mb={4}
          />
        )}

        <Typography
          sx={{
            color: theme.palette.grey[700],
            marginTop: '-30px',
            marginLeft: '10px',
            fontSize: '12px',
          }}
        >
          {formatMessage({ id: 'onboarding.generalInformation.vehiclesLocated' })}
        </Typography>

        <Box display="flex" mt="50px">
          <Checkbox
            id="multipleSites"
            checked={isMultipleSites}
            onChange={(e) => setInput({ isMultipleSites: e.target.checked })}
            inputProps={{ 'aria-label': 'Purchasing for multiple sites' }}
            disableRipple
            sx={{ marginRight: '1rem', alignSelf: 'flex-start' }}
          />
          <Box>
            <label htmlFor="multipleSites">
              <Typography fontFamily={theme.typography.fontFamily} fontSize="0.875rem">
                {formatMessage({
                  id: 'onboarding.generalInformation.purchasingMultipleSites.header',
                })}
              </Typography>
              <Typography fontSize="0.75rem">
                {formatMessage({
                  id: 'onboarding.generalInformation.purchasingMultipleSites.subHeader',
                })}
              </Typography>
            </label>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
}
