import React from 'react';
import hero from '../../assets/images/homepage/hero.png';
import mobileHero from '../../assets/images/homepage/mobileHero.png';
import zappyrideLogo from '../../assets/images/homepage/ZappyrideLogo-white.png';
import { Box, Typography, useTheme } from '@mui/material';
import Content from './Content';
import { useIntl } from 'react-intl';
import GetStartedButton from './GetStartedButton';

export default function Hero() {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Box
      height="75vh"
      sx={{
        background: `linear-gradient(190deg, white, transparent), url(${mobileHero}) top center no-repeat`,
        backgroundSize: 'contain',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          background: `url(${hero}) top center no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '800px',
        },
      }}
    >
      <Content width="900px" className="hero-content">
        <Typography
          component="h1"
          fontFamily={theme.typography.fontFamily}
          color={theme.palette.primary.main}
          fontWeight={600}
          mb={1}
          sx={{
            fontSize: '48px',
            lineHeight: '48px',
            pt: 4,
            [theme.breakpoints.up('sm')]: { fontSize: '84px', lineHeight: '86px', pt: 0 },
          }}
        >
          {formatMessage({ id: 'homepage.hero.headerPrimary' })}
        </Typography>
        <Box
          width="100%"
          display="flex"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: { flexWrap: 'wrap', marginBottom: '25px' },
          }}
        >
          <Typography
            fontFamily={theme.typography.fontFamily}
            fontSize="22px"
            color={'#FFFFFF'}
            maxWidth="600px"
            mb={3}
            mt={4}
            sx={{ fontSize: '18px', [theme.breakpoints.up('sm')]: { fontSize: '22px' } }}
            style={{ marginTop: '0px' }}
          >
            {formatMessage({ id: 'homepage.hero.subheader' })}
          </Typography>
          <img src={zappyrideLogo} alt="Zappyride" height="25px" width="151px" />
        </Box>
        <Box
          width="100%"
          display="flex"
          sx={{
            justifyContent: 'center',
          }}
        >
          <GetStartedButton />
        </Box>
      </Content>
    </Box>
  );
}
