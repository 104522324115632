import { get } from '../utils/api';
import {
  transformApiElectricVehicle,
  transformApiFossilVehicle,
  transformApiCharger,
} from '../utils/conversions';
import vehicleMappings from '../data/vehicleMappings';
import sampleApiElectricVehicles from '../data/sampleData/sampleApiElectricVehicles';
import sampleApiFossilVehicles from '../data/sampleData/sampleApiFossilVehicles';
import { v4 as uuidv4 } from 'uuid';
import sampleApincentives from '../data/sampleData/sampleApiIncentives';
import sampleApiEvaluatedIncentives from '../data/sampleData/sampleApiEvaluatedIncentives';

export const DEFAULT_CHARGING_WINDOW = {
  start: 0,
  finish: 5,
  chargingApproach: 'Max',
  chargerId: 'level_2-7.7',
  isGeneric: true,
  id: uuidv4(),
};

// used after DEFAULT_CHARGING_WINDOW has already been added
export const SECONDARY_DEFAULT_CHARGING_WINDOW = {
  start: 12,
  finish: 14,
  chargingApproach: 'Max',
  chargerId: 'dc_fast_charging-50',
  isGeneric: true,
  id: uuidv4(),
};

export const BASE_CUSTOM_FOSSIL_FUEL_VEHICLE = {
  fromApi: true,
  id: uuidv4(),
  category: 'Custom',
  name: 'Generic Passenger Sedan',
  type: 'Passenger',
  subtype: 'Sedan',
  formFactor: 'Custom',
  make: '',
  year: '2023',
  model: '',
  trim: 'Custom',
  imageUrl:
    'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Sedan.png',
  rangeInMiles: '',
  milesPerGallon: ' ',
  fuel: 'gasoline',
  msrp: ' ',
};

export const DEFAULT_VEHICLE_SET = {
  id: uuidv4(),
  name: 'Vehicle Set #1',
  existingVehicleId: 'Chevrolet_Equinox_FWD_GAS_2023',
  replacementVehicleId: vehicleMappings['Chevrolet_Equinox_FWD_GAS_2023'].ev_id,
  vehicleCount: 3,
  milesPerWorkday: 0,
  personalMilesPerWorkday: 0,
  personalMilesPaidFor: false,
  hoursPerWorkday: 0,
  workdays: [],
  workmonths: [],
  chargingWindows: [DEFAULT_CHARGING_WINDOW],
  cargoWeight: 1000,
  overrideSpecs: {},
  customFossilFuelVehicle: BASE_CUSTOM_FOSSIL_FUEL_VEHICLE,
  hasUserSpecifiedCustomVehicle: false,
};

const DEFAULT_ZIP_CODE = '94108';
const DEFAULT_STATE = 'California';
const DEFAULT_POWER_SUPPLIER = 'PG&E';

const defaults = {
  vehicleSets: [DEFAULT_VEHICLE_SET],
  selectedTab: 0,
  startYear: new Date().getFullYear() + 1,
  lifespanYears: 5,
  organizationType: 'medium_or_large_businesses',
  isPublicEntity: false,
  isTaxExempt: false,
  isMultipleSites: false,
  zipCode: DEFAULT_ZIP_CODE,
  state: DEFAULT_STATE,
  powerSupplier: DEFAULT_POWER_SUPPLIER,
  isInADac: false,
  btmConstructionCosts: 0,
  dieselPrice: 5.02,
  gasolinePrice: 4.74,
  electricityPrice: 0.2376,
  electricityEmissions: 224.5,
  isOnboarded: false,
  isCustomChargerChosen: false,
  onboardingStep: 'gettingStarted',
  isResetting: false,
};

// ONLY USED FOR TESTS
export const getTestingDefaults = () => ({
  ...defaults,
  apiElectricVehicles: sampleApiElectricVehicles,
  apiFossilVehicles: sampleApiFossilVehicles,
  apiIncentives: sampleApincentives,
  apiEvaluatedIncentives: sampleApiEvaluatedIncentives,
  apisLoading: {
    incentives: false,
    evaluatedIncentives: false,
    location: false,
  },
});

const getElectricVehicles = async () => {
  const response = await get(`/commercial/vehicles?postcode=${DEFAULT_ZIP_CODE}`);
  const evIds = Object.keys(vehicleMappings)
    .map((vehicle) => vehicleMappings[vehicle])
    .map((veh) => veh.ev_id);

  return response.data.vehicles
    .filter(({ handle }) => Object.values(evIds).includes(handle))
    .map(transformApiElectricVehicle);
};

const getFossilVehicles = async () => {
  const response = await get(`/commercial/vehicles?postcode=${DEFAULT_ZIP_CODE}&fuel_type=gas`);
  return response.data.vehicles
    .filter(({ handle }) => vehicleMappings[handle] !== undefined)
    .map(transformApiFossilVehicle);
};

const getChargers = async () => {
  const response = await get(`/commercial/chargers?postcode=${DEFAULT_ZIP_CODE}`);
  return response.data.chargers.map(transformApiCharger);
};

const getIncentives = async () => {
  const response = await get(
    `/commercial/incentives?postcode=${DEFAULT_ZIP_CODE}&project_type=fleets`,
  );
  return response.data.incentives;
};

const getDefaultsWithApi = async () => {
  const [apiElectricVehicles, apiFossilVehicles, apiChargers, apiIncentives] = await Promise.all([
    getElectricVehicles(),
    getFossilVehicles(),
    getChargers(),
    getIncentives(),
  ]);

  const apiLocationData = (await get(`/location?postcode=${DEFAULT_ZIP_CODE}`)).data.location;
  const dieselPrice = apiLocationData.regional_fuel_cost[0].diesel;
  const gasolinePrice = apiLocationData.regional_fuel_cost[0].gasoline;
  const electricityPrice = apiLocationData.regional_fuel_cost[0].commercial_electricity / 100;
  const electricityEmissions = apiLocationData.regional_electricity[0].emissions.emissions_co2;

  // use default vehicles unless they weren't returned from the API
  const existingVehicle1 =
    apiFossilVehicles.find(({ id }) => id === DEFAULT_VEHICLE_SET.existingVehicleId) ||
    apiFossilVehicles[0];

  // use vehicles from API response
  const vehicleSets = [
    {
      ...DEFAULT_VEHICLE_SET,
      existingVehicleId: existingVehicle1?.id,
      replacementVehicleId: vehicleMappings[existingVehicle1?.id]?.ev_id,
    },
  ];

  return {
    ...defaults,
    vehicleSets,
    dieselPrice,
    gasolinePrice,
    electricityPrice,
    electricityEmissions,
    apiChargers,
    apiElectricVehicles,
    apiFossilVehicles,
    apiIncentives,
    assumedIncentives: 0,
    apiEvaluatedIncentives: [],
    apisLoading: {
      incentives: false,
      evaluatedIncentives: false,
      location: false,
    },
  };
};

export default getDefaultsWithApi;
