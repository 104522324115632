import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import ButtonInput from '../../components/ButtonInput';

export default function WarningButtonRow({ onCancel, onDelete }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <Box
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      sx={(theme) => ({ [theme.breakpoints.up('sm')]: { display: 'flex' } })}
    >
      <Typography
        fontFamily={theme.typography.fontFamily}
        fontSize="14px"
        sx={(theme) => ({
          textAlign: 'center',
          [theme.breakpoints.up('sm')]: { textAlign: 'left' },
        })}
      >
        <b>{formatMessage({ id: 'inputs.vehicleSet.editSetDialog.warningBold' })}</b>
        {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.warning' })}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-evenly"
        sx={(theme) => ({
          width: '100%',
          mt: 1,
          [theme.breakpoints.up('sm')]: { width: '50%', mt: 0 },
        })}
      >
        <ButtonInput variant="outlined" onClick={onCancel}>
          {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.cancel' })}
        </ButtonInput>
        <ButtonInput variant="warning" onClick={() => onDelete()}>
          {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.yes' })}
        </ButtonInput>
      </Box>
    </Box>
  );
}
