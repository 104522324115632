import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Grid,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useInputs } from '@bellawatt/use-inputs';
import { ComplexVehicleCard, SimpleVehicleCard } from './VehicleCard';
import { ActionButtons } from './ActionButtons';
import { cloneDeep } from 'lodash';
import ContentHeader from './ContentHeader';
import Filters from './Filters';
import CatalogHeader from './CatalogHeader';
import { omitComputedData } from '../../utils/computedData';

const sortConfig = {
  rangeDesc: {
    field: 'range',
    method: 'desc',
  },
  rangeAsc: {
    field: 'range',
    method: 'asc',
  },
};

const useVehicles = (filters, sort) => {
  const { apiElectricVehicles } = useInputs();

  const filteredVehicles = apiElectricVehicles
    .filter(
      ({ type, subtype, make }) =>
        (filters.type === 'All' || filters.type === type) &&
        (filters.subtype === 'All' || filters.subtype === subtype) &&
        (filters.make === 'All' || filters.make === make),
    )
    .filter(({ range }) => {
      if (filters.range === 250) {
        return range <= 250;
      } else if (filters.range === 500) {
        return range > 250 && range <= 500;
      } else if (filters.range === 501) {
        return range > 500;
      }
      return true;
    });

  const { field, method } = sort;

  switch (method) {
    case 'asc':
      filteredVehicles.sort((a, b) => a[field] - b[field]);
      break;
    case 'desc':
      filteredVehicles.sort((a, b) => b[field] - a[field]);
      break;
    default:
  }

  return filteredVehicles;
};

export default function VehicleCatalog({ isOpen, onClose, vehicleSet }) {
  const { apiElectricVehicles, setInput, vehicleSets } = useInputs();

  const [selectedVehicle, setSelectedVehicle] = useState(vehicleSet.replacementVehicle);
  const [sortOrder, setSortOrder] = useState('rangeAsc');
  const [activeFilters, setActiveFilters] = useState({
    type: vehicleSet.existingVehicle.type,
    subtype: 'All',
    make: 'All',
    range: 'All',
  });

  const handleSave = () => {
    const index = vehicleSets.findIndex((set) => set.id === vehicleSet.id);
    const newSets = cloneDeep(vehicleSets);

    newSets[index] = {
      ...vehicleSet,
      replacementVehicleId: selectedVehicle.id,
    };

    setInput({ vehicleSets: omitComputedData(newSets) });
    onClose();
  };

  const handleClose = () => {
    onClose();
    setActiveFilters({
      type: vehicleSet.existingVehicle.type,
      subtype: 'All',
      make: 'All',
      range: 'All',
    });
  };

  const handleSelect = (vehicle) => {
    setSelectedVehicle(vehicle);
  };

  const updateFilter = (name, value) => setActiveFilters({ ...activeFilters, [name]: value });
  const vehicles = useVehicles(activeFilters, sortConfig[sortOrder]);

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={isLargeScreen ? 'lg' : 'sm'}
      onBackdropClick={onClose}
    >
      <DialogTitle>
        <CatalogHeader onClose={onClose} vehicleSet={vehicleSet} />
      </DialogTitle>
      <DialogContent px="48px">
        <Grid container>
          <Grid item xs={12} md={3}>
            <SimpleVehicleCard vehicleSet={vehicleSet} />
            <Filters vehicleSet={vehicleSet} onChange={updateFilter} />
          </Grid>
          <Grid item xs={12} md={9}>
            <ContentHeader
              subset={vehicles.length}
              total={apiElectricVehicles.length}
              sortOrder={sortOrder}
              changeSort={setSortOrder}
            />
            <Box
              display="flex"
              flexWrap="wrap"
              columnGap={3}
              rowGap={3}
              minHeight="680px"
              sx={(theme) => ({
                justifyContent: 'center',
                [theme.breakpoints.up('sm')]: { justifyContent: 'start', pl: 3 },
              })}
            >
              {vehicles.map((vehicle) => (
                <ComplexVehicleCard
                  key={vehicle.id}
                  vehicleSet={vehicleSet}
                  replacementOption={vehicle}
                  selectedVehicle={selectedVehicle}
                  onSelect={() => handleSelect(vehicle)}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ActionButtons onClose={handleClose} onSave={handleSave} />
      </DialogActions>
    </Dialog>
  );
}
