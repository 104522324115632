import React from 'react';
import { QuestionMarkIcon } from '../assets/icons/icons';
import { Box, Tooltip as MuiTooltip } from '@mui/material';
import { useTheme } from '@mui/material';

export default function ToolTip({ content, children }) {
  const theme = useTheme();
  return (
    <MuiTooltip
      title={content}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            color: 'black',
            backgroundColor: 'white',
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: '2px',
            fontFamily: 'Overpass, sans-serif',
            fontSize: '14px',
            px: 2,
            py: 1,
          },
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        {children}
        <Box flexShrink={0} ml={2} mt={'2px'}>
          <QuestionMarkIcon />
        </Box>
      </Box>
    </MuiTooltip>
  );
}
