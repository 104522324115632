/* eslint-disable import/prefer-default-export */

import { formatChargerName } from './formatters';

const typeConversions = {
  // Passenger vehicles:
  Sedan: 'Passenger',
  Crossover: 'Passenger',
  SUV: 'Passenger',
  Hatchback: 'Passenger',
  Coupe: 'Passenger',
  Minivan: 'Passenger',
  'Station Wagon': 'Passenger',

  // Non-Passenger vehicles
  transit_bus: 'Transit Bus',
  step_van: 'Step Van',
  pickup_truck: 'Pick-up Truck',
  school_bus: 'School Bus',
  cutaway_chassis: 'Cutaway Chassis',
  utility_truck: 'Utility Truck',
  box_truck: 'Box Truck',
  refuse_truck: 'Refuse Truck',
  trailer_truck: 'Trailer Truck',
  terminal_tractor: 'Terminal Tractor',
  motor_coach: 'Motor Coach',
  forklift: 'Forklift',
  cargo_van: 'Cargo Van',
  shuttle_bus: 'Shuttle Bus',
  specialty: 'Specialty',
  bev_drive_system: 'BEV Drive System',
  tru: 'TRU',
};

const passengerSubtypeConversions = {
  Sedan: 'Sedan',
  Crossover: 'SUV', // There is no crossover archetype, so including this conversion keeps all crossovers from the API within the app
  SUV: 'SUV',
  Hatchback: 'Hatchback',
  Coupe: 'Coupe',
  Minivan: 'Minivan',
  'Station Wagon': 'Station Wagon',
};

const efficiencyUnitConversions = {
  'kwh per mile': 1,
  'kwh per 100 miles': 100,
};

export const transformApiElectricVehicle = (vehicle) => ({
  fromApi: true,
  id: vehicle.handle,
  category: vehicle.form_factor === 'tru' ? 'TRU' : vehicle.is_offroad ? 'Off-Road' : 'On-Road',
  name: `${vehicle.model_year} ${vehicle.make} ${vehicle.model} ${vehicle.trim}`.trim(),
  type: typeConversions[vehicle.form_factor] || vehicle.form_factor,
  subtype:
    vehicle.type === 'Passenger'
      ? passengerSubtypeConversions[vehicle.form_factor]
      : vehicle.subtype,
  formFactor: vehicle.form_factor,
  make: vehicle.make,
  year: vehicle.model_year,
  model: vehicle.model,
  duty: vehicle.duty,
  trim: vehicle.trim,
  imageUrl: vehicle.images.find(({ url_thumbnail }) => url_thumbnail)?.url_full || null,
  rangeInMiles: vehicle.total_range,
  rangeInHours: vehicle.total_range,
  range: vehicle.total_range,
  fuel: vehicle.fuel,
  liftCapacity: vehicle.lift_capacity || 0,
  batteryCapacityInKwh: vehicle.battery_capacity,
  weightClass: vehicle.weight_class,
  msrp: vehicle.msrp,
  msrpIsEstimated: vehicle.is_estimated_price,
  efficiencyInMilesPerKwh:
    efficiencyUnitConversions[vehicle.electric_efficiency_unit] / vehicle.electric_efficiency,
  maintenanceCostPerMile: vehicle.is_offroad ? 0 : vehicle.maintenance_cost,
  maintenanceCostPerHour: vehicle.is_offroad ? vehicle.maintenance_cost : 0,
  headroom: vehicle.handle.includes('Chevrolet_Bolt_EUV_BEV')
    ? 40
    : vehicle.handle.includes('Chevrolet_Bolt__BEV')
    ? 40.1
    : 0,
  legroom:
    vehicle.handle.includes('Chevrolet_Bolt_EUV_BEV') ||
    vehicle.handle.includes('Chevrolet_Bolt__BEV')
      ? 44.3
      : 0,
  cargo: vehicle.cargo_volume_max,
  seats: vehicle.seats_max,
  gvwr: vehicle.gvwr,
});

export const transformApiFossilVehicle = (vehicle) => ({
  fromApi: true,
  id: vehicle.handle,
  category: vehicle.form_factor === 'tru' ? 'TRU' : vehicle.is_offroad ? 'Off-Road' : 'On-Road',
  name: `${vehicle.make} ${vehicle.model} ${vehicle.trim || ''}`.trim(),
  type: typeConversions[vehicle.form_factor] || vehicle.form_factor,
  subtype:
    vehicle.type === 'Passenger'
      ? passengerSubtypeConversions[vehicle.form_factor]
      : vehicle.subtype,
  formFactor: vehicle.form_factor,
  make: vehicle.make,
  year: vehicle.model_year,
  model: vehicle.model,
  trim: vehicle.trim,
  imageUrl: vehicle.images.find(({ url_thumbnail }) => url_thumbnail)?.url_full || null,
  rangeInMiles: vehicle.total_range,
  milesPerGallon: vehicle.fossil_fuel_efficiency,
  fuel: vehicle.fuel,
  msrp: vehicle.msrp,
  weightClass: vehicle.weight_class,
});

export const transformApiCharger = (charger) => ({
  id: charger.charger_id,
  name: formatChargerName(charger),
  chargerType: charger.current === 'AC' ? 'level_2' : 'dc_fast_charging',
  cellularSupport: charger.cellular_support,
  bluetooth: charger.bluetooth,
  wifi: charger.wifi,
  ethernet: charger.ethernet,
  ports: charger.num_of_ports,
  cordLength: charger.cord_length,
  price: charger.price,
  priceIsEstimated: charger.is_estimated_price,
  ocppVersion: charger.ocpp_version,
  oscpVersion: charger.oscp_version,
  plugTypes: charger.plug_types,
  make: charger.make,
  model: charger.model,
  modelVariant: charger.model_variant,
  imageUrl: charger.img,
  portKw: charger.current === 'AC' ? charger.kw_ac : charger.kw_dc,
  formFactor: charger.form_factor,
  outlets: charger.home_outlet,
  vehicleToGrid: charger.vgi_capable,
  warranty: charger.warranty,
  certifications: charger.certifications,
});
