import ButtonInput from '../components/ButtonInput';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import getDefaultsWithApi from '../use-inputs/getDefaults';

export default function ResetInputs() {
  const { formatMessage } = useIntl();
  const { setInput } = useInputs();

  const handleResetInputs = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(formatMessage({ id: 'inputs.confirmResetInputs' }))) {
      setInput({ isResetting: 'true' });
      getDefaultsWithApi().then((data) => setInput({ ...data, isResetting: false }));
    }
  };

  return (
    <Box mt={4} width="100%" textAlign="center">
      <ButtonInput variant="contained" onClick={handleResetInputs}>
        {formatMessage({ id: 'inputs.reset' })}
      </ButtonInput>
    </Box>
  );
}
