import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import CollapsibleInput from './CollapsibleInput';
import DropdownInput from '../components/DropdownInput';
import booleanOptions from '../data/booleanOptions';
import { useInputs } from '@bellawatt/use-inputs';
import TextFieldInput from '../components/TextFieldInput';

import Link from '../components/Link';

const StyledLink = ({ href, children }) => {
  return (
    <Link to={href} external variant="tooltip" target="_blank" textTransform="none">
      {children}
    </Link>
  );
};
export default function SiteInputs() {
  const { formatMessage } = useIntl();
  const [touched, setTouched] = useState({});
  const touchField = (fieldName) => setTouched({ ...touched, [fieldName]: true });
  const { isMultipleSites, zipCode, isInADac, setInput } = useInputs();

  return (
    <CollapsibleInput title={formatMessage({ id: 'inputs.location.title' })}>
      {!isMultipleSites && (
        <TextFieldInput
          value={zipCode}
          label={formatMessage({ id: 'inputs.location.zipCode.label' })}
          tooltip={
            <FormattedMessage
              id="inputs.location.zipCode.tooltip"
              values={{
                linkAAA: <StyledLink href="https://gasprices.aaa.com/">AAA</StyledLink>,
                linkUSIA: (
                  <StyledLink href="https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=table_5_06_a">
                    US Energy Information Administration
                  </StyledLink>
                ),
              }}
            />
          }
          onBlur={(e) => {
            touchField('zipCode');
            setInput({ zipCode: e.target.value });
          }}
          inputProps={{ maxLength: 5 }}
          maxLength={5}
          type="number"
          mb={4}
          error={
            touched.zipCode &&
            !/[0-9]{5}/.test(zipCode) &&
            formatMessage({ id: 'inputs.location.zipCode.formatError' })
          }
        />
      )}

      <DropdownInput
        id="in-a-dac-input"
        label={formatMessage({ id: 'inputs.location.inDac.label' })}
        options={booleanOptions.map(({ id, translationKey }) => ({
          value: id,
          children: formatMessage({ id: translationKey }),
        }))}
        value={isInADac ? 'yes' : 'no'}
        onChange={(e) => setInput({ isInADac: e.target.value === 'yes' })}
        tooltip={formatMessage({ id: 'inputs.location.inDac.tooltip' })}
        mb={4}
      />

      {/* Removed Temporarily Per Client Request */}
      {/* <TextFieldInput
        value={btmConstructionCosts}
        label={formatMessage({ id: 'inputs.location.btmConstructionCosts.label' })}
        tooltip={formatMessage({ id: 'inputs.location.btmConstructionCosts.tooltip' })}
        onBlur={(e) => setInput({ btmConstructionCosts: parseInt(e.target.value) })}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        type="number"
        mb={4}
      /> */}
    </CollapsibleInput>
  );
}
