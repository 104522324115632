import CHARGERS from '../data/CHARGERS';
import { BASE_CUSTOM_FOSSIL_FUEL_VEHICLE } from './getDefaults';
const computed = {
  vehicleSets: ({ vehicleSets, apiFossilVehicles, apiElectricVehicles, apiChargers }) =>
    vehicleSets.map((vehicleSet) => {
      const hasUserSpecifiedCustomVehicle = vehicleSet.hasUserSpecifiedCustomVehicle;

      const selectedVehicle = hasUserSpecifiedCustomVehicle
        ? { ...BASE_CUSTOM_FOSSIL_FUEL_VEHICLE, ...vehicleSet.customFossilFuelVehicle }
        : apiFossilVehicles.find((v) => v.id === vehicleSet.existingVehicleId);

      const overrideSpecs = vehicleSet.overrideSpecs;
      const customFossilFuelVehicle = vehicleSet.customFossilFuelVehicle;
      const overrides = vehicleSet.overrideSpecs || {};
      const isNewSet = vehicleSet?.isNewSet;

      const existingVehicle = hasUserSpecifiedCustomVehicle
        ? selectedVehicle
        : Object.keys(selectedVehicle || {}).reduce((next, key) => {
            if (overrides && overrides[key]) {
              return { ...next, [key]: overrides[key] };
            } else {
              return { ...next, [key]: selectedVehicle[key] };
            }
          }, {});

      let replacementVehicle = apiElectricVehicles.find(
        (v) => v.id === vehicleSet.replacementVehicleId,
      );

      if (!replacementVehicle) {
        // Model year changes in the data can cause the replacement vehicle to be missing
        const idWithoutYear = vehicleSet?.replacementVehicleId?.slice(0, -5); // remove year from id
        replacementVehicle = apiElectricVehicles.find((v) => v.id.includes(idWithoutYear));
        if (!replacementVehicle) {
          // use first EV if no match found
          replacementVehicle = apiElectricVehicles[0];
        }
      }

      const chargingWindows = vehicleSet.chargingWindows.map((chargingWindow) => ({
        ...chargingWindow,
        charger: chargingWindow.isGeneric
          ? CHARGERS.find(({ id }) => id === chargingWindow.chargerId)
          : apiChargers.find(({ id }) => id === chargingWindow.chargerId),
      }));

      return {
        ...vehicleSet,
        chargingWindows,
        existingVehicle,
        replacementVehicle,
        customFossilFuelVehicle,
        overrideSpecs,
        hasUserSpecifiedCustomVehicle,
        isNewSet,
      };
    }),
};

export default computed;
