import React from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import { PeopleIcon } from '../assets/icons/icons';

import { useIntl } from 'react-intl';

export default function FindYourRep() {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Link
      href="https://www.gmfleet.com/contact"
      target="_blank"
      rel="noreferrer"
      color={theme.palette.grey[900]}
    >
      <Box
        position="fixed"
        bottom="15px"
        right="5%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        zIndex={3}
        sx={(theme) => ({ [theme.breakpoints.up('sm')]: { bottom: '80px' } })}
      >
        <Box
          boxShadow="0px 0px 16px rgba(0, 0, 0, 0.13)"
          borderRadius="24px"
          backgroundColor="white"
          width="115px"
          height="40px"
          pt="2px"
          mb="12px"
          textAlign="center"
        >
          <Typography fontSize="12px" fontFamily={theme.typography.fontFamily}>
            {formatMessage({ id: 'findYourRep' })}
          </Typography>
        </Box>
        <PeopleIcon />
      </Box>
    </Link>
  );
}
