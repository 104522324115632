import React, { useState, useEffect } from 'react';
import { Grid, Typography, useTheme, Checkbox, FormControlLabel } from '@mui/material';
import { useIntl } from 'react-intl';
import TextFieldInput from '../../components/TextFieldInput';
import OperationsDaysMonths from './OperationsDaysMonths';
import WarningMessage from '../../components/WarningMessage';

export default function VehicleUsage({ vehicleSet, onChangeVehicleSet, isEditing, setDisabled }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { personalMilesPerWorkday, personalMilesPaidFor, milesPerWorkday } = vehicleSet;
  const [error, setError] = useState([]);
  const [includePersonal, setIncludePersonal] = useState(personalMilesPerWorkday > 0);
  const [touchedFields, setTouchedFields] = useState({});

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    handleError();
  }, [vehicleSet]);
  /* eslint-disable react-hooks/exhaustive-deps */

  let formattedMessage = formatMessage({ id: 'inputs.vehicleSet.editSetDialog.alertExceed' });

  const handleIncludePersonal = () => {
    if (includePersonal) {
      onChangeVehicleSet({ personalMilesPerWorkday: 0 });
    }
    setIncludePersonal(!includePersonal);
  };
  const checks = {
    milesPerWorkday: () => milesPerWorkday && milesPerWorkday > 0,
    personalMilesPerWorkday: () => personalMilesPerWorkday && personalMilesPerWorkday > 0,
    workdays: () => vehicleSet.workdays.length > 0,
    workmonths: () => vehicleSet.workmonths.length > 0,
  };
  const isValid = () => {
    const requiredFields = ['milesPerWorkday', 'workdays', 'workmonths'];
    if (includePersonal) {
      requiredFields.push('personalMilesPerWorkday');
    }
    return requiredFields.every((field) => (isEditing || touchedFields[field]) && checks[field]());
  };
  const handleError = () => {
    const errorMessages = {
      milesPerWorkday: 'Please specify a valid miles amount',
      personalMilesPerWorkday: 'Please specify a valid miles amount',
      workdays: 'Please select at least one day',
      workmonths: 'Please select at least one month',
    };
    for (const field of Object.keys(checks)) {
      const checkResult = checks[field]();
      if (touchedFields?.[field] && !checkResult) {
        if (!error.some((el) => el.type === field)) {
          setError((prevArr) => [
            ...prevArr,
            {
              type: field,
              msg: errorMessages[field],
            },
          ]);
        }
        setDisabled(true);
        return;
      }
    }

    if (isValid()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    setError([]);
  };

  const getError = (field) => {
    const index = error.findIndex((e) => e.type === field);
    return index >= 0 ? error[index].msg : false;
  };
  const handleChange = (value) => {
    if (Object.keys(value).length < 2) {
      setTouchedFields({ ...touchedFields, [Object.keys(value)[0]]: true });
    }
    onChangeVehicleSet({ ...vehicleSet, [Object.keys(value)[0]]: value[Object.keys(value)[0]] });
  };
  return (
    <Grid container justifyContent="space-between" px={2}>
      <Grid item md={5} xs={12} mb={4}>
        <TextFieldInput
          value={milesPerWorkday}
          label={formatMessage({ id: 'inputs.vehicleSet.editSetDialog.avgMiles' })}
          onBlur={(e) => handleChange({ milesPerWorkday: parseInt(e.target.value) })}
          type="number"
          error={getError('milesPerWorkday')}
        />

        <FormControlLabel
          control={
            <Checkbox
              style={{
                color: includePersonal ? theme.palette.common.blue[700] : theme.palette.grey[500],
              }}
              checked={includePersonal}
              onClick={handleIncludePersonal}
            />
          }
          label={
            <Typography fontSize="12px">
              {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.includePersonal' })}
            </Typography>
          }
        />
      </Grid>

      <Grid item xs={12} md={5}>
        {includePersonal && (
          <>
            <TextFieldInput
              value={personalMilesPerWorkday}
              label={formatMessage({ id: 'inputs.vehicleSet.editSetDialog.avgPersonal' })}
              onBlur={(e) => {
                setTouchedFields({ ...touchedFields, personalMilesPerWorkday: true });
                handleChange({ personalMilesPerWorkday: parseInt(e.target.value) });
              }}
              type="number"
              error={getError('personalMilesPerWorkday')}
            />

            <FormControlLabel
              control={
                <Checkbox
                  style={{
                    color: personalMilesPaidFor
                      ? theme.palette.common.blue[700]
                      : theme.palette.grey[500],
                  }}
                  checked={personalMilesPaidFor}
                  onClick={() =>
                    onChangeVehicleSet({
                      ...vehicleSet,
                      personalMilesPaidFor: !personalMilesPaidFor,
                    })
                  }
                />
              }
              label={
                <Typography fontSize="12px">
                  {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.personalPaidFor' })}
                </Typography>
              }
            />
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        <OperationsDaysMonths
          vehicleSet={vehicleSet}
          onChangeVehicleSet={onChangeVehicleSet}
          handleError={handleError}
          setTouchedFields={setTouchedFields}
          getError={getError}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <WarningMessage message={formattedMessage} variant="info2" fullWidth />
        </Grid>
      </Grid>
    </Grid>
  );
}
