import React from 'react';
import { Grid, useTheme, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { DAYS_ABBREVIATED } from '../../data/timePeriods';
import { isEqual } from 'lodash';
import { formatHours } from '../../utils/formatters';
import { formatGenericChargerName, formatChargerName } from '../../utils/formatters';

export default function ExpectedWorkload({ vehicleSet, isElectric }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { workdays, milesPerWorkday, chargingWindows } = vehicleSet;

  const filteredWorkdays = DAYS_ABBREVIATED.sort((a, b) => a.position - b.position).filter(
    ({ itemIndex, translationKey }) => workdays.includes(itemIndex) && translationKey,
  );

  const days = filteredWorkdays.map(({ translationKey }) => formatMessage({ id: translationKey }));

  return (
    <Grid
      container
      justifyContent="start"
      alignItems="center"
      border={` 2px solid ${theme.palette.grey[400]}`}
      borderRadius="4px"
      padding={4}
    >
      <Grid item xs={12}>
        <Typography fontFamily={theme.typography.fontFamily} height={16} pb={1}>
          {formatMessage({ id: 'panels.overview.workload' })}
        </Typography>
      </Grid>
      <Grid item xs={12} py={3} sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}>
        <Typography fontSize="12px" fontFamily={theme.typography.fontFamily} pb="12px">
          {formatMessage({ id: 'panels.overview.workdays' }).toUpperCase()}
        </Typography>
        <Typography fontSize="14px" fontFamily={theme.typography.fontFamily}>
          {isEqual(workdays, [1, 2, 3, 4, 5])
            ? formatMessage({ id: 'panels.overview.weekdays' })
            : days.join(', ')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        py={3}
        sx={{ borderBottom: isElectric ? `1px solid ${theme.palette.grey[300]}` : '' }}
      >
        <Typography fontSize="12px" fontFamily={theme.typography.fontFamily} pb="12px">
          {formatMessage({ id: 'panels.overview.typicalWorkday' }).toUpperCase()}
        </Typography>
        <Typography fontSize="14px" fontFamily={theme.typography.fontFamily}>
          {`${milesPerWorkday} ${formatMessage({ id: 'panels.overview.miles' })}`}
        </Typography>
      </Grid>
      {isElectric && (
        <Grid item xs={12} py={3}>
          <Typography fontSize="12px" fontFamily={theme.typography.fontFamily} pb="12px">
            {chargingWindows?.length > 1
              ? formatMessage({ id: 'panels.overview.windows' }).toUpperCase()
              : formatMessage({ id: 'panels.overview.window' }).toUpperCase()}
          </Typography>
          {chargingWindows.map(({ start, finish, charger, id, isGeneric }) => {
            return (
              <div key={id}>
                <Typography
                  fontSize="12px"
                  fontFamily={theme.typography.fontFamily}
                >{`${formatHours(start)} - ${formatHours(finish)}`}</Typography>
                {isGeneric ? (
                  <Typography fontSize="12px" fontFamily={theme.typography.fontFamily}>
                    {formatGenericChargerName({
                      type: formatMessage({ id: `chargerTypes.${charger.chargerType}` }),
                      portKw: charger.portKw,
                    })}
                  </Typography>
                ) : (
                  <Typography fontSize="12px" fontFamily={theme.typography.fontFamily}>
                    {formatChargerName(charger)}
                  </Typography>
                )}
              </div>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
}
