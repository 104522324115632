import React, { useState } from 'react';
import { Grid, Typography, Box, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import EditVehicleOverrides from '../../../dialogs/editVehicleSetDialog/EditVehicleOverrides';

const VehicleSpec = ({ field, value, disclaimer, secondLineValue }) => {
  const theme = useTheme();

  return (
    <Grid>
      <Grid container display="flex" justifyContent="space-between" mb="16px" ml="auto">
        <Typography
          fontSize="12px"
          fontWeight="400"
          maxWidth="100px"
          fontFamily={theme.typography.fontFamily}
          mr="20px"
          mb="0"
          color={theme.palette.grey[700]}
        >
          {field}
        </Typography>
        <Box textAlign="end">
          <Typography fontSize="12px" fontWeight="600">
            {value}
          </Typography>
          {secondLineValue && (
            <Typography fontSize="12px" fontWeight="600">
              {secondLineValue}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid container mr="0" ml="auto" flexWrap="nowrap">
        <Grid item>
          {disclaimer && (
            <Box>
              <Typography
                fontSize="12px"
                fontWeight="400"
                color="grey.700"
                ml="px"
                lineHeight="14px"
                fontStyle={'italic'}
              >
                {' '}
                {disclaimer}{' '}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function StatsCard({
  vehicleSet,
  onChangeVehicleSet,
  replacementVehicle,
  selectedVehicle,
}) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const [overrideDialog, setOverrideDialog] = useState(false);

  return (
    <Grid
      container
      height="200px"
      alignItems="flex-start"
      justifyContent="center"
      spacing={4}
      sx={{
        border: `1px solid ${theme.palette.grey[600]}`,
        padding: '14px',
        width: '100%',
        marginLeft: '0',
      }}
    >
      <Grid container xs={5.7} alignItems="center" alignSelf="center">
        <Grid item xs={4}>
          <img src={selectedVehicle.imageUrl} alt={selectedVehicle.name} width="100%" />
          <Typography fontSize="14px" color={theme.palette.grey[700]}>
            {selectedVehicle.year} {selectedVehicle.name}
          </Typography>
        </Grid>
        <Grid item xs={8} paddingLeft="20px">
          <Typography
            fontFamily={theme.typography.fontFamily}
            fontSize="14px"
            fontWeight="700"
            color={theme.palette.grey[700]}
            mb="16px"
          >
            {formatMessage({ id: 'onboarding.selectVehicle.current' })}
          </Typography>
          <Box>
            <VehicleSpec
              field={formatMessage({ id: 'onboarding.selectVehicle.range' })}
              value={`${selectedVehicle.rangeInMiles} ${formatMessage({
                id: 'onboarding.selectVehicle.miles',
              })}`}
            />
            <VehicleSpec
              field={formatMessage({ id: 'onboarding.selectVehicle.fuel' })}
              value={`Gasoline`}
            />
          </Box>
          <Typography fontSize="12px" color={theme.palette.grey[700]} fontStyle="italic">
            {formatMessage({ id: 'disclaimer.vehicleData' })}
          </Typography>
        </Grid>
      </Grid>
      <Box mx="24px" height="100%" borderLeft={`1px solid ${theme.palette.grey[300]}`} />
      <Grid container xs={5.7} alignItems="center" alignSelf="center">
        <Grid item xs={4}>
          <img src={replacementVehicle.imageUrl} alt={replacementVehicle.name} width="100%" />
          <Typography fontSize="14px" color={theme.palette.grey[700]}>
            {replacementVehicle.name}
          </Typography>
        </Grid>
        <Grid item xs={8} paddingLeft="20px">
          <Typography
            fontFamily={theme.typography.fontFamily}
            fontSize="14px"
            fontWeight="700"
            color={theme.palette.grey[700]}
            mb="16px"
          >
            {formatMessage({ id: 'onboarding.selectVehicle.equivalent' })}
          </Typography>
          <VehicleSpec
            field={formatMessage({ id: 'onboarding.selectVehicle.epaEstimatedRange' })}
            value={`${replacementVehicle.rangeInMiles} ${formatMessage({
              id: 'onboarding.selectVehicle.miles',
            })}`}
          />
          <VehicleSpec
            //      field={formatMessage({ id: 'onboarding.selectVehicle.capacity' })}
            //      value={`${replacementVehicle.batteryCapacityInKwh} kWh`}
            disclaimer={formatMessage({ id: 'onboarding.selectVehicle.disclaimer.ev' })}
          />
        </Grid>
      </Grid>

      <EditVehicleOverrides
        vehicleSet={vehicleSet}
        isOpen={overrideDialog}
        onClose={() => setOverrideDialog(false)}
        onChangeVehicleSet={onChangeVehicleSet}
      />
    </Grid>
  );
}
