import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import CostsOverTimeChart from './CostsOverTimeChart';
import CostsBarChart from './CostsBarChart';
import CostsWaterfallChart from './CostsWaterfallChart';
import SavingsTable from './SavingsTable';
import StyledToggleButton from '../../inputs/StyledToggleButton';
import SummaryBoxes from './SummaryBoxes';
import VehicleSets from '../../calculations/VehicleSets';
import getDefaultRate from '../../calculations/rates/defaultRate';
import { useDisplay } from '../../components/DisplayProvider';

export default function Financials() {
  const {
    vehicleSets,
    dieselPrice,
    gasolinePrice,
    electricityPrice,
    lifespanYears,
    assumedIncentives,
    btmConstructionCosts,
  } = useInputs();
  const { mode } = useDisplay();

  const [showBarChart, setShowBarChart] = useState('panels.financials.barChart');

  const totalCostsParams = {
    rate: getDefaultRate(electricityPrice),
    dieselPrice,
    gasolinePrice,
    lifespanYears,
    incentives: assumedIncentives,
  };

  const fossilFleetCosts = VehicleSets.totalCosts(vehicleSets, {
    ...totalCostsParams,
    isRetiringFleet: true,
  });

  const evFleetCosts = VehicleSets.totalCosts(vehicleSets, {
    ...totalCostsParams,
    isRetiringFleet: false,
    btmConstructionCosts,
  });

  const toggleChartView = (value) => {
    //prevents both toggles from being unchecked.
    if (value) {
      setShowBarChart(value);
    }
  };

  return (
    <Grid container width="100%" justifyContent="space-between">
      <Grid item xs={12}>
        <SummaryBoxes fossilFleetCosts={fossilFleetCosts} evFleetCosts={evFleetCosts} />
      </Grid>
      <Grid item xs={12} mt="60px">
        <CostsOverTimeChart fossilFleetCosts={fossilFleetCosts} evFleetCosts={evFleetCosts} />
      </Grid>
      <Grid item xs={12} textAlign="end" mt="80px">
        <StyledToggleButton
          title1={'panels.financials.barChart'}
          title2={'panels.financials.waterfall'}
          selected={showBarChart}
          toggleSelected={toggleChartView}
        />
      </Grid>
      <Grid item xs={12}>
        {showBarChart === 'panels.financials.barChart' || mode === 'print' ? (
          <CostsBarChart fossilFleetCosts={fossilFleetCosts} evFleetCosts={evFleetCosts} />
        ) : (
          <CostsWaterfallChart fossilFleetCosts={fossilFleetCosts} evFleetCosts={evFleetCosts} />
        )}
      </Grid>
      {/* For normal app use, the Waterfall chart needs to be in the same Grid item, but for Print formatting, it needs to be separate */}
      {mode === 'print' && (
        <Grid item xs={12} mt="400px">
          <CostsWaterfallChart fossilFleetCosts={fossilFleetCosts} evFleetCosts={evFleetCosts} />
        </Grid>
      )}
      <Grid item xs={12} mt="50px" mb="100px">
        <SavingsTable
          fossilFleetCosts={fossilFleetCosts}
          evFleetCosts={evFleetCosts}
          lifespanYears={lifespanYears}
        />
      </Grid>
    </Grid>
  );
}
