import { useInputs } from '@bellawatt/use-inputs';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import CollapsibleInput from './CollapsibleInput';
import EditVehicleSetCard from './EditVehicleSetCard';
import EditVehicleSet from '../dialogs/editVehicleSetDialog/EditVehicleSet';
import cloneDeep from 'lodash/cloneDeep';
import { DEFAULT_VEHICLE_SET } from '../use-inputs/getDefaults';
import { v4 as uuidv4 } from 'uuid';
import { addComputedData, omitComputedData } from '../utils/computedData';
import ButtonInput from '../components/ButtonInput';

export default function VehicleSetInputs() {
  const { formatMessage } = useIntl();

  const { vehicleSets, setInput, apiFossilVehicles, apiChargers, apiElectricVehicles } =
    useInputs();
  const [openEdit, setOpenEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [workingVehicleSet, setWorkingVehicleSet] = useState(null);

  const handleClick = (vehicleSet) => {
    setWorkingVehicleSet(vehicleSet);
    setIsEditing(true);
    setOpenEdit(true);
  };

  const handleAddNewSet = () => {
    const newSet = addComputedData({
      vehicleSets: [
        {
          ...DEFAULT_VEHICLE_SET,
          id: uuidv4(),
          name: `Vehicle Set #${vehicleSets.length + 1}`,
          hasUserSpecifiedCustomVehicle: true,
          isNewSet: true,
        },
      ],
      apiFossilVehicles,
      apiElectricVehicles,
      apiChargers,
    }).vehicleSets[0];

    //console.log("vehicleset",newSet);
    const setsArr = cloneDeep(vehicleSets);
    setsArr.push(newSet);

    setWorkingVehicleSet(cloneDeep(newSet));
    //We need the vehicle data for the working set, but it should not get saved to state
    setInput({ vehicleSets: omitComputedData(setsArr) });
    setOpenEdit(true);
  };

  return (
    <CollapsibleInput title={formatMessage({ id: 'inputs.vehicleSet.title' })} defaultOpen>
      {vehicleSets.map((vehicleSet, index) => (
        <EditVehicleSetCard
          key={`${vehicleSet.name}-${index}`}
          vehicleSet={vehicleSet}
          onClick={() => handleClick(vehicleSet)}
        />
      ))}
      <ButtonInput onClick={handleAddNewSet} variant="icon">
        {formatMessage({ id: 'inputs.vehicleSet.addNewButton' })}
      </ButtonInput>
      <EditVehicleSet
        isOpen={openEdit}
        onClose={() => {
          setOpenEdit(false);
          setIsEditing(false);
        }}
        isEditing={isEditing}
        vehicleSet={workingVehicleSet}
      />
    </CollapsibleInput>
  );
}
