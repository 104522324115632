import React, { useEffect } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import { useState } from 'react';
import DropdownInput from '../../components/DropdownInput';
import VehicleOverview from './VehicleOverview';
import VehicleInfoCard from './VehicleInfoCard';
import VehicleSet from '../../calculations/VehicleSet';
import getDefaultRate from '../../calculations/rates/defaultRate';
import { formatAsCents, formatAsDollars, formatAsThousands } from '../../utils/formatters';
import { useDisplay } from '../../components/DisplayProvider';

export default function Comparison() {
  const { formatMessage } = useIntl();
  const { vehicleSets, dieselPrice, gasolinePrice, electricityPrice, zipCode, isMultipleSites } =
    useInputs();

  const theme = useTheme();

  const { mode } = useDisplay();

  const [selectedVehicleSet, setSelectedVehicleSet] = useState(vehicleSets[0]);

  useEffect(() => {
    //When a vehicleSet is updated in left-inputs, we need to check and update if it happens to be the selectedSet
    const index = vehicleSets.findIndex((set) => set.id === selectedVehicleSet.id);

    setSelectedVehicleSet(vehicleSets[index]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleSets]);

  const handleVehicleChange = (e) => {
    const vehicleSet = vehicleSets.find(({ id }) => id === e.target.value) || vehicleSets[0];
    setSelectedVehicleSet(vehicleSet);
  };

  const annualMiles = VehicleSet.annualMiles(selectedVehicleSet);

  const annualFossilFuelCost = VehicleSet.annualFossilFuelCosts(selectedVehicleSet, {
    dieselPrice,
    gasolinePrice,
  });
  const annualElectricityCost = VehicleSet.annualElectricityCosts(
    selectedVehicleSet,
    getDefaultRate(electricityPrice),
  );
  const fossilFuelCostPerMile = annualFossilFuelCost / annualMiles;
  const electricityCostPerMile = annualElectricityCost / annualMiles;

  const fossilFuelInfo = [
    {
      title: formatMessage({ id: 'panels.comparison.fuel.annualFossilCost' }),
      value: formatAsDollars(annualFossilFuelCost),
    },
    {
      title: formatMessage({ id: 'panels.comparison.fuel.fossilPerMileCost' }),
      value: formatAsCents(fossilFuelCostPerMile),
    },
    {
      title: formatMessage({ id: 'panels.comparison.fuel.milesPerYear' }),
      value: formatAsThousands(annualMiles),
      disclaimer: formatMessage(
        { id: 'panels.comparison.fuel.milesPerYearDisclaimer' },
        {
          BREAK: (
            <>
              <br />
            </>
          ),
        },
      ),
    },
    {
      title: formatMessage({ id: 'panels.comparison.fuel.fossilAssumptions' }),
      value: formatMessage(
        {
          id: `panels.comparison.fuel.${
            isMultipleSites ? 'avgFossilFuelCostNational' : 'avgFossilFuelCost'
          }`,
        },
        {
          fuel_type: <span>gasoline</span>,
          state_zip: <span>{zipCode}</span>,
          link: (
            <a target="_blank" rel="noreferrer" href="https://gasprices.aaa.com/">
              AAA
            </a>
          ),
        },
      ),
    },
  ];

  const electricFuelInfo = [
    {
      title: formatMessage({ id: 'panels.comparison.fuel.annualElectricCost' }),
      value: formatAsDollars(annualElectricityCost),
    },
    {
      title: formatMessage({ id: 'panels.comparison.fuel.electricPerMileCost' }),
      value: formatAsCents(electricityCostPerMile),
    },
    {
      title: formatMessage({ id: 'panels.comparison.fuel.milesPerYear' }),
      value: formatAsThousands(annualMiles),
      disclaimer: formatMessage(
        { id: 'panels.comparison.fuel.milesPerYearDisclaimerElectric' },
        {
          BREAK: (
            <>
              <br />
            </>
          ),
        },
      ),
    },
    {
      title: formatMessage({ id: 'panels.comparison.fuel.electricAssumptions' }),
      value: formatMessage(
        {
          id: `panels.comparison.fuel.${
            isMultipleSites ? 'avgElectricityCostNational' : 'avgElectricityCost'
          }`,
        },
        {
          state_zip: <span>{zipCode}</span>,
          link: (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=table_5_06_a"
            >
              US Energy Information Administration
            </a>
          ),
        },
      ),
    },
  ];

  const allInfo = [
    {
      header: formatMessage({ id: 'panels.comparison.fuel.header' }),
      fossilInfo: fossilFuelInfo,
      electricInfo: electricFuelInfo,
    },
    {
      header: formatMessage({ id: 'panels.comparison.specs.header' }),
      fossilInfo: [],
      electricInfo: [],
      disclaimer: formatMessage({ id: 'onboarding.selectVehicle.specs' }),
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={4}>
          <Typography fontFamily={theme.typography.fontFamily} sx={{ textTransform: 'uppercase' }}>
            {formatMessage({ id: 'panels.comparison.header' })}
          </Typography>
          <Typography fontSize="0.875rem">
            {formatMessage({ id: 'panels.comparison.subheader' })}
          </Typography>
        </Box>

        <DropdownInput
          id="vehicle-comparison-select"
          label={formatMessage({ id: 'panels.comparison.dropdownLabel' })}
          value={selectedVehicleSet.id}
          options={vehicleSets.map(({ id, existingVehicle: { year, make, model } }) => ({
            value: id,
            children: year + ' ' + make + ' ' + model,
          }))}
          onChange={handleVehicleChange}
          maxWidth={300}
          mb={4}
        />

        <Grid container justifyContent="space-between">
          <Grid item xs={mode === 'print' ? 5 : 12} md={5}>
            <VehicleOverview
              name={`${selectedVehicleSet.existingVehicle.year} ${selectedVehicleSet.existingVehicle.make} ${selectedVehicleSet.existingVehicle.model}`}
              imgUrl={selectedVehicleSet.existingVehicle.imageUrl}
              isElectric={false}
            />
            {allInfo.map(({ header, fossilInfo, disclaimer }) => (
              <Grid container justifyContent="space-between" mb={3} key={header}>
                <Grid item xs={12} md={12}>
                  <VehicleInfoCard header={header} info={fossilInfo} />
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={mode === 'print' ? 5 : 12} md={5}>
            <VehicleOverview
              name={selectedVehicleSet.replacementVehicle.name}
              imgUrl={selectedVehicleSet.replacementVehicle.imageUrl}
              isElectric={true}
            />
            {allInfo.map(({ header, electricInfo, disclaimer }) => (
              <Grid container justifyContent="space-between" mb={3} key={header}>
                <Grid item xs={12} md={12}>
                  <VehicleInfoCard header={header} info={electricInfo} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
