import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import {
  LargeInfoIcon,
  WarningTriangleIcon,
  AlertIconVariant,
  MileageIcon,
} from '../assets/icons/icons';
import { useIntl } from 'react-intl';

export default function WarningMessage({ message, variant = 'warning', fullWidth, padding }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const variants = {
    warning: {
      backgroundColor: theme.palette.common.orange[200],
      icon: <WarningTriangleIcon />,
    },
    info: {
      backgroundColor: theme.palette.grey[200],
      icon: <LargeInfoIcon />,
    },
    info2: {
      backgroundColor: '#E6F5FC',
      icon: <AlertIconVariant />,
    },
    fuel: {
      backgroundColor: '#FDF0E6',
      icon: (
        <Box pl="12px">
          <Box pl="6px">
            <MileageIcon color={'#ED6C02'} />
          </Box>
          <Typography fontFamily={theme.typography.fontFamily} fontSize="15px">
            {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.empty' })}
          </Typography>
        </Box>
      ),
    },
  };

  return (
    <Box
      display="flex"
      justifyContent="start"
      alignItems="center"
      width={fullWidth ? '100%' : '750px'}
      padding={padding ?? 1}
      backgroundColor={variants[variant].backgroundColor}
    >
      <Box mr="14px">{variants[variant].icon}</Box>
      <Typography pl={padding ?? 2} fontSize="14px" textAlign="left" component={'span'}>
        {message}
      </Typography>
    </Box>
  );
}
