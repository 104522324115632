import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import Dialog from '../dialog/Dialog';
import ButtonInput from '../../components/ButtonInput';
import ReactGA from 'react-ga';

export default function ExternalSiteDialog({ isOpen, onCancel, to }) {
  const { formatMessage } = useIntl();

  useEffect(() => {
    isOpen &&
      ReactGA.event({
        category: 'ExternalSiteDialog',
        action: 'Open',
        label: to,
      });
  }, [isOpen, to]);

  const handleCancel = () => {
    ReactGA.event({
      category: 'ExternalSiteDialog',
      action: 'Cancel',
      label: to,
    });
    onCancel();
  };

  return (
    <Dialog
      width="550px"
      isOpen={isOpen}
      onClose={handleCancel}
      title={formatMessage({ id: 'externalSiteDialog.title' })}
      footer={
        <Box
          sx={{
            textDecoration: 'none',
            color: (theme) => theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
          }}
        >
          <ButtonInput link onClick={handleCancel}>
            {formatMessage({ id: 'externalSiteDialog.cancel' })}
          </ButtonInput>
          <a
            href={to}
            onClick={() => {
              ReactGA.event({
                category: 'ExternalSiteDialog',
                action: 'Continue',
                label: to,
              });
            }}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <ButtonInput variant="filled">
              {formatMessage({ id: 'externalSiteDialog.continue' })}
            </ButtonInput>
          </a>
        </Box>
      }
    >
      {formatMessage({ id: 'externalSiteDialog.message' })}
    </Dialog>
  );
}
