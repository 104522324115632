import React from 'react';
import { Grid, Box, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { steps } from '../data/onboardingSteps';
import ButtonInput from '../components/ButtonInput';
import { useInputs } from '@bellawatt/use-inputs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import VehicleSet from '../calculations/VehicleSet';

const GettingStartedButtons = ({ onStart, onSkip }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Box sx={{ textAlign: 'center', marginBottom: '20px' }} maxWidth="1068px">
        <ButtonInput variant="filled" onClick={onStart}>
          {formatMessage({ id: 'onboarding.gettingStarted.createFirstVehicleSet' })}
        </ButtonInput>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <ButtonInput variant="link" onClick={onSkip}>
          {formatMessage({ id: 'onboarding.gettingStarted.doLater' })}
        </ButtonInput>
      </Box>
    </>
  );
};

export default function OnboardingButtonRow({ onSave, vehicleSet, disabled }) {
  const history = useHistory();
  const { setInput, onboardingStep, zipCode, isMultipleSites } = useInputs();
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const stepIndex = steps.findIndex((step) => step === onboardingStep);

  const handleSkipOnboarding = () => {
    setInput({ isOnboarded: true });
  };

  const handleGoForward = () => {
    if (stepIndex !== steps.length - 1) {
      setInput({ onboardingStep: steps[stepIndex + 1] });
    } else {
      onSave();
    }
    //scroll back to the top between each step
    document.documentElement.scrollTop = 0;
  };

  const handleGoBack = () => {
    if (stepIndex !== 0) {
      setInput({ onboardingStep: steps[stepIndex - 1] });
    } else {
      history.replace('/');
    }
    document.documentElement.scrollTop = 0;
  };

  const styles = {
    container: {
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.up('sm')]: {
        position: 'relative',
        boxShadow: 'none',
      },
    },
    buttons: {
      justifyContent: 'space-evenly',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-between',
      },
    },
  };

  const selectVehicleCanNext = () => {
    let validation = true;

    validation =
      vehicleSet.vehicleCount > 0 &&
      vehicleSet.vehicleCount !== undefined &&
      vehicleSet.name !== '' &&
      vehicleSet.name !== undefined;

    if (vehicleSet.hasUserSpecifiedCustomVehicle) {
      let mpgCheck =
        vehicleSet?.customFossilFuelVehicle.milesPerGallon &&
        vehicleSet?.customFossilFuelVehicle.milesPerGallon <= 100 &&
        vehicleSet?.customFossilFuelVehicle.milesPerGallon >= 1;

      let msrpCheck =
        vehicleSet?.customFossilFuelVehicle.msrp &&
        vehicleSet?.customFossilFuelVehicle.msrp <= 500000 &&
        vehicleSet?.customFossilFuelVehicle.msrp >= 10000;

      validation = mpgCheck && msrpCheck;
    } else {
      validation =
        vehicleSet.existingVehicleId !== '' && vehicleSet.existingVehicleId !== undefined;
    }

    return validation;
  };

  const disableForward = () => {
    if (onboardingStep === 'generalInformation') {
      return !/[0-9]{5}/.test(zipCode) && !isMultipleSites;
    } else if (onboardingStep === 'chargingBehavior') {
      const minimumStateOfCharge = VehicleSet.minimumStateOfCharge(vehicleSet);
      return minimumStateOfCharge <= 0;
    } else if (onboardingStep === 'selectVehicle') {
      return !selectVehicleCanNext();
    } else if (onboardingStep === 'vehicleUsage') {
      return disabled;
    } else {
      return false;
    }
  };

  return (
    <Box
      position="fixed"
      bottom="85px"
      width="100%"
      mb="50px"
      py={2}
      backgroundColor="white"
      zIndex={2}
      sx={{ ...styles.container }}
    >
      {stepIndex === 0 ? (
        <GettingStartedButtons onStart={handleGoForward} onSkip={handleSkipOnboarding} />
      ) : (
        <Grid container sx={{ ...styles.buttons }}>
          <Grid item>
            <ButtonInput variant="outlinedNoTransform" onClick={handleGoBack}>
              {formatMessage({ id: 'onboarding.back' })}
            </ButtonInput>
          </Grid>
          <Grid item>
            <ButtonInput variant="filled" onClick={handleGoForward} isDisabled={disableForward()}>
              {formatMessage({ id: `onboarding.${onboardingStep}.next` })}
            </ButtonInput>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
