import React from 'react';
import { Box, Grid, Typography, useTheme, Link } from '@mui/material';
import { formatAsDollars } from '../../utils/formatters';
import { useDisplay } from '../../components/DisplayProvider';
import { useInputs } from '@bellawatt/use-inputs';

// const URL_REGEX =
//   /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
// const isUrl = (str) => URL_REGEX.test(str);

export default function IncentiveCard({ incentive, isEligible }) {
  const { vehicleSets } = useInputs();
  const theme = useTheme();
  const { mode } = useDisplay();

  const {
    estimated_amount,
    incentive_name,
    incentive_url,
    //     incentive_application_url,
  } = incentive;

  const numVehicles = vehicleSets.reduce(function (set, obj) {
    return set + obj.vehicleCount;
  }, 0);

  return (
    <Box display="flex">
      <Box flexShrink={0} width="6px" />
      <Box px={3} pt="10px" pb="16px" flexGrow={1}>
        <Grid container columnSpacing={4}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Link
              href={incentive_url}
              fontFamily={theme.typography.fontFamily}
              color="#252525"
              fontSize="12px"
              fontWeight="400"
              rel="noopener noreferrer"
              target="_blank"
            >
              {incentive_name}
            </Link>
            <Typography
              fontFamily={theme.typography.fontFamily}
              fontSize="12px"
              fontWeight="700"
              mb={2}
            >
              Up to {formatAsDollars(estimated_amount / numVehicles)}
            </Typography>
          </Box>
          <Grid item xs={mode === 'print' ? 3 : 6} md={3}></Grid>
          <Grid item xs={mode === 'print' ? 3 : 6} md={3}></Grid>
        </Grid>
      </Box>
    </Box>
  );
}
