// maps ICE GM vehicles to their replacement EVs

const vehicleMappings = {
  Chevrolet_Silverado_1500_WT_Double_Cab_2WD_GAS_2023: {
    // Warning! At the time of this commit, there are no Silverados in the /commercial vehicles API endpoint
    // As a result, no vehicles that map to the Silverado will show up in the tool
    ev_id: 'Chevrolet_Silverado_Electric_WT4_BEV_2024',
    headroom: 43,
    legroom: 44.5,
    cargo: 71.7,
    seats: 6,
  },
  Chevrolet_Silverado_1500_WT_Double_Cab_4WD_GAS_2023: {
    // Warning! At the time of this commit, there are no Silverados in the /commercial vehicles API endpoint
    // As a result, no vehicles that map to the Silverado will show up in the tool
    ev_id: 'Chevrolet_Silverado_Electric_WT4_BEV_2024',
    headroom: 43,
    legroom: 44.5,
    cargo: 71.7,
    seats: 6,
  },
  Chevrolet_Silverado_1500_WT_Crew_Cab_2WD_GAS_2023: {
    // Warning! At the time of this commit, there are no Silverados in the /commercial vehicles API endpoint
    // As a result, no vehicles that map to the Silverado will show up in the tool
    ev_id: 'Chevrolet_Silverado_Electric_WT4_BEV_2024',
    headroom: 43,
    legroom: 44.5,
    cargo: 62.9,
    seats: 6,
  },
  Chevrolet_Silverado_1500_WT_Crew_Cab_4WD_GAS_2023: {
    // Warning! At the time of this commit, there are no Silverados in the /commercial vehicles API endpoint
    // As a result, no vehicles that map to the Silverado will show up in the tool
    ev_id: 'Chevrolet_Silverado_Electric_WT4_BEV_2024',
    headroom: 43,
    legroom: 44.5,
    cargo: 62.9,
    seats: 6,
  },
  Chevrolet_Malibu_GAS_2023: {
    ev_id: 'Chevrolet_Bolt_EUV_BEV_2023',
    headroom: 39.1,
    legroom: 41.5,
    cargo: 15.7,
    seats: 5,
  },
  Chevrolet_Malibu_LT_1LT_GAS_2023: {
    ev_id: 'Chevrolet_Bolt__BEV_2023',
    headroom: 39.1,
    legroom: 41.5,
    cargo: 15.7,
    seats: 5,
  },
  Chevrolet_Equinox_FWD_GAS_2023: {
    ev_id: 'Chevrolet_Bolt_EUV_BEV_2023',
    headroom: 40,
    legroom: 40.9,
    cargo: 63.9,
    seats: 5,
  },
  Chevrolet_Equinox_AWD_GAS_2023: {
    ev_id: 'Chevrolet_Bolt_EUV_BEV_2023',
    headroom: 40,
    legroom: 40.9,
    cargo: 63.9,
    seats: 5,
  },
  Chevrolet_Tahoe_2WD_GAS_2023: {
    ev_id: 'Chevrolet_Blazer_EV_2LT_BEV_2024',
    headroom: 42.3,
    legroom: 44.5,
    cargo: 122.9,
    seats: 8,
  },
  Chevrolet_Tahoe_4WD_GAS_2023: {
    ev_id: 'Chevrolet_Blazer_EV_2LT_BEV_2024',
    headroom: 42.3,
    legroom: 44.5,
    cargo: 122.9,
    seats: 8,
  },
  Chevrolet_Colorado_2WD_GAS_2023: {
    // Warning! At the time of this commit, there are no Silverados in the /commercial vehicles API endpoint
    // As a result, no vehicles that map to the Silverado will show up in the tool
    ev_id: 'Chevrolet_Silverado_Electric_WT3_BEV_2024',
    headroom: 41.4,
    legroom: 45,
    cargo: 49.9,
    seats: 4,
  },
  Chevrolet_Colorado_4WD_GAS_2023: {
    // Warning! At the time of this commit, there are no Silverados in the /commercial vehicles API endpoint
    // As a result, no vehicles that map to the Silverado will show up in the tool
    ev_id: 'Chevrolet_Silverado_Electric_WT3_BEV_2024',
    headroom: 41.4,
    legroom: 45,
    cargo: 49.9,
    seats: 4,
  },
};

export default vehicleMappings;
