import { LoadProfile } from '@bellawatt/electric-rate-engine';
import { sum } from 'lodash';
import times from 'lodash/times';
import { YEAR } from '../utils/assumptions';
import { isHourInUse } from '../utils/time';
import VehicleSet from './VehicleSet';
import {
  DIESEL_EMISSIONS_IN_LBS_PER_GAL,
  GASOLINE_EMISSIONS_IN_LBS_PER_GAL,
} from '../utils/assumptions';

const loadProfileOfZeros = times(8760, (i) => 0);

// calculations that require an array of vehicle sets, but don't necessarily apply to only one site
const VehicleSets = {
  optimizedChargers(vehicleSets) {
    const chargingSets = {};

    vehicleSets.forEach(({ chargingWindows, ...vehicle }) => {
      chargingWindows.forEach((chargingWindow) => {
        const vehicleChargingUsage = {
          ...vehicle,
          usage: times(24, (hour) =>
            isHourInUse(chargingWindow, hour) ? parseInt(vehicle.vehicleCount, 10) : 0,
          ),
          chargingWindow,
        };
        chargingSets[chargingWindow.chargerId] = {
          ...chargingWindow,
          ...chargingWindow.charger,
          vehicles: chargingSets[chargingWindow.chargerId]
            ? [...chargingSets[chargingWindow.chargerId].vehicles, vehicleChargingUsage]
            : [vehicleChargingUsage],
        };
      });
    });

    Object.keys(chargingSets).forEach((key) => {
      const summedHours = chargingSets[key].vehicles.reduce(
        (sum, { usage }) => sum.map((val, i) => val + usage[i]),
        times(24, () => 0),
      );

      chargingSets[key].minimumRequired = Math.ceil(
        Math.max(...summedHours) / chargingSets[key].ports,
      );
    });

    return Object.values(chargingSets);
  },

  allChargers(vehicleSets) {
    const chargerList = [];
    vehicleSets.forEach(({ chargingWindows, vehicleCount }) => {
      chargingWindows.forEach((chargingWindow) => {
        chargerList.push({ ...chargingWindow, vehicleCount });
      });
    });
    return chargerList;
  },

  annualMiles(vehicleSets, fossilFuelType) {
    return sum(
      vehicleSets
        .filter(({ existingVehicle: { fuel } }) =>
          fossilFuelType ? fuel === fossilFuelType : true,
        )
        .map((set) => VehicleSet.annualMiles(set)),
    );
  },

  annualGallonsOfFossilFuel(vehicleSets, fossilFuelType) {
    // limit vehicles to specified fossil fuel type
    const filteredSets = vehicleSets.filter(
      ({ existingVehicle: { fuel } }) => fuel === fossilFuelType,
    );

    // sum the number of miles / mpg for each vehicle set
    return sum(
      filteredSets.map((set) => VehicleSet.annualMiles(set) / set.existingVehicle.milesPerGallon),
    );
  },

  totalKwh(vehicleSets) {
    const aggregateLoadProfile = vehicleSets.reduce((acc, set) => {
      return acc.aggregate(VehicleSet.annualLoadProfile(set));
    }, new LoadProfile(loadProfileOfZeros, { year: YEAR }));

    return aggregateLoadProfile.sum();
  },

  emissionsData(vehicleSets, { electricityEmissions, lifespanYears }) {
    const totalDieselMiles = this.annualMiles(vehicleSets, 'diesel');
    const totalGasolineMiles = this.annualMiles(vehicleSets, 'gas');

    const totalGallonsOfDiesel = this.annualGallonsOfFossilFuel(vehicleSets, 'diesel');
    const totalGallonsOfGasoline = this.annualGallonsOfFossilFuel(vehicleSets, 'gas');

    const totalDieselEmissions = totalGallonsOfDiesel * DIESEL_EMISSIONS_IN_LBS_PER_GAL;
    const totalGasolineEmissions = totalGallonsOfGasoline * GASOLINE_EMISSIONS_IN_LBS_PER_GAL;
    const totalEmissions = totalDieselEmissions + totalGasolineEmissions;

    const totalMwh = this.totalKwh(vehicleSets) / 1000;

    // g CO2/kWh * 2.2046 = lbs CO2/Mwh
    const electricityEmissionsInLbsPerMwh = electricityEmissions * 2.2046;
    const electricityEmissionsFromEVs = totalMwh * electricityEmissionsInLbsPerMwh;

    const annualEmissionsReduced = totalEmissions - electricityEmissionsFromEVs;
    const lifetimeEmissionsReduced = annualEmissionsReduced * lifespanYears;
    const annualTreesPlanted = annualEmissionsReduced / 48;
    const lifetimeTreesPlanted = annualTreesPlanted * lifespanYears;

    return {
      totalDieselMiles,
      totalGasolineMiles,
      totalGallonsOfDiesel,
      totalGallonsOfGasoline,
      totalDieselEmissions,
      totalGasolineEmissions,
      totalEmissions,
      totalMwh,
      electricityEmissionsInLbsPerMwh,
      electricityEmissionsFromEVs,
      annualEmissionsReduced,
      lifetimeEmissionsReduced,
      annualTreesPlanted,
      lifetimeTreesPlanted,
    };
  },

  vehiclePurchaseCosts(vehicleSets, isRetiringFleet) {
    return sum(
      vehicleSets.map(
        ({ existingVehicle, replacementVehicle, vehicleCount }) =>
          vehicleCount * (isRetiringFleet ? existingVehicle.msrp : replacementVehicle.msrp),
      ),
    );
  },

  chargerPurchaseCosts(vehicleSets, isRetiringFleet) {
    if (isRetiringFleet) {
      return 0;
    } else {
      const chargelist = this.allChargers(vehicleSets);
      const cost = sum(chargelist.map(({ vehicleCount, charger }) => vehicleCount * charger.price));

      return cost;
    }
  },

  totalCosts(
    vehicleSets,
    {
      isRetiringFleet,
      dieselPrice,
      gasolinePrice,
      rate,
      incentives,
      lifespanYears,
      btmConstructionCosts,
    },
  ) {
    const byYear = [];
    const byCategory = {};

    for (let i = 0; i < lifespanYears + 1; i++) {
      if (i === 0) {
        // only include vehicle purchase, charger purchase, and incentive costs for first year
        //  const chargerCost = this.chargerPurchaseCosts(vehicleSets, isRetiringFleet);
        const purchaseCosts = this.vehiclePurchaseCosts(vehicleSets, isRetiringFleet);
        const installationCosts = isRetiringFleet ? 0 : btmConstructionCosts;
        const incentivesAmount = isRetiringFleet ? 0 : 0 - incentives;

        byYear.push(purchaseCosts + installationCosts + incentivesAmount);
        byCategory.purchase = purchaseCosts;
        byCategory.incentives = incentivesAmount;
        //  byCategory.chargers = chargerCost;
        byCategory.btmConstructionCosts = installationCosts;
      } else {
        // only include fuel costs for subsequent years
        const fuelCosts = sum(
          vehicleSets.map((vehicleSet) =>
            isRetiringFleet
              ? VehicleSet.annualFossilFuelCosts(vehicleSet, { gasolinePrice, dieselPrice })
              : VehicleSet.annualElectricityCosts(vehicleSet, rate),
          ),
        );
        byYear.push(byYear[i - 1] + fuelCosts);
        byCategory.fuel = fuelCosts + (byCategory.fuel || 0);
      }
    }

    return {
      byYear,
      byCategory,
    };
  },
};

export default VehicleSets;
