import { React, useState } from 'react';
import { useIntl } from 'react-intl';
import TextFieldInput from '../../components/TextFieldInput';
import { CloseIcon } from '../../assets/icons/icons';
import ButtonInput from '../../components/ButtonInput';
import ModalButtonRow from './ModalButtonRow';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  useMediaQuery,
  DialogActions,
  Typography,
  Grid,
  useTheme,
} from '@mui/material';

export default function EditVehicleOverrides({ vehicleSet, onChangeVehicleSet, isOpen, onClose }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { overrideSpecs, rangeInMiles, milesPerGallon, gvwr, msrp } = vehicleSet;
  const overrides = overrideSpecs || {};

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const [specs, setSpecs] = useState(overrides);

  const handleSaveAndClose = () => {
    onChangeVehicleSet({ overrideSpecs: { ...specs } });
    onClose();
  };

  return (
    <Dialog open={isOpen} onBackdropClick={onClose} maxWidth={isLargeScreen ? 'lg' : 'sm'}>
      <DialogTitle backgroundColor={(theme) => theme.palette.grey[900]} marginBottom="40px">
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            minWidth: '380px',
            padding: '16px 18px',
            [theme.breakpoints.up('sm')]: { minWidth: '1000px' },
          })}
        >
          <Typography
            fontFamily={theme.typography.fontFamily}
            fontSize="24px"
            color={(theme) => theme.palette.grey[500]}
          >
            {formatMessage({ id: 'inputs.vehicleSet.overrides.title' })}
          </Typography>
          <ButtonInput title={formatMessage({ id: 'a11y.close' })} variant="link" onClick={onClose}>
            <CloseIcon color="white" />
          </ButtonInput>
        </Box>
      </DialogTitle>
      <DialogContent px="48px">
        <Grid container gap="1px">
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              minWidth: '380px',
              color: theme.palette.grey[900],
              fontStyle: 'emphasis',
              marginBottom: '40px',
              fontWeight: 'bold',
            })}
          >
            <Typography fontSize="16x" lineHeight="24px">
              {vehicleSet.existingVehicle.name}
            </Typography>
          </Box>
          <Grid
            container
            flexDirection="column"
            justifyContent="space-between"
            px={2}
            columnSpacing={4}
          >
            <Grid
              container
              flexDirection="row"
              justifyContent="space-evenly"
              px={2}
              columnSpacing={4}
            >
              <TextFieldInput
                width="500px"
                value={overrides.rangeInMiles || rangeInMiles}
                label={formatMessage({ id: 'inputs.vehicleSet.overrides.range' })}
                onBlur={(e) =>
                  setSpecs({
                    ...specs,
                    rangeInMiles: parseInt(e.target.value),
                  })
                }
                type="number"
                sx={(theme) => ({ mb: 4, [theme.breakpoints.up('sm')]: { mb: 12 } })}
              />

              <TextFieldInput
                width="500px"
                value={overrides.milesPerGallon || milesPerGallon}
                label={formatMessage({ id: 'inputs.vehicleSet.overrides.mpg' })}
                onBlur={(e) =>
                  setSpecs({
                    ...specs,
                    milesPerGallon: parseInt(e.target.value),
                  })
                }
                type="number"
                sx={(theme) => ({ mb: 4, [theme.breakpoints.up('sm')]: { mb: 12 } })}
              />
            </Grid>
            <Grid
              container
              flexDirection="row"
              justifyContent="space-evenly"
              px={2}
              columnSpacing={4}
            >
              <TextFieldInput
                width="500px"
                value={overrides.msrp || msrp}
                label={formatMessage({ id: 'inputs.vehicleSet.overrides.msrp' })}
                onBlur={(e) =>
                  setSpecs({
                    ...specs,
                    msrp: parseInt(e.target.value),
                  })
                }
                type="number"
                sx={(theme) => ({ mb: 4, [theme.breakpoints.up('sm')]: { mb: 12 } })}
              />

              <TextFieldInput
                width="500px"
                value={overrides.gvwr || gvwr}
                label={formatMessage({ id: 'inputs.vehicleSet.overrides.gvwr' })}
                onBlur={(e) =>
                  setSpecs({
                    ...specs,
                    gvwr: parseInt(e.target.value),
                  })
                }
                type="number"
                sx={(theme) => ({ mb: 4, [theme.breakpoints.up('sm')]: { mb: 12 } })}
              />
            </Grid>
          </Grid>
          <Box
            mx="auto"
            my="12px"
            sx={(theme) => ({
              width: '100%',
              minWidth: '380px',
              textAlign: 'center',
              [theme.breakpoints.up('sm')]: { minWidth: '1000px' },
            })}
          >
            <Typography>
              {formatMessage({ id: 'inputs.vehicleSet.overrides.includedWeight' })}
            </Typography>
          </Box>
        </Grid>
        <DialogActions>
          <ModalButtonRow onClose={onClose} onSaveAndClose={handleSaveAndClose} />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
