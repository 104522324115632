import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import ToolTip from './ToolTip';
import { formatAsThousands } from '../utils/formatters';

export default function TextFieldInput({
  label,
  value,
  error,
  tooltip,
  type = 'text',
  maxLength,
  disabled,
  onBlur = () => {},
  // capital InputProps applies to material UI <Input>, lowercase applies to <input> DOM element
  inputProps = {},
  InputProps = {},
  isThousand,
  disclaimer,
  ...rest
}) {
  const [workingValue, setWorkingValue] = useState(value);
  const [hasFocus, setHasFocus] = useState(false);

  const handleChange = (e) => {
    setWorkingValue(e.target.value.slice(0, maxLength));
  };

  // value can sometimes change because of external state
  // this ensures the working value matches the real value in the UI
  const handleValue = () => {
    let val = value;

    if (isThousand) {
      val = formatAsThousands(Number(val.toString().replaceAll(',', '')), false);
      if (value < 1) val = val.toString().replaceAll(',', '');
    }

    setWorkingValue(val);
  };

  useEffect(() => {
    handleValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setHasFocus(false);
  }, [onBlur]);

  return (
    <Box display="flex" alignItems="top" {...rest}>
      <Box flexGrow={1}>
        <TextField
          label={label}
          value={workingValue}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={() => {
            setHasFocus(true);
          }}
          disabled={disabled}
          InputProps={InputProps}
          inputProps={inputProps}
          sx={{
            width: '100%',
            borderColor: (theme) => theme.palette.common.error,
            '& .MuiOutlinedInput-notchedOutline': error
              ? { borderColor: (theme) => theme.palette.common.error }
              : {},
            '& .MuiFormLabel-filled': error ? { color: (theme) => theme.palette.common.error } : {},
          }}
          type={type !== 'number' ? type : hasFocus ? 'tel' : 'tel'}
        />
        {error && (
          <Box
            sx={(theme) => ({
              color: theme.palette.common.error,
              fontSize: '14px',
              paddingLeft: '12px',
            })}
          >
            {error}
          </Box>
        )}
        {disclaimer && !error && (
          <Box
            sx={{
              paddingLeft: '2px',
              paddingTop: '2px',
            }}
          >
            <Typography
              fontSize="12px"
              fontWeight="400"
              color="grey.700"
              ml="px"
              lineHeight="14px"
              fontStyle={'italic'}
            >
              {disclaimer}
            </Typography>
          </Box>
        )}
      </Box>
      <Box mt={2}>{tooltip && <ToolTip content={tooltip} />}</Box>
    </Box>
  );
}
