import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, FormControlLabel, Checkbox, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import CheckboxButtonRow from '../../inputs/CheckboxButtonRow';
import { DAYS_ABBREVIATED, MONTHS_ABBREVIATED } from '../../data/timePeriods';
import { isEqual } from 'lodash';
import ButtonInput from '../../components/ButtonInput';

export default function OperationsDaysMonths({
  vehicleSet,
  onChangeVehicleSet,
  setTouchedFields,
  getError,
}) {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { workdays, workmonths } = vehicleSet;
  const [weekdaysOnly, setWeekdaysOnly] = useState(isEqual(workdays, [1, 2, 3, 4, 5]));
  const [allMonths, setAllMonths] = useState(workmonths.length === 12);
  const [showMonths, setShowMonths] = useState(false);
  const [showDays, setShowDays] = useState(false);

  useEffect(() => {
    setWeekdaysOnly(isEqual(workdays, [1, 2, 3, 4, 5]));
  }, [workdays]);

  useEffect(() => {
    setAllMonths(workmonths.length === 12);
  }, [workmonths]);

  const handleWeekdaysCheckbox = () => {
    if (!weekdaysOnly) {
      setTouchedFields((touchedFields) => ({ ...touchedFields, workdays: true }));
      setWeekdaysOnly(true);
      onChangeVehicleSet({ ...vehicleSet, workdays: [1, 2, 3, 4, 5] });
    } else {
      setTouchedFields((touchedFields) => ({ ...touchedFields, workdays: false }));
      setWeekdaysOnly(false);
    }
  };

  const handleMonthsCheckbox = () => {
    if (!allMonths) {
      setTouchedFields((touchedFields) => ({ ...touchedFields, workmonths: true }));
      setAllMonths(true);
      onChangeVehicleSet({ ...vehicleSet, workmonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] });
    } else {
      setTouchedFields((touchedFields) => ({ ...touchedFields, workmonths: false }));
      setAllMonths(false);
    }
  };
  const handleChange = (name) => (value) => {
    onChangeVehicleSet({ ...vehicleSet, [name]: value });
    setTouchedFields((touchedFields) => ({ ...touchedFields, [name]: true }));
  };

  return (
    <Grid container rowSpacing={6}>
      <Grid item xs={12}>
        <Typography fontFamily={theme.typography.fontFamily} mb="30px">
          {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.daysOperation' })}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              style={{
                color: weekdaysOnly ? theme.palette.common.blue[700] : theme.palette.grey[500],
              }}
              checked={weekdaysOnly}
              onClick={() => handleWeekdaysCheckbox()}
            />
          }
          label={
            <Typography fontSize="12px">
              {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.weekdays' })}
            </Typography>
          }
        />
        {!weekdaysOnly || showDays ? (
          <CheckboxButtonRow
            data={DAYS_ABBREVIATED}
            selectionArray={workdays}
            onChange={handleChange('workdays')}
            error={getError('workdays')}
          />
        ) : (
          <Box display="flex" alignItems="center">
            <Typography>
              {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.customDays' })}
            </Typography>
            <ButtonInput variant="link" onClick={() => setShowDays(true)}>
              {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.specifyDays' })}
            </ButtonInput>
          </Box>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography fontFamily={theme.typography.fontFamily} mb="30px">
          {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.monthsOperation' })}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              style={{
                color: allMonths ? theme.palette.common.blue[700] : theme.palette.grey[500],
              }}
              checked={allMonths}
              onClick={() => handleMonthsCheckbox()}
            />
          }
          label={
            <Typography fontSize="12px">
              {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.allMonths' })}
            </Typography>
          }
        />
        {!allMonths || showMonths ? (
          <CheckboxButtonRow
            data={MONTHS_ABBREVIATED}
            selectionArray={workmonths}
            onChange={handleChange('workmonths')}
            error={getError('workmonths')}
          />
        ) : (
          <Box display="flex" alignItems="center">
            <Typography>
              {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.customMonths' })}
            </Typography>
            <ButtonInput variant="link" onClick={() => setShowMonths(true)}>
              {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.specifyMonths' })}
            </ButtonInput>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
