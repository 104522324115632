import React, { useCallback, useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import { orderBy } from 'lodash';
import DropdownInput from '../../components/DropdownInput';
import TextFieldInput from '../../components/TextFieldInput';
import { VEHICLE_TYPES, VEHICLE_SUBTYPES, FUEL_TYPES, VEHICLE_MODELS } from '../../data/VEHICLES';
import { useEffect } from 'react';

export default function VehicleSelection({
  vehicleSet,
  onChangeVehicleSet,
  setDisabled,
  isEditing,
}) {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { apiElectricVehicles } = useInputs();
  const { name, vehicleCount, replacementVehicle, customFossilFuelVehicle, overrideSpecs } =
    vehicleSet;
  const overrides = overrideSpecs || {};
  const [isShowingCustomOptions, setIsShowingCustomOptions] = useState(true);
  const [error, setError] = useState(false);
  const [specs] = useState(overrides);
  const [touchedFields, setTouchedFields] = useState({});

  const checks = {
    make: () => customFossilFuelVehicle?.make?.trim(),
    model: () => customFossilFuelVehicle?.model?.trim(),

    rangeInMiles: () =>
      customFossilFuelVehicle?.rangeInMiles &&
      customFossilFuelVehicle?.rangeInMiles <= 10000 &&
      customFossilFuelVehicle?.rangeInMiles >= 1,
    milesPerGallon: () =>
      customFossilFuelVehicle?.milesPerGallon &&
      customFossilFuelVehicle?.milesPerGallon <= 100 &&
      customFossilFuelVehicle?.milesPerGallon >= 1,
    msrp: () =>
      customFossilFuelVehicle?.msrp &&
      customFossilFuelVehicle?.msrp <= 500000 &&
      customFossilFuelVehicle?.msrp >= 10000,
    fossilFuelType: () => customFossilFuelVehicle?.fossilFuelType,
  };

  const isValidVehicle = () => {
    const requiredFields = [
      'make',
      'model',
      'rangeInMiles',
      'milesPerGallon',
      'msrp',
      'fossilFuelType',
    ];

    return requiredFields.every((field) => (isEditing || touchedFields[field]) && checks[field]());
  };

  const handleError = () => {
    const errorMessages = {
      make: 'A Make must be specified',
      model: 'A Model must be specified',
      rangeInMiles: 'A Range figure that is an integer between 1 and 10000 must be specified',
      milesPerGallon: 'An MPG figure that is an integer between 1 and 100 must be specified',
      msrp: 'An MSRP figure that is an integer between $10,000 and $500,000 must be specified',
    };

    for (const field of Object.keys(checks)) {
      const checkResult = checks[field]();

      if (customFossilFuelVehicle.hasOwnProperty(field) && touchedFields?.[field] && !checkResult) {
        setError({
          type: field,
          msg: errorMessages[field],
        });
        setDisabled(true);
        return;
      }
    }

    if (isValidVehicle()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    setError(false);
  };

  const handleCustomVehicleOption = (value) => {
    if (Object.keys(value).length < 2) {
      setTouchedFields({ ...touchedFields, [Object.keys(value)[0]]: true });
    }

    handleError();
    onChangeVehicleSet({ customFossilFuelVehicle: { ...customFossilFuelVehicle, ...value } });
  };

  const handleVehicleChange = (existingId) => {
    let target;
    apiElectricVehicles.forEach((ev) => {
      if (ev.id === existingId) {
        target = ev;
      }
    });
    onChangeVehicleSet({ replacementVehicle: target });
  };

  const handleCustomVehicleToggle = (value) => {
    setIsShowingCustomOptions(value);
    onChangeVehicleSet({ hasUserSpecifiedCustomVehicle: value });
  };

  const generateYears = () => {
    let year = new Date();
    year = year.getFullYear();

    let years = [];

    for (let i = 0; i < 10; i++) {
      years.push({ value: year - i, children: year - i });
    }

    return years;
  };

  const handleSaveSpecs = useCallback(() => {
    onChangeVehicleSet({ overrideSpecs: { ...specs } });
  }, [onChangeVehicleSet, specs]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleSaveSpecs();
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  const vehicleOptions = apiElectricVehicles.map(({ id, name }) => ({ value: id, children: name }));

  const filteredFuelTypes = FUEL_TYPES.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.value === value.value && t.children === value.children),
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let hasSubtype = false;

    VEHICLE_SUBTYPES[customFossilFuelVehicle.type].forEach(({ value }) => {
      if (value === customFossilFuelVehicle.subtype) hasSubtype = true;
    });

    if (!hasSubtype) {
      return handleCustomVehicleOption({
        subtype: VEHICLE_SUBTYPES[customFossilFuelVehicle.type][0].value,
      });
    }

    const selectedVehicleModel =
      VEHICLE_MODELS[customFossilFuelVehicle.type][customFossilFuelVehicle.subtype];

    const changeParams = {
      rangeInMiles: '',
      fossilFuelType: selectedVehicleModel.fossilFuelType,
      make: '',
      model: '',
      milesPerGallon: '',
      gvwr: selectedVehicleModel.gvwr.toFixed(0),
      year: '',
      msrp: '',
      imageUrl: selectedVehicleModel.imageUrl,
      name:
        selectedVehicleModel.name +
        ' ' +
        selectedVehicleModel.type +
        ' ' +
        selectedVehicleModel.subtype,
    };

    return handleCustomVehicleOption(changeParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFossilFuelVehicle.subtype, customFossilFuelVehicle.type]);
  /* eslint-disable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    handleError();
  }, [customFossilFuelVehicle]);
  /* eslint-disable react-hooks/exhaustive-deps */

  const switchTitle = formatMessage({ id: 'onboarding.selectVehicle.switchTitle' });
  const switchButton1 = formatMessage({ id: 'onboarding.selectVehicle.switchButton1' });
  const switchButton2 = formatMessage({ id: 'onboarding.selectVehicle.switchButton2' });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    handleCustomVehicleToggle(true);
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  return (
    <Grid container justifyContent="space-between" px={2} columnSpacing={4}>
      <Grid item xs={12} spacing={2} flexDirection="row">
        <Grid item container xs={12} spacing={2} flexDirection="row" marginBottom={2}>
          <Grid item md={6} xs={12}>
            <TextFieldInput
              value={name}
              label={formatMessage({ id: 'onboarding.selectVehicle.name' })}
              onBlur={(e) => onChangeVehicleSet({ name: e.target.value })}
              mb={0}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextFieldInput
              value={vehicleCount}
              label={formatMessage({ id: 'onboarding.selectVehicle.numberVehicles' })}
              onBlur={(e) => onChangeVehicleSet({ vehicleCount: parseInt(e.target.value) })}
              type="number"
              sx={(theme) => ({ mb: 0, [theme.breakpoints.up('sm')]: { mb: 0 } })}
            />
          </Grid>
        </Grid>

        <Typography fontSize="14px" color={theme.palette.black}>
          {switchTitle}
        </Typography>

        <Grid marginBottom={4} marginTop={2} item xs={12} flexDirection="row">
          <Box
            p="8px"
            width="100%"
            border={`1px solid ${theme.palette.grey[300]}`}
            flexDirection="row"
            display="flex"
            gap={2}
          >
            <Grid item sm={6} xs={12}>
              <Box
                p="4px"
                width="100%"
                height="63px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color={isShowingCustomOptions ? 'white' : '#007DBE'}
                backgroundColor={isShowingCustomOptions ? '#007DBE' : 'transparent'}
                onClick={() => setIsShowingCustomOptions(true)}
                style={{ cursor: 'pointer' }}
              >
                {switchButton1}
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box
                p="4px"
                width="100%"
                height="63px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color={isShowingCustomOptions ? '#007DBE' : 'white'}
                backgroundColor={isShowingCustomOptions ? 'transparent' : '#007DBE'}
                onClick={() => setIsShowingCustomOptions(false)}
                style={{ cursor: 'pointer' }}
              >
                {switchButton2}
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {!isShowingCustomOptions && (
        <Grid item xs={12} spacing={2} flexDirection="row">
          <Grid item md={6} xs={12}>
            <DropdownInput
              id="vehicle-subtype-input"
              label={formatMessage({
                id: 'onboarding.selectVehicle.customFossilFuelVehicle.replacement',
              })}
              options={orderBy(vehicleOptions, [(types) => types.value], ['asc'])}
              value={replacementVehicle.id}
              onChange={(e) => handleVehicleChange(e.target.value)}
            />
          </Grid>
        </Grid>
      )}

      {isShowingCustomOptions && (
        <Grid item xs={12} spacing={2} flexDirection="row">
          <Grid container item xs={12} spacing={2} flexDirection="row">
            <Grid item md={6} xs={12}>
              <DropdownInput
                id="vehicle-type-input"
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.type',
                })}
                options={orderBy(VEHICLE_TYPES, [(types) => types.value], ['asc'])}
                value={customFossilFuelVehicle.type}
                onChange={(e) => handleCustomVehicleOption({ type: e.target.value })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DropdownInput
                id="vehicle-subtype-input"
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.subtype',
                })}
                options={orderBy(
                  VEHICLE_SUBTYPES[customFossilFuelVehicle.type],
                  [(types) => types.value],
                  ['asc'],
                )}
                value={
                  customFossilFuelVehicle.subtype ||
                  VEHICLE_SUBTYPES[customFossilFuelVehicle.type][0].value
                }
                onChange={(e) => handleCustomVehicleOption({ subtype: e.target.value })}
              />
            </Grid>
          </Grid>

          <Box my="24px" width="100%" borderTop={`1px solid ${theme.palette.grey[300]}`} />

          <Grid container item xs={12} spacing={2} flexDirection="row">
            <Grid item md={6} xs={12}>
              <DropdownInput
                id="vehicle-year-input"
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.year',
                })}
                options={generateYears()}
                value={customFossilFuelVehicle.year}
                onChange={(e) => handleCustomVehicleOption({ year: e.target.value })}
                mb={4}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextFieldInput
                value={customFossilFuelVehicle.make || ' '}
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.make',
                })}
                onBlur={(e) => handleCustomVehicleOption({ make: e.target.value })}
                type="text"
                mb={4}
                error={error.type === 'make' ? error.msg : false}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2} flexDirection="row">
            <Grid item md={6} xs={12}>
              <TextFieldInput
                value={customFossilFuelVehicle.model || ' '}
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.model',
                })}
                onBlur={(e) => handleCustomVehicleOption({ model: e.target.value })}
                type="text"
                mb={4}
                error={error.type === 'model' ? error.msg : false}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DropdownInput
                id="vehicle-subtype-input"
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.fuelType',
                })}
                options={filteredFuelTypes}
                value={customFossilFuelVehicle.fossilFuelType}
                onChange={(e) => handleCustomVehicleOption({ fossilFuelType: e.target.value })}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2} flexDirection="row">
            <Grid item md={6} xs={12}>
              <TextFieldInput
                value={customFossilFuelVehicle.milesPerGallon || ' '}
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.mpg',
                })}
                onBlur={(e) => handleCustomVehicleOption({ milesPerGallon: e.target.value })}
                type="number"
                mb={4}
                error={error.type === 'milesPerGallon' ? error.msg : false}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextFieldInput
                value={customFossilFuelVehicle.rangeInMiles}
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.range',
                })}
                onBlur={(e) => handleCustomVehicleOption({ rangeInMiles: e.target.value })}
                type="number"
                mb={4}
                error={error.type === 'rangeInMiles' ? error.msg : false}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2} flexDirection="row">
            <Grid item md={6} xs={12}>
              <TextFieldInput
                value={customFossilFuelVehicle.msrp}
                label={formatMessage({
                  id: 'onboarding.selectVehicle.customFossilFuelVehicle.msrp',
                })}
                onBlur={(e) => handleCustomVehicleOption({ msrp: e.target.value })}
                type="number"
                mb={4}
                error={error.type === 'msrp' ? error.msg : false}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
