import React from 'react';
import { Grid, Box } from '@mui/material';
import { GasCanIcon, CarIcon, BankIcon, SavingsIcon } from '../assets/icons/icons';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material';
import VehicleSets from '../calculations/VehicleSets';
import { useInputs } from '@bellawatt/use-inputs';
import { formatAsCents, formatAsDollars } from '../utils/formatters';
import sum from 'lodash/sum';
import VehicleSet from '../calculations/VehicleSet';
import getDefaultRate from '../calculations/rates/defaultRate';
import ToolTip from './ToolTip';

const SummaryText = ({ title, value }) => {
  return (
    <Box>
      <Box fontSize="12px" lineHeight="1.25">
        {title.toUpperCase()}
      </Box>
      <Box fontSize="24px" fontFamily="Overpass, sans-serif " color="white">
        {value === '$Infinity' || value === 'Infinity tons' ? '-' : value}
      </Box>
    </Box>
  );
};

const SummaryBox = ({ icon, children, noBorder, width }) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="start">
      <Box
        width={width}
        height="65px"
        alignItems="center"
        display="flex"
        pr={1}
        mr={1}
        sx={{ borderRight: noBorder ?? `1px solid ${theme.palette.grey[600]}` }}
      >
        <Box pr={1}>{icon}</Box>
        <Box pr={1}>{children}</Box>
      </Box>
    </Box>
  );
};

export default function FleetSummary() {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const {
    vehicleSets,
    lifespanYears,
    gasolinePrice,
    dieselPrice,
    electricityPrice,
    apiEvaluatedIncentives,
    btmConstructionCosts,
    powerSupplier,
    assumedIncentives,
  } = useInputs();

  const rate = getDefaultRate(electricityPrice);

  const totalCostsParams = {
    rate: getDefaultRate(electricityPrice),
    dieselPrice,
    gasolinePrice,
    lifespanYears,
    incentives: assumedIncentives,
  };

  const totalRetiringFleetCosts = VehicleSets.totalCosts(vehicleSets, {
    ...totalCostsParams,
    isRetiringFleet: true,
  });

  const totalElectrifiedFleetCosts = VehicleSets.totalCosts(vehicleSets, {
    ...totalCostsParams,
    isRetiringFleet: false,
    btmConstructionCosts,
  });

  const { byCategory: fossilCategories } = totalRetiringFleetCosts;
  const { byCategory: evCategories } = totalElectrifiedFleetCosts;

  const fuelSavings = fossilCategories.fuel - evCategories.fuel;
  const purchaseSavings = fossilCategories.purchase - evCategories.purchase;

  const totalVehicles = sum(vehicleSets.map(({ vehicleCount }) => vehicleCount));
  const totalSavings = fuelSavings + purchaseSavings;

  const totalMiles = VehicleSets.annualMiles(vehicleSets);

  const annualFossilFuelCosts = sum(
    vehicleSets.map((vehicleSet) =>
      VehicleSet.annualFossilFuelCosts(vehicleSet, { gasolinePrice, dieselPrice }),
    ),
  );

  const annualElectricityCosts = sum(
    vehicleSets.map((vehicleSet) => VehicleSet.annualElectricityCosts(vehicleSet, rate)),
  );
  const fuelSavingsAnnual = annualFossilFuelCosts - annualElectricityCosts;
  const perMileSavings = fuelSavingsAnnual / totalMiles;

  const incentivesAmount =
    sum(apiEvaluatedIncentives.map(({ estimated_amount }) => estimated_amount)) / totalVehicles;

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        backgroundColor: theme.palette.grey[900],
        height: '158px',
        color: theme.palette.grey[500],
      }}
    >
      <Box width="100%" display="flex" justifyContent="center">
        <Box width="1250px" pl={2}>
          <Box
            component="h1"
            width="100%"
            mt="20px"
            mb="20px"
            fontFamily="Overpass"
            fontSize="16px"
            fontWeight="400"
          >
            {formatMessage({ id: 'fleetSummary.title' }).toUpperCase()}
          </Box>
          <Box display="flex" justifyContent="space-between">
            <SummaryBox icon={<SavingsIcon />}>
              <Box display="flex" alignItems="baseline">
                <SummaryText
                  title={formatMessage({ id: 'fleetSummary.lifetime' }, { time: lifespanYears })}
                  value={formatAsDollars(totalSavings)}
                />
                <ToolTip
                  content={formatMessage(
                    { id: 'fleetSummary.tooltips.lifetime' },
                    {
                      time: lifespanYears,
                      plural: lifespanYears > 1 && 's',
                      gramar: formatMessage({
                        id:
                          [8, 11, 18].indexOf(lifespanYears) >= 0
                            ? 'fleetSummary.tooltips.an'
                            : 'fleetSummary.tooltips.a',
                      }),
                    },
                  )}
                />
              </Box>
            </SummaryBox>
            <SummaryBox width="195px" icon={<CarIcon />}>
              <SummaryText
                title={formatMessage({ id: 'fleetSummary.size' })}
                value={formatMessage({ id: 'fleetSummary.vehicles' }, { value: totalVehicles })}
              />
            </SummaryBox>
            <SummaryBox
              icon={
                <Box height="41px" width="40px">
                  <GasCanIcon />
                </Box>
              }
            >
              <Box display="flex" justifyContent="space-between" alignItems="baseline">
                <Box pr={3}>
                  <Box display="flex" alignItems="baseline">
                    <SummaryText
                      title={formatMessage({ id: 'fleetSummary.fuelSavings' })}
                      value={formatAsDollars(fuelSavingsAnnual)}
                    />
                    <ToolTip
                      content={formatMessage(
                        { id: 'fleetSummary.tooltips.annualTou' },
                        {
                          years: lifespanYears,
                          utility: powerSupplier,
                          dieselFuelCost: formatAsCents(dieselPrice),
                          gasFuelCost: formatAsCents(gasolinePrice),
                        },
                      )}
                    />
                  </Box>
                </Box>
                <SummaryText
                  title={formatMessage({ id: 'fleetSummary.perMile' })}
                  value={formatAsCents(perMileSavings)}
                />
              </Box>
            </SummaryBox>
            <SummaryBox icon={<BankIcon />} noBorder={true}>
              <Box display="flex">
                <Box maxWidth="170px">
                  <Box display="flex" justifyContent="space-between">
                    <SummaryText
                      title={formatMessage({ id: 'fleetSummary.incentives' })}
                      value={formatAsDollars(incentivesAmount)}
                    />
                    <ToolTip
                      content={formatMessage(
                        { id: 'fleetSummary.tooltips.incentives' },
                        {
                          years: lifespanYears,
                        },
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </SummaryBox>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
