import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import ButtonInput from '../../components/ButtonInput';
import { TrashIcon } from '../../assets/icons/icons';
import WarningButtonRow from './WarningButtonRow';

export default function ModalButtonRow({
  onClose,
  onDelete,
  onSaveAndClose,
  showDelete,
  disabled,
  isNew,
}) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const [showWarning, setShowWarning] = useState(false);

  const handleCancel = (e) => {
    e.preventDefault();

    if (isNew) {
      onDelete();
    } else {
      onClose();
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" m={2} width="100%">
      {showWarning ? (
        <WarningButtonRow onCancel={() => setShowWarning(false)} onDelete={onDelete} />
      ) : (
        <>
          <ButtonInput variant="outlined" onClick={(e) => handleCancel(e)}>
            {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.cancel' })}
          </ButtonInput>
          {showDelete && (
            <ButtonInput onClick={() => setShowWarning(true)} variant="link" alert>
              <Box display="flex" alignItems="center">
                <TrashIcon />
                <Typography fontFamily={theme.typography.fontFamily} pl={1} fontSize="14px">
                  {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.delete' })}
                </Typography>
              </Box>
            </ButtonInput>
          )}
          <ButtonInput variant="contained" isDisabled={disabled} onClick={() => onSaveAndClose()}>
            {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.save' })}
          </ButtonInput>
        </>
      )}
    </Box>
  );
}
