import { Box } from '@mui/material';
import Hero from './Hero';
import Summary from './Summary';
import Fleets from './Fleets';
import Action from './Action';

export default function Homepage() {
  return (
    <Box>
      <Hero />
      <Summary />
      <Fleets />
      <Action />
    </Box>
  );
}
