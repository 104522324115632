import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { GasCanIcon, ElectricFleetIcon } from '../../assets/icons/icons';
import { useIntl } from 'react-intl';

export default function VehicleOverview({ name, imgUrl, isElectric }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={3} height="370px">
      <img src={imgUrl} alt={name} width="auto" height={220} />
      <Typography
        fontSize="1.5rem"
        fontFamily={theme.typography.fontFamily}
        textAlign="center"
        mb={1}
      >
        {name}
      </Typography>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" mr={1}>
          {isElectric ? <ElectricFleetIcon /> : <GasCanIcon height="24" width="24" color="#000" />}
        </Box>
        <Typography>
          {formatMessage({
            id: isElectric ? 'panels.comparison.batteryElectric' : 'panels.comparison.gasoline',
          })}
        </Typography>
      </Box>
    </Box>
  );
}
