import React, { useEffect } from 'react';
import { PrintIcon } from '../assets/icons/icons';
import { useIntl } from 'react-intl';
import { Tabs, Tab, Grid, Box, useTheme, CircularProgress, Link } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import ReactGA from 'react-ga';

export default function TabContent({ tabList }) {
  const { selectedTab, apisLoading, setInput } = useInputs();
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const anyApisLoading = Object.keys(apisLoading).some((key) => apisLoading[key]);

  useEffect(() => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Tab change',
      value: selectedTab,
    });
  }, [selectedTab]);

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="start"
      width="100%"
      pl="36px"
      pr="36px"
      sx={{
        [theme.breakpoints.up('sm')]: {
          pr: 0,
          maxWidth: '925px',
        },
      }}
    >
      {anyApisLoading && (
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '200px',
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}
      <Grid
        item
        xs={12}
        position="fixed"
        backgroundColor="white"
        zIndex={2}
        top={'64px'}
        sx={{ [theme.breakpoints.up('sm')]: { position: 'relative' } }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile={true}
          value={selectedTab}
          onChange={(_, newTab) => setInput({ selectedTab: newTab })}
          sx={{
            backgroundColor: 'transparent',
            minHeight: 'auto',
            '&.MuiTabs-indicator': {
              backgroundColor: `${theme.palette.primary.main} !important`,
            },
            '&.MuiTabs-flexContainer': {
              justifyContent: 'space-between',
            },
          }}
        >
          {tabList.map(({ label }, index) => {
            return (
              <Tab
                sx={{
                  color: theme.palette.grey[700],
                  fontFamily: 'Overpass, sans-serif',
                  fontSize: '12px',
                  fontWeight: '700',
                  borderBottom: `2px solid ${theme.palette.grey[500]}`,
                  flexGrow: 1,
                  '&.Mui-selected': {
                    color: `${theme.palette.primary.main} !important`,
                    fontWeight: 700,
                  },
                }}
                label={label}
                value={index}
                key={index}
                disableRipple
              />
            );
          })}
        </Tabs>
      </Grid>
      <Grid item xs={12} height="90px" mt="50px">
        <Box justifyContent="end" display="flex" height="100%" alignItems="center">
          <Link sx={{ cursor: 'pointer' }} href="/print" underline="none">
            <Box
              display="flex"
              alignItems="center"
              color={theme.palette.primary.main}
              fontFamily={theme.typography.fontFamily}
            >
              <PrintIcon />
              <Box pl="12px">{formatMessage({ id: 'tabs.print' })}</Box>
            </Box>
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {tabList[selectedTab].content}
      </Grid>
    </Grid>
  );
}
