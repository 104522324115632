import React from 'react';
import { useTheme, Typography } from '@mui/material';
import StyledTable from '../../components/charts/StyledTable';
import { formatAsDollars } from '../../utils/formatters';
import { useIntl } from 'react-intl';
import ToolTip from '../../components/ToolTip';
import Link from '../../components/Link';
const StyledLink = ({ href, children }) => {
  return (
    <Link to={href} external variant="tooltip" target="_blank" textTransform="none">
      {children}
    </Link>
  );
};
export default function SavingsTable({ fossilFleetCosts, evFleetCosts, lifespanYears }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const { byCategory: fossilCategories } = fossilFleetCosts;
  const { byCategory: evCategories } = evFleetCosts;

  const fuelSavings = fossilCategories.fuel - evCategories.fuel;
  const purchaseSavings = fossilCategories.purchase - evCategories.purchase;
  const totalSavings = fuelSavings + purchaseSavings;

  const fossilFleetTotal = fossilCategories.fuel + fossilCategories.purchase;

  const evFleetTotal = evCategories.fuel + evCategories.purchase;

  const labels = [
    '',
    formatMessage({ id: 'panels.financials.electric' }),
    formatMessage({ id: 'panels.financials.fossil' }),
    formatMessage({ id: 'panels.financials.savings' }),
  ];

  const rows = [
    [
      <Typography fontSize="14px" fontFamily={theme.typography.fontFamily}>
        {formatMessage({ id: 'panels.financials.fuel' })}
      </Typography>,
      <ToolTip
        content={formatMessage(
          { id: 'panels.financials.tooltips.evcost' },
          {
            link: (
              <StyledLink href="https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=table_5_06_a">
                {formatMessage({ id: 'inputs.fuel.electricityPrice.link' })}
              </StyledLink>
            ),
          },
        )}
      >
        {formatAsDollars(evCategories.fuel)}
      </ToolTip>,
      <ToolTip
        content={formatMessage(
          { id: 'panels.financials.tooltips.ffcost' },
          { link: <StyledLink href="https://gasprices.aaa.com/">AAA</StyledLink> },
        )}
      >
        {formatAsDollars(fossilCategories.fuel)}
      </ToolTip>,
      formatAsDollars(fuelSavings),
    ],
    [
      <Typography fontSize="14px" fontFamily={theme.typography.fontFamily}>
        {formatMessage({ id: 'panels.financials.vehiclePurchase' })}
      </Typography>,
      formatAsDollars(evCategories.purchase),
      formatAsDollars(fossilCategories.purchase),
      formatAsDollars(purchaseSavings),
    ],
    [
      <Typography fontSize="14px" fontFamily={theme.typography.fontFamily}>
        {formatMessage({ id: 'panels.financials.total' })}
      </Typography>,
      formatAsDollars(evFleetTotal),
      formatAsDollars(fossilFleetTotal),
      formatAsDollars(totalSavings),
    ],
  ];

  return <StyledTable headings={labels} rows={rows} firstCellColor={theme.palette.primary.main} />;
}
